@charset "utf-8";

.awe-layout-container * {
    outline: none;
    margin: 0;
    box-sizing: border-box;
}

body.awe-layout-container {
    font-family: var(--fonts-OpenSans);
    font-size: var(--fs15);
    font-weight: 400;
    line-height: normal;
    color: var(--greyMain);
    background-color: #F4F4F4;
    overflow-x: hidden;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-size: 16px;
    width: 100%;
    height: 100%;
}

.awe-layout-container a {
    color: inherit;
    display: inline-block;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.awe-layout-container a:hover,
.awe-layout-container button:hover {
    color: var(--primaryMain);
}

.awe-layout-container a,
.awe-layout-container a:hover,
.awe-layout-container a:focus {
    text-decoration: none;
    -webkit-transition: 0.4s all;
    transition: 0.4s all;
    outline: none;
}

.awe-layout-container button,
.awe-layout-container input,
.awe-layout-container optgroup,
.awe-layout-container select,
.awe-layout-container textarea {
    color: var(--darkMain);
    font-weight: normal;
    border: 0px;
    background-color: transparent;
    outline: none;
    padding: 0px;
}

.awe-layout-container input,
.awe-layout-container optgroup,
.awe-layout-container select,
.awe-layout-container textarea {
    width: 100%;
}

.awe-layout-container a[type=button],
.awe-layout-container a[type=reset],
.awe-layout-container a[type=submit],
.awe-layout-container button,
.awe-layout-container html [type=button],
.awe-layout-container [type=reset],
.awe-layout-container [type=submit] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.awe-layout-container input::-webkit-outer-spin-button,
.awe-layout-container input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    /* Firefox */
}

.awe-layout-container input[type=number] {
    -moz-appearance: textfield;
}

.awe-layout-container button,
.awe-layout-container [type=button] {
    background-color: transparent;
    border: none;
    box-shadow: none;
    outline: none;
}

.awe-layout-container button:focus,
.awe-layout-container input:focus,
.awe-layout-container select:focus,
.awe-layout-container textarea:focus,
.awe-layout-container .accordion-button:focus {
    outline: none;
    box-shadow: none;
}

.awe-layout-container .form-control {
    border: 1px solid var(--light700);
    /*background: var(--white);
     */
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    padding: 8px 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.awe-layout-container .form-check-input:focus,
.awe-layout-container .form-control:focus {
    box-shadow: none;
    outline: none;
}

.awe-layout-container .form-check-input {
    border: 1px solid var(--border);
    margin: 2px 0px 0px -1.5rem;
    cursor: pointer;
}

.awe-layout-container .form-check-input:checked {
    background-color: var(--primaryMain);
    border-color: var(--primaryMain);
}

.awe-layout-container .form-check-label {
    cursor: pointer;
}

.awe-layout-container [type="file"] {
    appearance: none;
}

.awe-layout-container [type="file"]::-webkit-file-upload-button {
    display: none;
}

.awe-layout-container [type="file"]::file-selector-button {
    display: none;
}

.awe-layout-container ul,
.awe-layout-container menu,
.awe-layout-container dir {
    margin: 0;
    padding: 0;
    /* display: block; */
    list-style-type: none;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
    -webkit-padding-start: 0;
}

.awe-layout-container section {
    position: relative;
}

.awe-layout-container ::-webkit-input-placeholder {
    color: var(--greyMain);
}

.awe-layout-container :-moz-placeholder {
    color: var(--greyMain);
}

.awe-layout-container ::-moz-placeholder {
    color: var(--greyMain);
}

.awe-layout-container :-ms-input-placeholder {
    color: var(--greyMain);
}

.awe-layout-container a,
.awe-layout-container button {
    -webkit-transition: 0.4s all;
    transition: 0.4s all;
    outline: none;
}

.awe-layout-container .o_hidden {
    overflow: hidden !important;
}

.awe-layout-container hr {
    border-top: 1px solid #E0E0E0;
}

.awe-layout-container p {
    color: var(--greyMain);
    font-size: var(--p);
    margin-bottom: 16px;
}

.awe-layout-container p:last-child {
    margin-bottom: 0;
}

.awe-layout-container img {
    max-width: 100%;
    /* Scrollbar */
}

.awe-layout-container .custom-scrollbar {
    overflow-y: auto;
}

.awe-layout-container .custom-scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 6px;
    background-color: var(--light700);
    border-radius: 8px;
}

.awe-layout-container .custom-scrollbar::-webkit-scrollbar-track {
    background-color: var(--light700);
    border-radius: 8px;
}

.awe-layout-container .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--primary400);
    border-radius: 8px;
    /* Hide scrollbar for Chrome, Safari and Opera */
}

.awe-layout-container .scrollbar_hide::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for IE, Edge and Firefox */
}

.awe-layout-container .scrollbar_hide {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    /*Preloader*/
}

.awe-layout-container .preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--darkMain);
    z-index: 9999;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.awe-layout-container .loader-part h5 {
    font-size: 14px;
    color: #fff;
    margin-bottom: 0;
    margin-top: 20px;
    font-weight: 400;
}

.awe-layout-container .loader-spinner {
    width: 75px;
    height: 75px;
    margin: 0;
    background: transparent;
    border-top: 4px solid #fff;
    border-right: 4px solid transparent;
    border-radius: 50%;
    -webkit-animation: 1s spin linear infinite;
    animation: 1s spin linear infinite;
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {

    /*-------default-class---------*/
    /* font size */
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.awe-layout-container .h1,
.awe-layout-container .h2,
.awe-layout-container .h3,
.awe-layout-container .h4,
.awe-layout-container .h5,
.awe-layout-container .h6,
.awe-layout-container h1,
.awe-layout-container h2,
.awe-layout-container h3,
.awe-layout-container h4,
.awe-layout-container h5,
.awe-layout-container h6 {
    color: var(--darkMain);
    font-weight: 700;
    margin: 0;
    line-height: 1.5;
}

.awe-layout-container h1,
.awe-layout-container .h1 {
    font-size: var(--h1);
}

.awe-layout-container h2,
.awe-layout-container .h2 {
    font-size: var(--h2);
}

.awe-layout-container h3,
.awe-layout-container .h3 {
    font-size: var(--h3);
}

.awe-layout-container h4,
.awe-layout-container .h4 {
    font-size: var(--h4);
}

.awe-layout-container h5,
.awe-layout-container .h5 {
    font-size: var(--h5);
}

.awe-layout-container h6,
.awe-layout-container .h6 {
    font-size: var(--h6);
}

.awe-layout-container .fs12 {
    font-size: var(--fs12) !important;
}

.awe-layout-container .fs13 {
    font-size: var(--fs13) !important;
}

.awe-layout-container .fs14 {
    font-size: var(--fs14) !important;
}

.awe-layout-container .fs15 {
    font-size: var(--fs15) !important;
}

.awe-layout-container .fs16 {
    font-size: var(--fs16) !important;
}

.awe-layout-container .fs18 {
    font-size: var(--fs18) !important;
}

.awe-layout-container .fs20 {
    font-size: var(--fs20) !important;
}

.awe-layout-container .fs22 {
    font-size: var(--fs22) !important;
}

.awe-layout-container .fs24 {
    font-size: var(--fs24) !important;
}

.awe-layout-container .fs26 {
    font-size: var(--fs26) !important;
}

.awe-layout-container .fs28 {
    font-size: var(--fs28) !important;
}

.awe-layout-container .fs30 {
    font-size: var(--fs30) !important;
}

.awe-layout-container .fs32 {
    font-size: var(--fs32) !important;
}

.awe-layout-container .fs34 {
    font-size: var(--fs34) !important;
}

.awe-layout-container .fs36 {
    font-size: var(--fs36) !important;
    /* line height */
}

.awe-layout-container .lh_1 {
    line-height: 1;
}

.awe-layout-container .lh_1_1 {
    line-height: 1.1;
}

.awe-layout-container .lh_1_2 {
    line-height: 1.2;
}

.awe-layout-container .lh_1_3 {
    line-height: 1.3;
}

.awe-layout-container .lh_1_4 {
    line-height: 1.4;
}

.awe-layout-container .lh_1_5 {
    line-height: 1.5;
}

.awe-layout-container .lh_1_6 {
    line-height: 1.6;
}

.awe-layout-container .lh_1_7 {
    line-height: 1.7;
}

.awe-layout-container .lh_1_8 {
    line-height: 1.8;
}

.awe-layout-container .lh_2 {
    line-height: 2;
    /* font weight */
}

.awe-layout-container .fw400 {
    font-weight: 400 !important;
}

.awe-layout-container .fw500 {
    font-weight: 500 !important;
}

.awe-layout-container .fw600 {
    font-weight: 600 !important;
}

.awe-layout-container .fw700 {
    font-weight: 700 !important;
}

.awe-layout-container .fw800 {
    font-weight: 800 !important;
}

.awe-layout-container select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url('../images/icons/arrow_down.svg') calc(100% - 10px)/11px no-repeat;
}

.awe-layout-container .link_text {
    font-weight: 500;
    font-size: 18px;
    color: var(--primaryMain);
}

.awe-layout-container .link_text:hover {
    color: var(--primary400);
}

.awe-layout-container .link_white {
    color: var(--white);
}

.awe-layout-container .link_white:hover {
    color: var(--primary400);
}

.awe-layout-container .link_primary {
    color: var(--primary400);
}

.awe-layout-container .link_primary:hover {
    color: var(--primary500);
}

.awe-layout-container .sr-only {
    position: absolute;
    width: 0px;
    height: 0px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    /*-----Spaching-------*/
}

.awe-layout-container .ptb96 {
    padding-top: 6rem;
    padding-bottom: 6rem;
}

.awe-layout-container .ptb80 {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.awe-layout-container .ptb64 {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.awe-layout-container .ptb48 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.awe-layout-container .ptb40 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.awe-layout-container .ptb32 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.awe-layout-container .ptb24 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.awe-layout-container .ptb16 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.awe-layout-container .pt96 {
    padding-top: 6rem;
}

.awe-layout-container .pt80 {
    padding-top: 5rem;
}

.awe-layout-container .pt64 {
    padding-top: 4rem;
}

.awe-layout-container .pt48 {
    padding-top: 3rem;
}

.awe-layout-container .pt32 {
    padding-top: 2rem;
}

.awe-layout-container .pt24 {
    padding-top: 1.5rem;
}

.awe-layout-container .pt16 {
    padding-top: 1rem;
}

.awe-layout-container .pb96 {
    padding-bottom: 6rem;
}

.awe-layout-container .pb80 {
    padding-bottom: 5rem;
}

.awe-layout-container .pb64 {
    padding-bottom: 4rem;
}

.awe-layout-container .pb48 {
    padding-bottom: 3rem;
}

.awe-layout-container .pb32 {
    padding-bottom: 2rem;
}

.awe-layout-container .pb24 {
    padding-bottom: 1.5rem;
}

.awe-layout-container .pb16 {
    padding-bottom: 1rem;
}

.awe-layout-container .mt96 {
    margin-top: 6rem;
}

.awe-layout-container .mt80 {
    margin-top: 5rem;
}

.awe-layout-container .mt64 {
    margin-top: 4rem;
}

.awe-layout-container .mt48 {
    margin-top: 3rem;
}

.awe-layout-container .mt32 {
    margin-top: 2rem;
}

.awe-layout-container .mt24 {
    margin-top: 1.5rem;
}

.awe-layout-container .mt20 {
    margin-top: 1.25rem;
}

.awe-layout-container .mt16 {
    margin-top: 1rem;
}

.awe-layout-container .mt12 {
    margin-top: 0.75rem;
}

.awe-layout-container .mt10 {
    margin-top: 0.625rem;
}

.awe-layout-container .mb96 {
    margin-bottom: 6rem;
}

.awe-layout-container .mb80 {
    margin-bottom: 5rem;
}

.awe-layout-container .mb64 {
    margin-bottom: 4rem;
}

.awe-layout-container .mb48 {
    margin-bottom: 3rem;
}

.awe-layout-container .mb32 {
    margin-bottom: 2rem;
}

.awe-layout-container .mb24 {
    margin-bottom: 1.5rem;
}

.awe-layout-container .mb20 {
    margin-bottom: 1.25rem;
}

.awe-layout-container .mb16 {
    margin-bottom: 1rem;
}

.awe-layout-container .mb12 {
    margin-bottom: 0.75rem;
}

.awe-layout-container .mb10 {
    margin-bottom: 0.625rem;
    /* flex class */
}

.awe-layout-container .flex_item_cc,
.awe-layout-container .flex_item_cb,
.awe-layout-container .flex_item_ca,
.awe-layout-container .flex_item_cs,
.awe-layout-container .flex_item_ce,
.awe-layout-container .flex_item_ss,
.awe-layout-container .flex_item_sb,
.awe-layout-container .flex_item_sc,
.awe-layout-container .flex_item_se {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 16px;
}

.awe-layout-container .flex_item_cc {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.awe-layout-container .flex_item_cb {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.awe-layout-container .flex_item_ca {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.awe-layout-container .flex_item_cs {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.awe-layout-container .flex_item_ce {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.awe-layout-container .flex_item_ss {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.awe-layout-container .flex_item_sb {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: space-between;
}

.awe-layout-container .flex_item_sc {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.awe-layout-container .flex_item_se {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.awe-layout-container .user_img {
    width: 52px;
    height: 52px;
    overflow: hidden;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

.awe-layout-container .user_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.awe-layout-container .user_img.w42,
.awe-layout-container .w42 {
    width: 42px;
    height: 42px;
}

.awe-layout-container .user_img.w32,
.awe-layout-container .w32 {
    width: 32px;
    height: 32px;
}

.awe-layout-container .user_img.w24,
.awe-layout-container .w24 {
    width: 24px;
    height: 24px;
}

.awe-layout-container .user_img.w20,
.awe-layout-container .w20 {
    width: 20px;
    height: 20px;
}

.awe-layout-container .user_img.w16,
.awe-layout-container .w16 {
    width: 16px;
    height: 16px;
    /* width */
}

.awe-layout-container .user_img.w12,
.awe-layout-container .w12 {
    width: 12px;
    height: 12px;
    /* width */
}

.awe-layout-container .w40 {
    width: 40px;
}

.awe-layout-container .mw40 {
    max-width: 40px;
}

.awe-layout-container .minw40 {
    min-width: 40px;
}

.awe-layout-container .w50 {
    width: 50px;
}

.awe-layout-container .w50 {
    width: 50px;
}

.awe-layout-container .mw50 {
    max-width: 50px;
}

.awe-layout-container .minw50 {
    min-width: 50px;
}

.awe-layout-container .w50 {
    width: 50px;
}

.awe-layout-container .mw60 {
    max-width: 60px;
}

.awe-layout-container .minw60 {
    min-width: 60px;
}

.awe-layout-container .w80 {
    width: 80px;
}

.awe-layout-container .mw80 {
    max-width: 80px;
}

.awe-layout-container .minw80 {
    min-width: 80px;
}

.awe-layout-container .minw85 {
    min-width: 85px;
}

.awe-layout-container .w100 {
    width: 100px;
}

.awe-layout-container .mw100 {
    max-width: 100px;
}

.awe-layout-container .minw100 {
    min-width: 100px;
}

.awe-layout-container .w120 {
    width: 100px;
}

.awe-layout-container .mw120 {
    max-width: 100px;
}

.awe-layout-container .minw120 {
    min-width: 100px;
}

.awe-layout-container .w150 {
    width: 150px;
}

.awe-layout-container .mw150 {
    max-width: 150px;
}

.awe-layout-container .minw150 {
    min-width: 150px;
}

.awe-layout-container .w160 {
    width: 160px;
}

.awe-layout-container .mw160 {
    max-width: 160px;
}

.awe-layout-container .minw160 {
    min-width: 160px;
}

.awe-layout-container .w170 {
    width: 170px;
}

.awe-layout-container .mw170 {
    max-width: 170px;
}

.awe-layout-container .minw170 {
    min-width: 170px;
}

.awe-layout-container .w180 {
    width: 180px;
}

.awe-layout-container .mw180 {
    max-width: 180px;
}

.awe-layout-container .minw180 {
    min-width: 180px;
}

.awe-layout-container .w200 {
    width: 200px;
}

.awe-layout-container .mw200 {
    max-width: 200px;
}

.awe-layout-container .minw200 {
    min-width: 200px;
}

.awe-layout-container .w250 {
    width: 250px;
}

.awe-layout-container .mw250 {
    max-width: 250px;
}

.awe-layout-container .minw250 {
    min-width: 250px;
}

.awe-layout-container .w300 {
    width: 300px;
}

.awe-layout-container .mw300 {
    max-width: 300px;
}

.awe-layout-container .minw300 {
    min-width: 300px;
}

@media screen and (max-width: 575px) {

    .awe-layout-container .btn-theme.minw250,
    .awe-layout-container .btn-theme.minw200 {
        min-width: 100px;
    }
}

.awe-layout-container .gap4 {
    gap: 4px !important;
}

.awe-layout-container .gap6 {
    gap: 6px !important;
}

.awe-layout-container .gap8 {
    gap: 8px !important;
}

.awe-layout-container .gap10 {
    gap: 10px !important;
}

.awe-layout-container .gap12 {
    gap: 12px !important;
}

.awe-layout-container .gap14 {
    gap: 14px !important;
    /* dropdowns */
}

.awe-layout-container .dropdown-menu {
    -webkit-animation: 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 animdropdown;
    animation: 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 animdropdown;
}

@-webkit-keyframes animdropdown {
    from {
        -webkit-transform: translate3d(0, 6px, 0);
        transform: translate3d(0, 6px, 0);
        opacity: 0;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes animdropdown {
    from {
        -webkit-transform: translate3d(0, 6px, 0);
        transform: translate3d(0, 6px, 0);
        opacity: 0;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.awe-layout-container .btn-theme {
    border: 1px solid var(--primary400);
    background-color: var(--primary400);
    text-transform: uppercase;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
    padding: 7.5px 12px;
    font-size: 13px;
    font-weight: 400;
    color: var(--white);
    letter-spacing: 0.455px;
    line-height: 18px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.awe-layout-container .btn-theme.btn-theme-outline {
    border-color: var(--primary400);
    color: var(--primary400);
    background-color: transparent;
}

.awe-layout-container .btn-theme.btn-theme-outline:hover {
    border: 1px solid var(--primary400);
    background-color: var(--primary400);
    color: var(--white);
}

.awe-layout-container .btn-icon {
    padding: 8.5px;
    line-height: 1;
    border: 1px solid var(--primary400);
    background-color: var(--primary400);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
    color: var(--white);
    line-height: 1;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.awe-layout-container .btn-theme:hover,
.awe-layout-container .btn-icon:hover {
    background-color: var(--primary500);
    border-color: var(--primary500);
    color: var(--white);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
}

.awe-layout-container .btn-theme:focus {
    background-color: var(--primary600);
    border-color: var(--primary600);
    color: var(--white);
    box-shadow: 0px 0px 0px 2px rgba(0, 73, 218, 0.15), 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
}

.awe-layout-container .btn-theme:disabled {
    background-color: var(--primary300);
    border-color: var(--primary300);
    color: var(--white);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
}

.awe-layout-container .btn-theme.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.awe-layout-container .btn-theme.icon span {
    width: 24px;
    height: 24px;
}

.awe-layout-container .btn-theme.btn-sm {
    padding: 8px 24px;
    font-size: var(--fs14);
}

.awe-layout-container .btn-icon.btn-sm {
    padding: 8px;
}

.awe-layout-container .btn-theme.btn-sm.icon span {
    width: 16px;
    height: 16px;
}

.awe-layout-container .btn-white {
    border-color: var(--white);
    background-color: var(--white);
    color: var(--grey700);
    box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.16);
}

.awe-layout-container .btn-white:hover {
    background-color: var(--white);
    border-color: var(--white);
    color: var(--grey700);
    box-shadow: 0px 0px 0px 1px rgba(0, 80, 240, 0.4);
}

.awe-layout-container .btn-white:focus {
    background-color: var(--white);
    border-color: var(--white);
    color: var(--grey700);
    box-shadow: 0px 0px 0px 2px rgba(0, 73, 218, 0.15), 0px 0px 0px 1px rgba(70, 79, 96, 0.32);
}

.awe-layout-container .btn-white:disabled {
    background-color: var(--grey200);
    border-color: var(--grey200);
    color: var(--grey700);
    box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.2);
}

.awe-layout-container .btn-grey {
    border-color: var(--lightMain);
    background-color: var(--lightMain);
    color: var(--greyMain);
}

.awe-layout-container .btn-grey:hover {
    background-color: var(--dark100);
    border-color: var(--dark100);
    color: var(--darkMain);
}

.awe-layout-container .btn-danger {
    border-color: var(--red300);
    background-color: var(--red300);
    color: var(--white);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
}

.awe-layout-container .btn-danger:hover {
    background-color: var(--red400);
    border-color: var(--red400);
    color: var(--white);
}

.awe-layout-container .btn-danger:focus {
    background-color: var(--red300);
    border-color: var(--red300);
    color: var(--white);
    box-shadow: 0px 0px 0px 2px rgba(219, 15, 66, 0.15), 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
}

.awe-layout-container .btn-danger:disabled {
    background-color: var(--red200);
    border-color: var(--red200);
    color: var(--white);
    box-shadow: 0px 0px 0px 1px #F76F7F, 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 991px) {
    .awe-layout-container .btn-theme {
        padding: 8px 16px;
        font-size: var(--fs14);
    }

    .awe-layout-container .btn-icon {
        padding: 10px;
    }
}

.awe-layout-container .badge {
    padding: 2px 8px;
    font-size: 13px;
    line-height: normal;
    font-weight: 600;
    min-width: 66px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    background-color: var(--primary400);
    color: var(--white);
    line-height: 17px;
    text-transform: uppercase;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;
}

/* .awe-layout-container .badge::before {
    content: "";
    width: 6px;
    height: 6px;
    background-color: var(--white);
    display: block;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
} */

.awe-layout-container .badge-active {
    background-color: var(--primary400);
    color: var(--white);
}

.awe-layout-container .badge-dark {
    background-color: var(--dark100);
    color: var(--darkMain);
}

.awe-layout-container .badge-warning {
    background-color: var(--warning900);
    color: var(--white);
}

.awe-layout-container .badge-orange {
    background-color: var(--warningMain);
    color: var(--white);
}

.awe-layout-container .badge-danger {
    background-color: var(--danger900);
    color: var(--white);
}

.awe-layout-container .badge-light {
    background-color: var(--lightMain);
    color: var(--darkMain);
}

.awe-layout-container .status_badge.badge-light {
    background-color: var(--lightMain);
    color: var(--darkMain);
}

.awe-layout-container .border,
.awe-layout-container .border-top,
.awe-layout-container .border-bottom,
.awe-layout-container .border-end,
.awe-layout-container .border-start {
    border-color: var(--border);
}

@media screen and (max-width: 1399px) {

    /* pagination */
    .awe-layout-container .badge {
        font-size: var(--fs14);
        padding: 6px 14px;
    }
}

.awe-layout-container .pagination li:first-child a {
    border-radius: 12px 0px 0px 12px;
    -webkit-border-radius: 12px 0px 0px 12px;
    -moz-border-radius: 12px 0px 0px 12px;
    -ms-border-radius: 12px 0px 0px 12px;
    -o-border-radius: 12px 0px 0px 12px;
}

.awe-layout-container .pagination li:last-child a {
    border-radius: 0px 12px 12px 0;
    -webkit-border-radius: 0px 12px 12px 0;
    -moz-border-radius: 0px 12px 12px 0;
    -ms-border-radius: 0px 12px 12px 0;
    -o-border-radius: 0px 12px 12px 0;
    border-right: 1px solid var(--border);
}

.awe-layout-container .pagination li a {
    border: 1px solid var(--border);
    background-color: var(--white);
    border-right: 0px;
    padding: 12px;
    font-size: var(--fs16);
    font-weight: 700;
    line-height: normal;
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.awe-layout-container .pagination li.active a,
.awe-layout-container .pagination li:hover a {
    background-color: var(--tosca100);
    color: var(--tosca);
}

@media screen and (max-width: 767px) {

    /* text color */
    .awe-layout-container .pagination li a {
        width: 38px;
        height: 38px;
        padding: 6px;
    }

    .awe-layout-container .pagination li:first-child a {
        border-radius: 8px 0px 0px 8px;
        -webkit-border-radius: 8px 0px 0px 8px;
        -moz-border-radius: 8px 0px 0px 8px;
        -ms-border-radius: 8px 0px 0px 8px;
        -o-border-radius: 8px 0px 0px 8px;
    }

    .awe-layout-container .pagination li:last-child a {
        border-radius: 0px 8px 8px 0;
        -webkit-border-radius: 0px 8px 8px 0;
        -moz-border-radius: 0px 8px 8px 0;
        -ms-border-radius: 0px 8px 8px 0;
        -o-border-radius: 0px 8px 8px 0;
        border-right: 1px solid var(--border);
    }
}

.awe-layout-container .text-primary {
    color: var(--primary400) !important;
}

.awe-layout-container .text-grey-600 {
    color: var(--grey600) !important;
}

.awe-layout-container .text-grey-900 {
    color: var(--grey900) !important;
}

.awe-layout-container .text-grey-100 {
    color: var(--grey100) !important;
}

.awe-layout-container .text-tosca {
    color: var(--primary400) !important;
}

.awe-layout-container .text-danger {
    color: var(--dangerMain) !important;
}

.awe-layout-container .text-dark {
    color: var(--darkMain) !important;
}

.awe-layout-container .text_c3A3C40 {
    color: var(--dark700) !important;
}

.awe-layout-container .text-grey {
    color: var(--greyMain) !important;
}

.awe-layout-container .text-grey-800 {
    color: var(--grey800) !important;
}

.awe-layout-container .text_c979797 {
    color: var(--grey150) !important;
}

.awe-layout-container .text-light-dark {
    color: var(--lightDark) !important;
}

.awe-layout-container .text-light-dark-800 {
    color: var(--lightDark800) !important;
}

.awe-layout-container .text-warning-900 {
    color: var(--warning900) !important;
}

.awe-layout-container .text-warning-800 {
    color: var(--warning800) !important;
}

.awe-layout-container .text-warning-700 {
    color: var(--warning700) !important;
}

.awe-layout-container .dropdown-menu {
    top: 100% !important;
    padding: 0px;
    border-radius: 16px;
    border: 1px solid var(--border);
    background-color: var(--white);
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    min-width: 200px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
}

.awe-layout-container .dropdown-menu li a {
    padding: 16px 20px;
    border-bottom: 1px solid var(--border);
    display: flex;
    align-items: center;
    gap: 8px;
}

.awe-layout-container .dropdown-menu li:last-child a {
    border-bottom: 0px;
    /* modal */
}

.awe-layout-container .modal .modal-content {
    border-radius: 24px;
    background: var(--white, #FFF);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.09);
    /* custom_switch */
}

.awe-layout-container .custom_switch .switch-label {
    display: flex;
    align-items: center;
    max-width: 155px;
    min-width: 155px;
    cursor: pointer;
}

.awe-layout-container .custom_switch .switch-label span {
    width: 50%;
    padding: 14px 16px;
    font-size: var(--fs16);
    line-height: 18px;
    border: 1px solid var(--border);
    text-align: center;
    display: block;
    flex-shrink: 0;
}

.awe-layout-container .custom_switch .switch-label .left {
    border-radius: 8px 0px 0px 8px;
    -webkit-border-radius: 8px 0px 0px 8px;
    -moz-border-radius: 8px 0px 0px 8px;
    -ms-border-radius: 8px 0px 0px 8px;
    -o-border-radius: 8px 0px 0px 8px;
}

.awe-layout-container .custom_switch .switch-label .right {
    border-radius: 0px 8px 8px 0px;
    border-left: 0px;
}

.awe-layout-container .custom_switch input:checked+.switch-label .left {
    background-color: var(--tosca);
    color: var(--white);
    border-color: var(--tosca);
}

.awe-layout-container .custom_switch input:not(:checked)+.switch-label .right {
    background-color: var(--dangerMain);
    color: var(--white);
    border-color: var(--dangerMain);
}

.awe-layout-container .switch-wrapper,
.awe-layout-container .switch-label {
    position: relative;
    cursor: pointer;
}

.awe-layout-container .switch-label .switch-box {
    content: "";
    width: 32px;
    height: 10px;
    position: relative;
    display: block;
    background-color: #D2D2D2;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.awe-layout-container .switch-label .switch-box:before {
    content: "";
    width: 16px;
    height: 16px;
    display: block;
    position: absolute;
    top: -3px;
    left: 0px;
    background-color: #7F7F8D;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
}

.awe-layout-container .switch-wrapper .switch-input:checked+.switch-label .on_text,
.awe-layout-container .switch-wrapper .switch-input:not(:checked)+.switch-label .off_text {
    display: block;
}

.awe-layout-container .switch-wrapper .switch-input:not(:checked)+.switch-label .on_text,
.awe-layout-container .switch-wrapper .switch-input:checked+.switch-label .off_text {
    display: none;
}

.awe-layout-container .switch-wrapper .switch-input:checked+.switch-label .switch-box {
    background-color: var(--tosca300);
}

.awe-layout-container .switch-wrapper .switch-input:checked+.switch-label .switch-box::before {
    left: calc(100% - 16px);
    background-color: var(--tosca);
}

.awe-layout-container .list_style_number {
    counter-reset: count;
}

.awe-layout-container .list_style_number li {
    counter-increment: count;
}

.awe-layout-container .list_style_number .count::before {
    content: counter(count);
}

.awe-layout-container .list_style_number .count.dot::before {
    content: counter(count) ".";
}

.awe-layout-container .list_style_dots li {
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.awe-layout-container .list_style_dots li::before {
    content: "";
    width: 6px;
    height: 6px;
    display: block;
    margin-top: 7px;
    background-color: var(--greyMain);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.awe-layout-container .r12 {
    border-radius: 12px !important;
    -webkit-border-radius: 12px !important;
    -moz-border-radius: 12px !important;
    -ms-border-radius: 12px !important;
    -o-border-radius: 12px !important;
}

.awe-layout-container .r10 {
    border-radius: 10px !important;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    -ms-border-radius: 10px !important;
    -o-border-radius: 10px !important;
}

.awe-layout-container .r8 {
    border-radius: 8px !important;
    -webkit-border-radius: 8px !important;
    -moz-border-radius: 8px !important;
    -ms-border-radius: 8px !important;
    -o-border-radius: 8px !important;
}

.awe-layout-container .r6 {
    border-radius: 6px !important;
    -webkit-border-radius: 6px !important;
    -moz-border-radius: 6px !important;
    -ms-border-radius: 6px !important;
    -o-border-radius: 6px !important;
}

.awe-layout-container .bg-light {
    background-color: var(--lightMain) !important;
}

.awe-layout-container .col_border_left div:nth-child(1n+0) .coder_word_card {
    border-left: 2px solid var(--primary400);
    padding: 10px;
}

.awe-layout-container .status_badge {
    background-color: var(--primary400);
    color: var(--white);
    min-width: 48px;
    height: 22px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: var(--fs13);
    line-height: 18px;
    font-weight: 600;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

.awe-layout-container .status_badge.badge_primary {
    background-color: var(--primary400);
    color: var(--white);
}

.awe-layout-container .status_badge.badge_warning {
    background-color: var(--warning900);
    color: var(--white);
}

.awe-layout-container .status_badge.badge_danger {
    background-color: var(--danger900);
    color: var(--white);
}

.awe-layout-container .status_badge.badge_orange {
    background-color: var(--warningMain);
    color: var(--white);
}

.awe-layout-container .status_badge.badge_yellow {
    background-color: var(--yellow);
    color: var(--white);
}
.awe-layout-container .status_badge.badge_orange {
    background-color: var(--orange);
    color: var(--white);
}

.awe-layout-container .status_badge.badge_green {
    background-color: var(--green);
    color: var(--white);
}
.awe-layout-container .status_badge.badge_red {
    background-color: var(--red);
    color: var(--white);
}

.awe-layout-container .table-short-text {
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.awe-layout-container .mui_custom_table p.MuiTablePagination-caption {
    margin-bottom: 0px;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.45px;
    color: var(--grey800);
}

.awe-layout-container .mui_custom_table .custom_table_responsive+.MuiTable-root .MuiTableCell-root {
    padding: 0px;
}

.awe-layout-container .mui_custom_table .custom_table_responsive+.MuiTable-root .MuiSelect-root {
    padding: 0px;
}

.awe-layout-container .mui_custom_table .custom_table_responsive+.MuiTable-root .MuiTablePagination-selectRoot {
    margin-right: 0px;
    padding-right: 22px;
}

.text_line1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.awe-layout-container .filter_dropdown .dropdown-menu {
    box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.25);
    right: 0;
    left: unset !important;
    background: #FFFFFF;
    border: 0px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.awe-layout-container .custom_select.MuiInputBase-root {
    width: 100%;
    display: block;
}

.awe-layout-container .custom_select .MuiSelect-select {
    border: 1px solid var(--light700);
    /*background: var(--white);
     */
    font-family: var(--fonts-OpenSans);
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    padding: 8px 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    width: auto !important;
}

.awe-layout-container .custom_select .custom_select__value-container {
    padding: 0px;
}

.MuiMenu-list .MuiMenuItem-root {
    font-family: var(--fonts-OpenSans);
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
}

.awe-layout-container .custom_select .custom_select__control {
    border: 1px solid var(--light700);
    /*background: var(--white);
     */
    font-family: var(--fonts-OpenSans);
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    padding: 8px 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    width: auto !important;
    padding: 0px;
    padding-left: 8px;
    padding-right: 8px;
    margin: 0px;
}

.awe-layout-container .custom_select .custom_select__indicator {
    padding-left: 0px;
    padding-right: 0px;
}

.awe-layout-container .custom_select .custom_select__menu-list .custom_select__option {
    font-family: var(--fonts-OpenSans);
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
}

.awe-layout-container .custom_select .custom_select__indicator-separator {
    display: none;
}

.awe-layout-container .form-control[type="date"]::-webkit-calendar-picker-indicator {
    background: url('../images/icons/calendar_icon.svg') no-repeat;
}

/* .awe-layout-container p.MuiTablePagination-caption {
    margin-bottom: 4px;
}

.awe-layout-container .MuiTableSortLabel-root .MuiTableSortLabel-icon {
    opacity: 1;
}

.awe-layout-container .custom_table {
    width: 100%;
}

.awe-layout-container .custom_table table {
    width: 100%;
    border-collapse: collapse;
}

.awe-layout-container .custom_table th,
.awe-layout-container .custom_table td {
    border: 1px solid #E9EDF5;
    padding: 10px;
    text-align: center;
}

.awe-layout-container .custom_table th {
    background: #F7F8FB;
    text-transform: uppercase;
    font-weight: 700;
}

.awe-layout-container .custom_table td {
    background: white;
}

.awe-layout-container .custom_table .Action,
.awe-layout-container .custom_table .Status,
.awe-layout-container .custom_table .DxCode,
.awe-layout-container .custom_table .PageNo {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 40px;
}

.awe-layout-container .custom_table .Tag {
    width: 48px;
    height: 22px;
    padding: 8px;
    background: #1A9698;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
}

.awe-layout-container .custom_table .PageNo,
.awe-layout-container .custom_table .RowsPerPage {
    display: flex;
    align-items: center;
    gap: 20px;
}

.awe-layout-container .custom_table .Button {
    padding: 4px;
    background: #F7F9FC;
    box-shadow: 0 0 0 1px rgba(70, 78.67, 96, 0.20);
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
} */

.awe-layout-container .mui_custom_table .table {
    font-size: 14px;
    color: var(--grey100);
    font-weight: 400;
    line-height: 20px;
}

.awe-layout-container .mui_custom_table .table thead {
    background-color: var(--light500);
}

.awe-layout-container .mui_custom_table .table thead th {
    border-bottom: 0px;
    font-size: 13px;
    color: var(--dark900);
    font-weight: 700;
    line-height: 20px;
    padding: 7px 16px;
    text-transform: uppercase;
}

.awe-layout-container .mui_custom_table .table thead th.sort_th .table_sort_icon {
    color: var(--grey600);
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
}

.awe-layout-container .mui_custom_table .table thead th.sort_th:hover .table_sort_icon {
    color: var(--dark900);
}

.awe-layout-container .mui_custom_table .table tbody td {
    padding: 7px 16px;
    vertical-align: middle;
}

.awe-layout-container .border-top-2 {
    border-top: 2px solid var(--border);
}

.border-warning-900 {
    border-color: var(--warning900) !important;
}

.border-danger-900 {
    border-color: var(--danger900) !important;
}

.border-dark-650 {
    border-color: var(--dark650) !important;
}

.custom_checkbox .MuiSvgIcon-root {
    color: var(--border);
    width: 24px;
    height: 24px;
}

.custom_checkbox .Mui-checked .MuiSvgIcon-root {
    color: var(--primary400);
}

@keyframes ping {
    0% {
        transform: scale(1);
        opacity: 0.5;
    }

    100% {
        transform: scale(2);
        opacity: 0;
    }
}

.dotsAnimation {
    position: relative;
    width: 10px;
    height: 10px;
    display: block;
    background-color: var(--dangerMain);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px;
}

.dotsAnimation::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--dangerMain);
}

.dotsAnimation::before {
    animation: ping 1.7s ease infinite;
    opacity: 0.25;
}


.custom_row {
    display: flex;
    gap: 16px;
}

.custom_row>* {
    flex: 1 0 auto;
    width: calc(50% - 8px);
}

.options li.selected {
    background-color: #f0f0f0 !important;
    /* Add your preferred highlight color */
}


.awe-layout-container div.custom-autocomplete input.MuiAutocomplete-input {
    border: 1px solid var(--light700);
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    padding: 8px 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.awe-layout-container div.custom-autocomplete div.MuiOutlinedInput-root {
    border: 0px solid var(--light700);
    padding: 0px;
}

.awe-layout-container button.cw-icon-design {
    background-color: #1a9698;
    color: #fff !important;
    border-radius: 50px;
    padding: 3px 5px;
    font-size: 10px;
    font-weight: bold;
}

.query-text p {
    margin-bottom:2px !important;
}

.ql-editor blockquote, .ql-editor h1, .ql-editor h2, .ql-editor h3, .ql-editor h4, .ql-editor h5, .ql-editor h6, .ql-editor ol, .ql-editor p, .ql-editor pre, .ql-editor ul {
    margin: 0 !important;
    padding: 0 !important;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}