@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400&display=swap");

.normal-layout-container {
  /* .card-header h6 {
    padding-left: 0px !important;
    padding-bottom: 0px !important;
  } */
  /* .issuer-box g {
    display: none !important;
  } */
  /* .navbar-toggler {
    display: none;
  } */
  /* .card-deck .card-body:hover {
    background: #5882e7 !important;
    color: #fff;
  } */
  /* .makeStyles-mainPanel-2 {
    padding-top: 2rem;
  } */
  /* .makeStyles-container-4 {
    padding-top: 60px !important;
  } */
  /* text {
    transform: translateY(10px);
  } */
  /* rect {
    transform: translateY(10px);
  } */
  /* circle {
    transform: translateY(10px);
  } */
  /* width */
  /* Track */
  /* Handle  change the color of thumb for more visible */
  /* Handle on hover */
}
body.normal-layout-container  {
  font-family: "Source Sans Pro", sans-serif;
  overflow: hidden !important;
}
.normal-layout-container h1,
.normal-layout-container h2,
.normal-layout-container h3,
.normal-layout-container h4,
.normal-layout-container h5,
.normal-layout-container h6 {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400 !important;
  font-size: 18px !important;
}
.normal-layout-container .MuiDialog-paper {
  overflow: hidden !important;
}
.normal-layout-container .makeStyles-background-661 {
  background-image: linear-gradient(-12deg, #fff 0, #fff 100%) !important;
}
.normal-layout-container p {
  font-family: "Source Sans Pro", sans-serif !important;
  font-weight: 400 !important;
}
.normal-layout-container a,
.normal-layout-container .MuiTableCell-root {
  font-family: "Source Sans Pro", sans-serif !important;
  font-weight: 400 !important;
  font-size: 15px !important;
}
.normal-layout-container .MuiButton-root {
  font-family: "Source Sans Pro", sans-serif !important;
  font-weight: bold !important;
}
.normal-layout-container .App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .normal-layout-container .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.normal-layout-container .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.normal-layout-container .risk-model .card {
  height: 338px;
  overflow-y: scroll !important;
}
.normal-layout-container .App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.normal-layout-container .highlight {
  background: yellow;
}
.normal-layout-container .bg-light {
  background: linear-gradient(-12deg, #526cec 0, #4565c1 100%) !important;
}
.normal-layout-container .box-height-chart {
  height: 330px;
}
.normal-layout-container .box-height-gapmember {
  height: 350px;
}
.normal-layout-container .makeStyles-background-14:after {
  background: #fff !important;
}
.normal-layout-container .makeStyles-background-14 {
  background-image: none !important;
}
.normal-layout-container .makeStyles-logo-9 {
  background-image: linear-gradient(120deg, #5299e6, #5299e6) !important;
}
.normal-layout-container .makeStyles-blue-63 {
  background: none !important;
  box-shadow: none !important;
  color: #000 !important;
}
.normal-layout-container .makeStyles-whiteFont-61,
.normal-layout-container .makeStyles-itemText-59 {
  color: #000 !important;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400 !important;
  font-size: 16px;
}
.normal-layout-container .navbar-light .navbar-text a {
  color: #fff !important;
  font-weight: bold;
}
.normal-layout-container .makeStyles-item-69 .active div,
.normal-layout-container .makeStyles-item-69 .active svg {
  color: #fff !important;
}
.normal-layout-container .navbar-light .navbar-text a svg {
  color: #fff !important;
}
.normal-layout-container .navbar {
  padding: 8px 1rem !important;
}
.normal-layout-container .makeStyles-logo-9 {
  padding: 9px 15px !important;
}
.normal-layout-container .makeStyles-drawerPaper-7 {
  box-shadow: 0 0 10px #ddd !important;
}
.normal-layout-container .makeStyles-itemLink-56 {
  background: none !important;
}
.normal-layout-container body {
  background: #f7f7f7 !important;
}
.normal-layout-container .card-header {
  background: none !important;
  border: 0px !important;
  padding: 10px 1.25rem 0.5rem 1.25rem !important;
}
.normal-layout-container .metal-level .card {
  height: 340px;
  overflow-y: scroll !important;
}
.normal-layout-container .card-body {
  box-shadow: none !important;
  padding: 0px 1.2rem !important;
}
.normal-layout-container .MuiToolbar-regular {
  min-height: 0px !important;
}
.normal-layout-container .awv_layout .MuiToolbar-regular {
  min-height: 57px !important;
}
.normal-layout-container .card,
.normal-layout-container .MuiPaper-elevation4 {
  border: 0px !important;
  box-shadow: none !important;
}
.normal-layout-container .MuiTableBody-root tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03);
}
.normal-layout-container .card {
  box-shadow: var(--devsite-page-box-shadow, 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15)) !important;
  border-radius: 0px !important;
}
.normal-layout-container .gap-issue {
  box-shadow: var(--devsite-page-box-shadow, 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15)) !important;
  border-radius: 0px !important;
}
.normal-layout-container .MuiPaper-elevation1 {
  box-shadow: 0 1px 3px rgba(33, 33, 33, 0.2) !important;
  background: #fff !important;
  height: auto !important;
}
.normal-layout-container .MuiPaper-elevation1L:hover {
  display: flex !important;
  align-items: center !important;
}
.normal-layout-container .card-deck .card {
  background: rgba(255, 255, 255, 0) !important;
  width: 100% !important;
}
.normal-layout-container .carousel .control-prev.control-arrow:before {
  border-right: 8px solid #5299e6;
}
.normal-layout-container .router-box {
  padding-top: 44px;
}
.normal-layout-container .card-deck .card-body {
  min-height: 1px;
  padding: 1.25rem;
  padding: 16px !important;
  font-size: 15px;
  flex-direction: row;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 8px !important;
  border-radius: 0px !important;
  width: auto;
  /* justify-content: space-between; */
}
.normal-layout-container .carousel .slide {
  width: 25%;
}
.normal-layout-container .card-deck .card-body .box {
  /* width: 25%; */
  display: flex;
  box-shadow: var(--devsite-page-box-shadow, 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(255, 255, 255, 0.15)) !important;
  margin: 0px 5px;
  padding: 14px 5px;
  border-radius: 0px;
  background-color: #fff;
}
.normal-layout-container .modular-page i {
  color: #577ee5;
  font-size: 4rem !important;
}
.normal-layout-container .widget-box .card {
  height: 390px;
  overflow-y: scroll !important;
}
.normal-layout-container .card-deck .card-body .box:hover {
  background: linear-gradient(-12deg, #2a57d7 0, #9eeeff 100%) !important;
  color: #fff;
}
.normal-layout-container .card-deck {
  overflow: hidden;
}
.normal-layout-container .carousel .thumbs-wrapper {
  display: none !important;
}
.normal-layout-container .carousel .control-arrow,
.normal-layout-container .carousel.carousel-slider .control-arrow {
  opacity: 1 !important;
}
.normal-layout-container .carousel.carousel-slider .control-arrow:hover {
  background: none !important;
}
.normal-layout-container .card {
  overflow: auto !important;
  overflow-y: hidden !important;
}
.normal-layout-container .card-deck .card {
  box-shadow: none !important;
  /* flex: auto !important; */
  margin: 0px !important;
}
.normal-layout-container .hcc-heatmap g text {
  /* font-size: 16px !important; */
  /* font-weight: bold; */
}
.normal-layout-container .makeStyles-wrapper-15 {
  height: 100% !important;
}
.normal-layout-container .card-deck .card-body .box:hover svg {
  color: #fff !important;
}
.normal-layout-container .main-text {
  font-size: 19px;
  margin-bottom: 7px !important;
  font-weight: 600 !important;
  /* width: 130px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.normal-layout-container .small-heading {
  font-size: 18px;
  margin-bottom: 0px !important;
}
.normal-layout-container .card-deck .card-body svg {
  color: #0d98ec !important;
  width: 44px !important;
  height: 44px !important;
  opacity: 1 !important;
  position: relative !important;
  top: 7px !important;
}
.normal-layout-container .makeStyles-content-3 {
  min-height: 100vh;
}
.normal-layout-container .makeStyles-paper-1 {
  margin-top: 0px !important;
  border-radius: 10px !important;
}
.normal-layout-container .heading {
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 0px;
  padding-left: 16px;
  color: #fff;
}
.normal-layout-container .logo {
  position: absolute;
  left: 5px;
  top: 0px;
  width: 131px;
  height: 56px;
}
.normal-layout-container body {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
}
.normal-layout-container div.main-layout-background {
  background: url("./assets/images/Invent_bg.png") !important;
  background-repeat: repeat-y;
  background-size: cover !important;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.normal-layout-container .makeStyles-avatar-2 {
  background-color: #245590 !important;
  margin: 0px !important;
}
.normal-layout-container .mainMenuRootSidebar div {
  font-weight: 600 !important;
}
.normal-layout-container .MuiListItem-button:hover {
  text-decoration: none;
  background-color: rgb(82, 108, 236) !important;
  color: rgb(239, 240, 247) !important;
}
.normal-layout-container .MuiButton-containedPrimary {
  background: #3d7ac3 !important;
}
.normal-layout-container .MuiButton-containedPrimary:hover {
  background: #3d7ac3 !important;
}
.normal-layout-container .MuiTypography-colorPrimary {
  color: #000 !important;
  font-weight: bold;
  font-size: 15px !important;
}
.normal-layout-container button.Mui-selected.MuiTab-fullWidth {
  color: rgb(255, 255, 255) !important;
  background: rgb(82, 108, 236) !important;
}
.normal-layout-container button.MuiButtonBase-root.MuiTab-root:hover {
  color: rgb(255, 255, 255) !important;
  background: rgb(82, 108, 236) !important;
}
.normal-layout-container .MUIDataTableHeadCell-sortActive-128 {
  color: rgb(255, 255, 255) !important;
}
.normal-layout-container .MUIDataTableHeadCell-sortActive-1076 {
  color: rgb(255, 255, 255) !important;
}
.normal-layout-container .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
  color: rgb(255, 255, 255) !important;
}
.normal-layout-container button.MuiButtonBase-root.MuiTab-root {
  color: #212529;
}
.normal-layout-container header.MuiAppBar-root.MuiAppBar-positionStatic.MuiAppBar-colorDefault {
  background: rgb(255, 255, 255) !important;
}
.normal-layout-container .MuiFormControl-marginNormal {
  margin-bottom: 25px !important;
  margin-top: 0px !important;
}
.normal-layout-container div.box-height-datagrid {
  height: 420px;
}
.normal-layout-container div.card-header h6.p-2 {
  text-transform: lowercase;
}
.normal-layout-container div.card-header h6.p-2::first-letter {
  text-transform: uppercase;
}
.normal-layout-container .MuiTypography-colorTextSecondary {
  color: #fff !important;
}
.normal-layout-container h6.header-title-text {
  text-transform: uppercase;
  font-weight: bold !important;
}
.normal-layout-container .modular-page i {
  color: #577ee5;
}
.normal-layout-container .modular-page h6 {
  font-size: 25px !important;
}
.normal-layout-container .home-heading {
  font-size: 2rem !important;
}
.normal-layout-container .modular-page .ui-item {
  height: 206px !important;
  padding-top: 39px;
}
.normal-layout-container .MuiPaper-root {
  transition: all 0.6s;
}
.normal-layout-container .modular-page .MuiCard-root:hover {
  background-image: linear-gradient(-12deg, #5982e8 0, #5982e8 100%) !important;
  color: #fff;
}
.normal-layout-container .modular-page .MuiPaper-root:hover i,
.normal-layout-container .modular-page .MuiPaper-elevation1:hover i,
.normal-layout-container .modular-page i:hover {
  color: #fff !important;
}
.normal-layout-container .makeStyles-blue-77 {
  box-shadow: none !important;
  background: none !important;
}
.normal-layout-container table {
  table-layout: fixed;
}
.normal-layout-container table tr th {
  font-size: 14px;
}
.normal-layout-container .btn-info,
.normal-layout-container .btn-info:hover,
.normal-layout-container .btn-info:focus {
  color: #000 !important;
  background-color: #fff !important;
  border-color: #ddd !important;
}
.normal-layout-container .makeStyles-background-28 {
  background-image: none !important;
}
.normal-layout-container .makeStyles-background-28:after {
  background: #fff !important;
}
.normal-layout-container .MuiAvatar-colorDefault {
  background-color: #5299e6 !important;
}
.normal-layout-container .makeStyles-paper-337 {
  margin-top: 0px !important;
}
.normal-layout-container .navbar-text,
.normal-layout-container .navbar-brand {
  padding: 0px !important;
}
.normal-layout-container .issue-details .card-body div[role=toolbar],
.normal-layout-container .members .card-body div[role=toolbar],
.normal-layout-container .hcc-list .card-body div[role=toolbar],
.normal-layout-container .member-list .card-body div[role=toolbar],
.normal-layout-container .OverallDiscrepency .card-body div[role=toolbar],
.normal-layout-container .coding .card-body div[role=toolbar],
.normal-layout-container .reports .card-body div[role=toolbar],
.normal-layout-container .list-member .card-body div[role=toolbar],
.normal-layout-container .hcc .card-body div[role=toolbar],
.normal-layout-container .aca-metrics .card-body div[role=toolbar] {
  position: absolute;
  top: 6px;
  right: 60px;
  padding-right: 0px;
}
@media screen and (max-width: 480px) {
  .normal-layout-container .issue-details .card-body div[role=toolbar],
.normal-layout-container .members .card-body div[role=toolbar],
.normal-layout-container .hcc-list .card-body div[role=toolbar],
.normal-layout-container .member-list .card-body div[role=toolbar],
.normal-layout-container .OverallDiscrepency .card-body div[role=toolbar],
.normal-layout-container .coding .card-body div[role=toolbar],
.normal-layout-container .reports .card-body div[role=toolbar],
.normal-layout-container .list-member .card-body div[role=toolbar],
.normal-layout-container .hcc .card-body div[role=toolbar],
.normal-layout-container .aca-metrics .card-body div[role=toolbar] {
    position: relative;
    top: 0px;
    left: 0px;
    right: 0px;
  }
  .normal-layout-container .risk-members,
.normal-layout-container .risk-model {
    margin-top: 20px;
  }
  .normal-layout-container .card {
    height: auto !important;
    margin-top: 25px !important;
  }
  .normal-layout-container .card-header > span.d-flex {
    display: block !important;
  }
}
.normal-layout-container .issue-details .card-body div[role=toolbar] button,
.normal-layout-container .members .card-body div[role=toolbar] button,
.normal-layout-container .hcc-list .card-body div[role=toolbar] button,
.normal-layout-container .member-list .card-body div[role=toolbar] button,
.normal-layout-container .OverallDiscrepency .card-body div[role=toolbar] button,
.normal-layout-container .coding .card-body div[role=toolbar] button,
.normal-layout-container .reports .card-body div[role=toolbar] button,
.normal-layout-container .list-member .card-body div[role=toolbar] button,
.normal-layout-container .hcc .card-body div[role=toolbar] button,
.normal-layout-container .aca-metrics .card-body div[role=toolbar] button {
  padding: 13px 10px;
}
.normal-layout-container .issue-details .card-body div[role=toolbar] button:hover,
.normal-layout-container .members .card-body div[role=toolbar] button:hover,
.normal-layout-container .hcc-list .card-body div[role=toolbar] button:hover,
.normal-layout-container .member-list .card-body div[role=toolbar] button:hover,
.normal-layout-container .OverallDiscrepency .card-body div[role=toolbar] button:hover,
.normal-layout-container .coding .card-body div[role=toolbar] button:hover,
.normal-layout-container .reports .card-body div[role=toolbar] button:hover,
.normal-layout-container .list-member .card-body div[role=toolbar] button:hover,
.normal-layout-container .hcc .card-body div[role=toolbar] button:hover,
.normal-layout-container .aca-metrics .card-body div[role=toolbar] button:hover {
  background-color: #fff !important;
  outline: 0;
}
.normal-layout-container .issue-details .card-body div[role=toolbar] .MuiSvgIcon-root,
.normal-layout-container .members .card-body div[role=toolbar] .MuiSvgIcon-root,
.normal-layout-container .hcc-list .card-body div[role=toolbar] .MuiSvgIcon-root,
.normal-layout-container .member-list .card-body div[role=toolbar] .MuiSvgIcon-root,
.normal-layout-container .OverallDiscrepency .card-body div[role=toolbar] .MuiSvgIcon-root,
.normal-layout-container .coding .card-body div[role=toolbar] .MuiSvgIcon-root,
.normal-layout-container .reports .card-body div[role=toolbar] .MuiSvgIcon-root,
.normal-layout-container .list-member .card-body div[role=toolbar] .MuiSvgIcon-root,
.normal-layout-container .hcc .card-body div[role=toolbar] .MuiSvgIcon-root,
.normal-layout-container .aca-metrics .card-body div[role=toolbar] .MuiSvgIcon-root {
  width: 18px;
}
.normal-layout-container .issue-details .card {
  height: 390px;
  overflow-y: scroll !important;
}
.normal-layout-container .risk-members .card {
  height: 390px;
}
.normal-layout-container .makeStyles-content-3 {
  height: 100vh !important;
  min-height: auto !important;
}
.normal-layout-container .makeStyles-wrapper-1 {
  height: auto !important;
}
.normal-layout-container a:hover {
  text-decoration: none !important;
}
.normal-layout-container .relative {
  position: relative;
}
.normal-layout-container .carousel .control-prev.control-arrow:before {
  border-right: 8px solid #3498ec !important;
}
.normal-layout-container .carousel .control-dots {
  display: none !important;
}
.normal-layout-container .gap-issue .card-body {
  height: 250px !important;
  overflow-y: scroll !important;
}
.normal-layout-container .level-summary .state-level {
  background: none !important;
  box-shadow: none !important;
}
.normal-layout-container .level-summary .state-level > .card-header:first-child {
  background-color: #fff !important;
  box-shadow: var(--devsite-page-box-shadow, 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15)) !important;
}
.normal-layout-container .issuer-table table tr td {
  margin: 10px !important;
  border-right: 1px solid #fff !important;
  border-left: 0px;
  border-bottom: 0px;
}
.normal-layout-container .issuer-table table tr td a {
  font-size: 15px;
  font-weight: bold;
}
.normal-layout-container .issuer-table table tr td:nth-child(5) div {
  padding: 3px 7px;
  /*font-size: 12px;*/
  /*font-weight: 400;*/
  line-height: 1;
  /* color: #fff; */
  /* background-color: #777; */
  border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
.normal-layout-container .issuer-table table,
.normal-layout-container .issuer-table table tfoot {
  border-top: 1px solid #ddd;
}
.normal-layout-container .issuer-table table tr th {
  border-bottom: 0px;
}
.normal-layout-container .header {
  position: fixed;
  right: 0px;
  left: 0px;
  height: 57px;
  z-index: 999;
  top: 0px;
}
.normal-layout-container .header1 {
  position: fixed;
  right: 0px;
  left: 0px;
  height: 57px;
  z-index: 999;
  box-shadow: 0px 0px 7px #313131;
  top: 0px;
  background-color: #526CEC !important;
}
.normal-layout-container .header2 {
  position: absolute;
  right: 0px;
  left: 0px;
  height: 57px;
  box-shadow: 0px 0px 7px #313131;
  top: 0px;
}
.normal-layout-container .header nav {
  display: none;
}
.normal-layout-container .gap-issue .bg-white {
  background-color: #fff !important;
  margin-bottom: 0px;
  padding: 22px;
}
.normal-layout-container .gap-issue {
  box-shadow: var(--devsite-page-box-shadow, 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15)) !important;
  background-color: #fff !important;
  height: 370px;
}
.normal-layout-container .bg-white {
  background-color: #fff !important;
  box-shadow: var(--devsite-page-box-shadow, 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15)) !important;
}
.normal-layout-container .member-gap .card {
  height: 400px;
  overflow-y: scroll !important;
}
.normal-layout-container .gaps .card {
  height: 368px;
  overflow-y: scroll !important;
}
.normal-layout-container .container,
.normal-layout-container .container-lg,
.normal-layout-container .container-md,
.normal-layout-container .container-sm,
.normal-layout-container .container-xl,
.normal-layout-container .MuiContainer-maxWidthLg {
  max-width: 100% !important;
}
.normal-layout-container .MuiContainer-maxWidthLg {
  padding: 0px !important;
}
.normal-layout-container .chart-review .card {
  height: 370px;
  overflow-y: scroll !important;
}
.normal-layout-container .chart {
  margin-top: 50px !important;
}
.normal-layout-container .bar_chart {
  margin-top: 10px !important;
  padding-bottom: 10px !important;
  margin-bottom: 10px !important;
}
.normal-layout-container .bar_chart_transform text {
  /* transform: translateY(10px); */
}
.normal-layout-container .tab-content-title-div {
  background: white;
  padding: 3px;
  margin: 0px 0px;
}
.normal-layout-container .pg-bottom {
  margin-bottom: 50px;
}
.normal-layout-container .slick-prev:before,
.normal-layout-container .slick-next:before {
  color: #3498ec !important;
}
.normal-layout-container .relative.box {
  display: flex !important;
  background-color: #fff;
  box-shadow: var(--devsite-page-box-shadow, 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(255, 255, 255, 0.15)) !important;
  padding: 14px 5px;
  border-radius: 0px;
  cursor: pointer;
}
.normal-layout-container .relative.box .bg1.pl-2.text-left {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.normal-layout-container .slick-slide {
  display: flex;
  padding: 0px 5px;
}
.normal-layout-container .slick-slide svg {
  color: #0d98ec !important;
  width: 44px !important;
  height: 44px !important;
  opacity: 1 !important;
  position: relative !important;
  top: 7px !important;
}
.normal-layout-container .card-body .box:hover {
  background: linear-gradient(-12deg, #2a57d7 0, #9eeeff 100%) !important;
  color: #fff;
}
.normal-layout-container .card-body .box:hover svg {
  color: #fff !important;
}
.normal-layout-container .MUIDataTable-responsiveStacked-83 {
  overflow: hidden !important;
}
.normal-layout-container .MuiTablePagination-root {
  overflow: hidden !important;
}
.normal-layout-container .hcc-heatmap tspan {
  text-overflow: ellipsis;
}
.normal-layout-container .header-notification-badge .MuiBadge-badge {
  height: 20px;
  display: flex;
  padding: 0px 4px;
  z-index: 1;
  position: absolute;
  flex-wrap: wrap;
  font-size: 0.75rem;
  min-width: 15px;
  box-sizing: border-box;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 100;
  line-height: 1;
  align-content: center;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
}
.normal-layout-container .header-notification-badge .MuiBadge-colorSecondary {
  color: #fff;
  background-color: #f50057;
  margin-top: 5px;
  margin-right: 2px;
}
.normal-layout-container .notice-dropdown .dropdown-menu {
  min-width: 320px;
  padding: 0;
}
.normal-layout-container .notice-dropdown .dropdown-menuheader {
  padding: 12px 20px;
  font-size: 13px;
  border-bottom: 1px solid #f2f4f9;
}
.normal-layout-container .notice-dropdown .drop-menucontent {
  max-height: 290px;
  position: relative;
  overflow-y: auto;
}
.normal-layout-container .notice-dropdown .drop-noticeRow:not(:last-child) {
  border-bottom: 1px solid #f3f3f3;
}
.normal-layout-container .notice-dropdown .drop-noticeRow {
  display: block;
  align-items: center;
  padding: 9px 16px;
  width: 100%;
}
.normal-layout-container .notice-dropdown .drop-noticeRow h4 {
  color: #18214d;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
  text-align: left;
  overflow: hidden;
  margin-bottom: 2px;
  width: 100%;
}
.normal-layout-container .notice-dropdown .drop-noticeRow .desc {
  color: #18214d;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  overflow: hidden;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 2px;
}
.normal-layout-container .notice-dropdown .dropdown-menu.show {
  opacity: 1;
  transform: translateY(10px) !important;
  visibility: visible;
}
.normal-layout-container .notice-dropdown .dropdown-menu {
  max-width: 280px;
  min-width: 200px;
  position: absolute;
  right: 0;
  left: auto;
  border-radius: 4px;
  padding: 12px 4px;
  border: none;
  z-index: 99;
  -webkit-box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.3);
  transition: 0.3s;
  transform: translateY(30px) !important;
  display: block;
  opacity: 0;
  visibility: hidden;
}
.normal-layout-container .header-notification-badge {
  cursor: pointer;
}
.normal-layout-container svg text {
  font-family: "Source Sans Pro", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400 !important;
  line-height: 1.5em !important;
  font-style: normal !important;
  stroke: none !important;
  stroke-width: 0 !important;
}
.normal-layout-container html {
  position: fixed;
  height: 100%;
  overflow: hidden;
}
.normal-layout-container body {
  /* margin: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; */
}
.normal-layout-container #headerTop {
  margin-bottom: 100px;
}
.normal-layout-container .MuiSelect-selectMenu {
  min-height: 1.576em !important;
}
.normal-layout-container .MuiOutlinedInput-inputMarginDense {
  padding-top: 17.5px !important;
}
.normal-layout-container div.card-header h6.text-bold {
  font-weight: bold !important;
}
.normal-layout-container div.card-header h6.text-capital {
  text-transform: uppercase;
}
.normal-layout-container .MuiCard-root.disabled {
  background: rgb(220, 220, 220) !important;
  color: rgba(0, 0, 0, 0.87) !important;
  cursor: auto !important;
  opacity: 0.9 !important;
}
.normal-layout-container .MuiCard-root.disabled:hover {
  background: rgb(220, 220, 220) !important;
  color: rgba(0, 0, 0, 0.87) !important;
  cursor: auto !important;
  opacity: 0.9 !important;
}
.normal-layout-container .MuiCard-root.disabled:hover i,
.normal-layout-container .MuiCard-root.disabled:hover i,
.normal-layout-container .MuiCard-root.disabled i:hover {
  color: #5982E8 !important;
  cursor: auto !important;
  opacity: 0.9 !important;
}
.normal-layout-container .awv-table-action .custom-btn-design {
  cursor: pointer !important;
  color: #fff !important;
  border-radius: 5px !important;
}
.normal-layout-container .awv-form-upload-div {
  background-color: #fff;
  height: 270px;
  border: 3px dashed #9f9d9d;
}
.normal-layout-container .awv-file-upload-icon {
  cursor: pointer !important;
  background-color: #ac10f2;
  color: #fff;
  padding: 4px;
  border-radius: 5px;
}
.normal-layout-container div.custom-action-column thead.MuiTableHead-root th:last-child {
  width: 300px;
}
.normal-layout-container div.awv_layout div.awv-circuler-progress {
  min-width: 100px;
  width: 11.2%;
  margin-left: 2.7% !important;
  flex: 0 0 auto;
}
.normal-layout-container div.awv_layout main.MuiBox-root {
  /* width: 100% !important; */
}
.normal-layout-container div.awv_layout {
  background-color: #fff !important;
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: auto !important;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  /* font-family: "Open Sans !important"; */
}
.normal-layout-container div.awv_layout a,
.normal-layout-container div.awv_layout .MuiTableCell-root,
.normal-layout-container div.awv_model_layout a,
.normal-layout-container div.awv_model_layout .MuiDialog-container {
  font-weight: 400;
  font-size: 13px !important;
}
.normal-layout-container div.awv_layout h6.MuiTypography-root {
  font-weight: 600 !important;
}
.normal-layout-container div.awv_layout button:focus {
  outline: none !important;
}
.normal-layout-container div.awv_model_layout ::-webkit-file-upload-button,
.normal-layout-container div.awv_layout_right_sidebar ::-webkit-file-upload-button {
  color: #fff;
  padding: 1em;
  background: #1d9a9b;
  border: 1px solid #1d9a9b;
}
.normal-layout-container div.awv_model_layout .custom_file_upload input[type=file],
.normal-layout-container div.awv_layout_right_sidebar .custom_file_upload input[type=file] {
  margin: unset;
  padding: unset;
  height: 55px;
  border-radius: 5px;
  background: transparent;
}
.normal-layout-container div.awv_layout :focus-visible {
  outline: none !important;
}
.normal-layout-container div.awv_layout .MuiAvatar-colorDefault {
  background-color: #1a9698 !important;
}
.normal-layout-container div.awv_model_layout .MuiFormLabel-root.Mui-focused {
  color: #1a9698 !important;
}
.normal-layout-container div.awv_model_layout .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #1a9698 !important;
}
.normal-layout-container div.awv_layout div.breadcrumb {
  background-color: #F7F7F7 !important;
}
.normal-layout-container div.awv_layout div.MuiCollapse-wrapperInner {
  width: 93% !important;
}
.normal-layout-container div.awv_layout .mainMenuRootSidebar div {
  font-weight: 500 !important;
  font-size: 15px !important;
}
.normal-layout-container div.awv_layout button.MuiButtonBase-root.MuiIconButton-root svg#expandable-button {
  margin-left: 15px !important;
  color: #fff;
  background: #1D9A9B;
}
.normal-layout-container .awv_layout .sidebar_custom .MuiPaper-root {
  background-color: #393a41 !important;
  margin: 10px 0px 0px 10px;
  border-radius: 15px;
  /* position: absolute; */
}
.normal-layout-container .awv_layout .MuiListItem-button:hover {
  text-decoration: none;
  background-color: transparent !important;
  color: rgb(239, 240, 247) !important;
}
.normal-layout-container .awv_layout .MuiListItem-button {
  text-decoration: none;
  background-color: transparent !important;
  color: rgb(239, 240, 247) !important;
}
.normal-layout-container .awv_layout header.MuiPaper-root {
  background-color: #f7f7f7 !important;
}
.normal-layout-container .awv_layout .logo {
  position: absolute;
  left: 5px;
  top: 0px;
  width: 121px;
  height: 50px;
  padding: 4px;
  margin-top: 7px;
}
.normal-layout-container .awv_layout hr.sidebar-awv-custom {
  background-color: #55555B;
  color: #55555B;
}
.normal-layout-container div.awv_layout a:hover {
  color: #1a9698;
}
.normal-layout-container div.awv_layout a.custom-active {
  color: #1a9698;
}
.normal-layout-container div.awv_layout .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
  color: #202020 !important;
}
.normal-layout-container div.awv_layout div.expand-grid-custom {
  border-left: 5px solid rgb(26, 150, 152) !important;
}
.normal-layout-container div.awv_layout_right_sidebar div.expand-grid-custom {
  border-left: 5px solid rgb(26, 150, 152) !important;
}
.normal-layout-container div.awv_layout_right_sidebar div.awv-recored-right-sidebar-form {
  font-size: 12px !important;
}
.normal-layout-container div.awv_layout .expand-grid-warning-status,
.normal-layout-container div.awv_model_layout .expand-grid-warning-status,
.normal-layout-container div.awv_layout_right_sidebar .expand-grid-warning-status {
  background: #f1c40f;
  border-radius: 25px !important;
  color: #fff;
}
.normal-layout-container div.awv_layout .expand-grid-success-status,
.normal-layout-container div.awv_model_layout .expand-grid-success-status,
.normal-layout-container div.awv_layout_right_sidebar .expand-grid-success-status {
  background: #1a9698;
  border-radius: 25px !important;
  color: #fff;
}
.normal-layout-container div.awv_layout .expand-grid-primary-status,
.normal-layout-container div.awv_model_layout .expand-grid-primary-status,
.normal-layout-container div.awv_layout_right_sidebar .expand-grid-primary-status {
  background: #1a9698;
  border-radius: 25px !important;
  color: #fff;
}
.normal-layout-container div.awv_layout .expand-grid-danger-status,
.normal-layout-container div.awv_model_layout .expand-grid-danger-status,
.normal-layout-container div.awv_layout_right_sidebar .expand-grid-danger-status {
  background: #dc3545;
  border-radius: 25px !important;
  color: #fff;
}
.normal-layout-container div.awv_layout .btn-custom-warning,
.normal-layout-container div.awv_model_layout .btn-custom-warning,
.normal-layout-container div.awv_layout_right_sidebar .btn-custom-warning {
  background: #f1c40f;
  color: #fff;
}
.normal-layout-container div.awv_layout .btn-custom-primary,
.normal-layout-container div.awv_model_layout .btn-custom-primary,
.normal-layout-container div.awv_layout_right_sidebar .btn-custom-primary {
  background: #1a9698;
  color: #fff;
}
.normal-layout-container div.awv_layout .btn-custom-danger,
.normal-layout-container div.awv_model_layout .btn-custom-danger,
.normal-layout-container div.awv_layout_right_sidebar .btn-custom-danger {
  background: #dc3545;
  color: #fff;
}
.normal-layout-container div.awv_layout .btn-custom-success,
.normal-layout-container div.awv_model_layout .btn-custom-success,
.normal-layout-container div.awv_layout_right_sidebar .btn-custom-success {
  background: #28a745;
  color: #fff;
}
.normal-layout-container div.awv_layout .btn-custom-primary:disabled,
.normal-layout-container div.awv_model_layout .btn-custom-primary:disabled,
.normal-layout-container div.awv_layout_right_sidebar .btn-custom-primary:disabled,
.normal-layout-container div.awv_layout .btn-custom-warning:disabled,
.normal-layout-container div.awv_model_layout .btn-custom-warning:disabled,
.normal-layout-container div.awv_layout_right_sidebar .btn-custom-warning:disabled,
.normal-layout-container div.awv_layout .btn-custom-success:disabled,
.normal-layout-container div.awv_model_layout .btn-custom-success:disabled,
.normal-layout-container div.awv_layout_right_sidebar .btn-custom-success:disabled,
.normal-layout-container div.awv_layout .btn-custom-danger:disabled,
.normal-layout-container div.awv_model_layout .btn-custom-danger:disabled,
.normal-layout-container div.awv_layout_right_sidebar .btn-custom-danger:disabled {
  background: #e3e3e3;
  color: #7f7c7c;
}
.normal-layout-container div.awv_layout .txt-custom-warning,
.normal-layout-container div.awv_model_layout .txt-custom-warning,
.normal-layout-container div.awv_layout_right_sidebar .txt-custom-warning {
  color: #f1c40f;
}
.normal-layout-container div.awv_layout .txt-custom-primary,
.normal-layout-container div.awv_model_layout .txt-custom-primary,
.normal-layout-container div.awv_layout_right_sidebar .txt-custom-primary {
  color: #1a9698;
}
.normal-layout-container div.awv_layout .txt-custom-danger,
.normal-layout-container div.awv_model_layout .txt-custom-danger,
.normal-layout-container div.awv_layout_right_sidebar .txt-custom-danger {
  color: #dc3545;
}
.normal-layout-container div.awv_layout .txt-custom-success,
.normal-layout-container div.awv_model_layout .txt-custom-success,
.normal-layout-container div.awv_layout_right_sidebar .txt-custom-success {
  color: #28a745;
}
.normal-layout-container div.awv_layout button.MuiButtonBase-root.MuiTab-root:hover {
  color: rgb(255, 255, 255) !important;
  background: #1a9698 !important;
}
.normal-layout-container div.awv_layout div.MuiTabs-root .Mui-selected {
  color: #1a9698 !important;
}
.normal-layout-container div.awv_layout div.MuiTabs-root .mui-tab-label.Mui-selected {
  background: #fff !important;
  color: #1a9698 !important;
}
.normal-layout-container div.awv_layout div.MuiTabs-root .mui-tab-label.Mui-selected:hover {
  background: #1a9698 !important;
  color: #fff !important;
}
.normal-layout-container div.awv_layout div.MuiTabs-root span.MuiTabs-indicator {
  background: #1a9698 !important;
}
.normal-layout-container div.awv_layout div.circular-content {
  line-height: 1.2;
}
.normal-layout-container div.awv_layout div.custom-column-yearly-chart {
  margin-top: -12%;
  margin-right: 15%;
}
.normal-layout-container div#chartdivStack svg g[opacity="0.4"],
.normal-layout-container div#chartdivStack svg g[opacity="0.3"],
.normal-layout-container div#chartdivStack svg g[opacity="0.2"],
.normal-layout-container div#chartdivStack svg g[opacity="0.1"] {
  display: none;
}
@media only screen and (min-width: 1366px) {
  .normal-layout-container {
    /* For desktop: */
  }
  .normal-layout-container div.awv_layout div.custom-column-yearly-chart {
    margin-top: -7%;
    margin-right: 15%;
  }
}
.normal-layout-container div.awv_layout a.quick-links {
  font-size: 15px !important;
}
.normal-layout-container div.awv_layout ::-webkit-scrollbar {
  width: 10px;
}
.normal-layout-container div.awv_layout ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
.normal-layout-container div.awv_layout ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.normal-layout-container div.awv_layout ::-webkit-scrollbar-thumb:hover {
  background: #e3e3e3;
}
.normal-layout-container div.awv_layout .MuiFormControl-root.selectCustomDesign {
  font-size: 12px !important;
  z-index: 110 !important;
  max-width: 200px;
}
.normal-layout-container .custom-autocomplete ul.options {
  position: absolute;
  list-style: none;
  padding: 0;
  margin-top: 55px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  z-index: 1;
  max-height: 200px;
  overflow: auto;
}
.normal-layout-container .custom-autocomplete ul.options li {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
}
.normal-layout-container .custom-autocomplete ul.options li:hover {
  background-color: #f0f0f0;
}
.normal-layout-container div.awv_layout_right_sidebar div.history-custom-warning {
  border-left: 5px solid #f1c40f !important;
}
.normal-layout-container div.awv_layout_right_sidebar div.history-custom-primary {
  border-left: 5px solid #E9731D !important;
}
.normal-layout-container div.awv_layout_right_sidebar div.history-custom-primary {
  border-left: 5px solid rgb(26, 150, 152) !important;
}
.normal-layout-container div.awv_layout_right_sidebar div.history-custom-danger {
  border-left: 5px solid red !important;
}

/* Add this to your CSS file */
.spinner {
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top: 2px solid #fff;
  border-radius: 50%;
  width: 1em; /* Use em to make the size relative to the font size */
  height: 1em; /* Use em to make the size relative to the font size */
  animation: spin 0.6s linear infinite;
  margin-left: 0.5em; /* Add margin to the left to create space */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
