.pcp_details_table table tr th {
    width: 105px;
    word-wrap: none;
}

.claim_detail_table table tr th {
    position: sticky;
   width: 105px;
    word-wrap: normal;
    height: 10px;
}.Dynamic_detail_table table tr th {
    width: 200px;
    word-wrap: none;
    position: static;
 }
  .recharts-legend-item ,.legend-item-0
{
    display: inline-block !important;
    margin-right: 10px !important;
    /* border: 1px solid; */
    position: relative !important;
    bottom: 19rem !important;
}
.recharts-legend-item-text{
    color: #666 !important;
    font-size: 12px !important;
}
.slick-slide{
    min-width: 13rem;
}
.slick-track{
    display: flex;
    justify-content: center;
}
.autoFit {
  position:absolute;
  top:20px;
  right:0px;
  bottom:0px;
  left:20px;
}