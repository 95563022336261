@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap);
body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  height: 100%;
}


.normal-layout-container {
  /* .card-header h6 {
    padding-left: 0px !important;
    padding-bottom: 0px !important;
  } */
  /* .issuer-box g {
    display: none !important;
  } */
  /* .navbar-toggler {
    display: none;
  } */
  /* .card-deck .card-body:hover {
    background: #5882e7 !important;
    color: #fff;
  } */
  /* .makeStyles-mainPanel-2 {
    padding-top: 2rem;
  } */
  /* .makeStyles-container-4 {
    padding-top: 60px !important;
  } */
  /* text {
    transform: translateY(10px);
  } */
  /* rect {
    transform: translateY(10px);
  } */
  /* circle {
    transform: translateY(10px);
  } */
  /* width */
  /* Track */
  /* Handle  change the color of thumb for more visible */
  /* Handle on hover */
}
body.normal-layout-container  {
  font-family: "Source Sans Pro", sans-serif;
  overflow: hidden !important;
}
.normal-layout-container h1,
.normal-layout-container h2,
.normal-layout-container h3,
.normal-layout-container h4,
.normal-layout-container h5,
.normal-layout-container h6 {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400 !important;
  font-size: 18px !important;
}
.normal-layout-container .MuiDialog-paper {
  overflow: hidden !important;
}
.normal-layout-container .makeStyles-background-661 {
  background-image: linear-gradient(-12deg, #fff 0, #fff 100%) !important;
}
.normal-layout-container p {
  font-family: "Source Sans Pro", sans-serif !important;
  font-weight: 400 !important;
}
.normal-layout-container a,
.normal-layout-container .MuiTableCell-root {
  font-family: "Source Sans Pro", sans-serif !important;
  font-weight: 400 !important;
  font-size: 15px !important;
}
.normal-layout-container .MuiButton-root {
  font-family: "Source Sans Pro", sans-serif !important;
  font-weight: bold !important;
}
.normal-layout-container .App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .normal-layout-container .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}
.normal-layout-container .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.normal-layout-container .risk-model .card {
  height: 338px;
  overflow-y: scroll !important;
}
.normal-layout-container .App-link {
  color: #61dafb;
}
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.normal-layout-container .highlight {
  background: yellow;
}
.normal-layout-container .bg-light {
  background: linear-gradient(-12deg, #526cec 0, #4565c1 100%) !important;
}
.normal-layout-container .box-height-chart {
  height: 330px;
}
.normal-layout-container .box-height-gapmember {
  height: 350px;
}
.normal-layout-container .makeStyles-background-14:after {
  background: #fff !important;
}
.normal-layout-container .makeStyles-background-14 {
  background-image: none !important;
}
.normal-layout-container .makeStyles-logo-9 {
  background-image: linear-gradient(120deg, #5299e6, #5299e6) !important;
}
.normal-layout-container .makeStyles-blue-63 {
  background: none !important;
  box-shadow: none !important;
  color: #000 !important;
}
.normal-layout-container .makeStyles-whiteFont-61,
.normal-layout-container .makeStyles-itemText-59 {
  color: #000 !important;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400 !important;
  font-size: 16px;
}
.normal-layout-container .navbar-light .navbar-text a {
  color: #fff !important;
  font-weight: bold;
}
.normal-layout-container .makeStyles-item-69 .active div,
.normal-layout-container .makeStyles-item-69 .active svg {
  color: #fff !important;
}
.normal-layout-container .navbar-light .navbar-text a svg {
  color: #fff !important;
}
.normal-layout-container .navbar {
  padding: 8px 1rem !important;
}
.normal-layout-container .makeStyles-logo-9 {
  padding: 9px 15px !important;
}
.normal-layout-container .makeStyles-drawerPaper-7 {
  box-shadow: 0 0 10px #ddd !important;
}
.normal-layout-container .makeStyles-itemLink-56 {
  background: none !important;
}
.normal-layout-container body {
  background: #f7f7f7 !important;
}
.normal-layout-container .card-header {
  background: none !important;
  border: 0px !important;
  padding: 10px 1.25rem 0.5rem 1.25rem !important;
}
.normal-layout-container .metal-level .card {
  height: 340px;
  overflow-y: scroll !important;
}
.normal-layout-container .card-body {
  box-shadow: none !important;
  padding: 0px 1.2rem !important;
}
.normal-layout-container .MuiToolbar-regular {
  min-height: 0px !important;
}
.normal-layout-container .awv_layout .MuiToolbar-regular {
  min-height: 57px !important;
}
.normal-layout-container .card,
.normal-layout-container .MuiPaper-elevation4 {
  border: 0px !important;
  box-shadow: none !important;
}
.normal-layout-container .MuiTableBody-root tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03);
}
.normal-layout-container .card {
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15) !important;
  box-shadow: var(--devsite-page-box-shadow, 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15)) !important;
  border-radius: 0px !important;
}
.normal-layout-container .gap-issue {
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15) !important;
  box-shadow: var(--devsite-page-box-shadow, 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15)) !important;
  border-radius: 0px !important;
}
.normal-layout-container .MuiPaper-elevation1 {
  box-shadow: 0 1px 3px rgba(33, 33, 33, 0.2) !important;
  background: #fff !important;
  height: auto !important;
}
.normal-layout-container .MuiPaper-elevation1L:hover {
  display: flex !important;
  align-items: center !important;
}
.normal-layout-container .card-deck .card {
  background: rgba(255, 255, 255, 0) !important;
  width: 100% !important;
}
.normal-layout-container .carousel .control-prev.control-arrow:before {
  border-right: 8px solid #5299e6;
}
.normal-layout-container .router-box {
  padding-top: 44px;
}
.normal-layout-container .card-deck .card-body {
  min-height: 1px;
  padding: 1.25rem;
  padding: 16px !important;
  font-size: 15px;
  flex-direction: row;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 8px !important;
  border-radius: 0px !important;
  width: auto;
  /* justify-content: space-between; */
}
.normal-layout-container .carousel .slide {
  width: 25%;
}
.normal-layout-container .card-deck .card-body .box {
  /* width: 25%; */
  display: flex;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(255, 255, 255, 0.15) !important;
  box-shadow: var(--devsite-page-box-shadow, 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(255, 255, 255, 0.15)) !important;
  margin: 0px 5px;
  padding: 14px 5px;
  border-radius: 0px;
  background-color: #fff;
}
.normal-layout-container .modular-page i {
  color: #577ee5;
  font-size: 4rem !important;
}
.normal-layout-container .widget-box .card {
  height: 390px;
  overflow-y: scroll !important;
}
.normal-layout-container .card-deck .card-body .box:hover {
  background: linear-gradient(-12deg, #2a57d7 0, #9eeeff 100%) !important;
  color: #fff;
}
.normal-layout-container .card-deck {
  overflow: hidden;
}
.normal-layout-container .carousel .thumbs-wrapper {
  display: none !important;
}
.normal-layout-container .carousel .control-arrow,
.normal-layout-container .carousel.carousel-slider .control-arrow {
  opacity: 1 !important;
}
.normal-layout-container .carousel.carousel-slider .control-arrow:hover {
  background: none !important;
}
.normal-layout-container .card {
  overflow: auto !important;
  overflow-y: hidden !important;
}
.normal-layout-container .card-deck .card {
  box-shadow: none !important;
  /* flex: auto !important; */
  margin: 0px !important;
}
.normal-layout-container .hcc-heatmap g text {
  /* font-size: 16px !important; */
  /* font-weight: bold; */
}
.normal-layout-container .makeStyles-wrapper-15 {
  height: 100% !important;
}
.normal-layout-container .card-deck .card-body .box:hover svg {
  color: #fff !important;
}
.normal-layout-container .main-text {
  font-size: 19px;
  margin-bottom: 7px !important;
  font-weight: 600 !important;
  /* width: 130px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.normal-layout-container .small-heading {
  font-size: 18px;
  margin-bottom: 0px !important;
}
.normal-layout-container .card-deck .card-body svg {
  color: #0d98ec !important;
  width: 44px !important;
  height: 44px !important;
  opacity: 1 !important;
  position: relative !important;
  top: 7px !important;
}
.normal-layout-container .makeStyles-content-3 {
  min-height: 100vh;
}
.normal-layout-container .makeStyles-paper-1 {
  margin-top: 0px !important;
  border-radius: 10px !important;
}
.normal-layout-container .heading {
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 0px;
  padding-left: 16px;
  color: #fff;
}
.normal-layout-container .logo {
  position: absolute;
  left: 5px;
  top: 0px;
  width: 131px;
  height: 56px;
}
.normal-layout-container body {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
}
.normal-layout-container div.main-layout-background {
  background: url(/static/media/Invent_bg.194907ec.png) !important;
  background-repeat: repeat-y;
  background-size: cover !important;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.normal-layout-container .makeStyles-avatar-2 {
  background-color: #245590 !important;
  margin: 0px !important;
}
.normal-layout-container .mainMenuRootSidebar div {
  font-weight: 600 !important;
}
.normal-layout-container .MuiListItem-button:hover {
  text-decoration: none;
  background-color: rgb(82, 108, 236) !important;
  color: rgb(239, 240, 247) !important;
}
.normal-layout-container .MuiButton-containedPrimary {
  background: #3d7ac3 !important;
}
.normal-layout-container .MuiButton-containedPrimary:hover {
  background: #3d7ac3 !important;
}
.normal-layout-container .MuiTypography-colorPrimary {
  color: #000 !important;
  font-weight: bold;
  font-size: 15px !important;
}
.normal-layout-container button.Mui-selected.MuiTab-fullWidth {
  color: rgb(255, 255, 255) !important;
  background: rgb(82, 108, 236) !important;
}
.normal-layout-container button.MuiButtonBase-root.MuiTab-root:hover {
  color: rgb(255, 255, 255) !important;
  background: rgb(82, 108, 236) !important;
}
.normal-layout-container .MUIDataTableHeadCell-sortActive-128 {
  color: rgb(255, 255, 255) !important;
}
.normal-layout-container .MUIDataTableHeadCell-sortActive-1076 {
  color: rgb(255, 255, 255) !important;
}
.normal-layout-container .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
  color: rgb(255, 255, 255) !important;
}
.normal-layout-container button.MuiButtonBase-root.MuiTab-root {
  color: #212529;
}
.normal-layout-container header.MuiAppBar-root.MuiAppBar-positionStatic.MuiAppBar-colorDefault {
  background: rgb(255, 255, 255) !important;
}
.normal-layout-container .MuiFormControl-marginNormal {
  margin-bottom: 25px !important;
  margin-top: 0px !important;
}
.normal-layout-container div.box-height-datagrid {
  height: 420px;
}
.normal-layout-container div.card-header h6.p-2 {
  text-transform: lowercase;
}
.normal-layout-container div.card-header h6.p-2::first-letter {
  text-transform: uppercase;
}
.normal-layout-container .MuiTypography-colorTextSecondary {
  color: #fff !important;
}
.normal-layout-container h6.header-title-text {
  text-transform: uppercase;
  font-weight: bold !important;
}
.normal-layout-container .modular-page i {
  color: #577ee5;
}
.normal-layout-container .modular-page h6 {
  font-size: 25px !important;
}
.normal-layout-container .home-heading {
  font-size: 2rem !important;
}
.normal-layout-container .modular-page .ui-item {
  height: 206px !important;
  padding-top: 39px;
}
.normal-layout-container .MuiPaper-root {
  transition: all 0.6s;
}
.normal-layout-container .modular-page .MuiCard-root:hover {
  background-image: linear-gradient(-12deg, #5982e8 0, #5982e8 100%) !important;
  color: #fff;
}
.normal-layout-container .modular-page .MuiPaper-root:hover i,
.normal-layout-container .modular-page .MuiPaper-elevation1:hover i,
.normal-layout-container .modular-page i:hover {
  color: #fff !important;
}
.normal-layout-container .makeStyles-blue-77 {
  box-shadow: none !important;
  background: none !important;
}
.normal-layout-container table {
  table-layout: fixed;
}
.normal-layout-container table tr th {
  font-size: 14px;
}
.normal-layout-container .btn-info,
.normal-layout-container .btn-info:hover,
.normal-layout-container .btn-info:focus {
  color: #000 !important;
  background-color: #fff !important;
  border-color: #ddd !important;
}
.normal-layout-container .makeStyles-background-28 {
  background-image: none !important;
}
.normal-layout-container .makeStyles-background-28:after {
  background: #fff !important;
}
.normal-layout-container .MuiAvatar-colorDefault {
  background-color: #5299e6 !important;
}
.normal-layout-container .makeStyles-paper-337 {
  margin-top: 0px !important;
}
.normal-layout-container .navbar-text,
.normal-layout-container .navbar-brand {
  padding: 0px !important;
}
.normal-layout-container .issue-details .card-body div[role=toolbar],
.normal-layout-container .members .card-body div[role=toolbar],
.normal-layout-container .hcc-list .card-body div[role=toolbar],
.normal-layout-container .member-list .card-body div[role=toolbar],
.normal-layout-container .OverallDiscrepency .card-body div[role=toolbar],
.normal-layout-container .coding .card-body div[role=toolbar],
.normal-layout-container .reports .card-body div[role=toolbar],
.normal-layout-container .list-member .card-body div[role=toolbar],
.normal-layout-container .hcc .card-body div[role=toolbar],
.normal-layout-container .aca-metrics .card-body div[role=toolbar] {
  position: absolute;
  top: 6px;
  right: 60px;
  padding-right: 0px;
}
@media screen and (max-width: 480px) {
  .normal-layout-container .issue-details .card-body div[role=toolbar],
.normal-layout-container .members .card-body div[role=toolbar],
.normal-layout-container .hcc-list .card-body div[role=toolbar],
.normal-layout-container .member-list .card-body div[role=toolbar],
.normal-layout-container .OverallDiscrepency .card-body div[role=toolbar],
.normal-layout-container .coding .card-body div[role=toolbar],
.normal-layout-container .reports .card-body div[role=toolbar],
.normal-layout-container .list-member .card-body div[role=toolbar],
.normal-layout-container .hcc .card-body div[role=toolbar],
.normal-layout-container .aca-metrics .card-body div[role=toolbar] {
    position: relative;
    top: 0px;
    left: 0px;
    right: 0px;
  }
  .normal-layout-container .risk-members,
.normal-layout-container .risk-model {
    margin-top: 20px;
  }
  .normal-layout-container .card {
    height: auto !important;
    margin-top: 25px !important;
  }
  .normal-layout-container .card-header > span.d-flex {
    display: block !important;
  }
}
.normal-layout-container .issue-details .card-body div[role=toolbar] button,
.normal-layout-container .members .card-body div[role=toolbar] button,
.normal-layout-container .hcc-list .card-body div[role=toolbar] button,
.normal-layout-container .member-list .card-body div[role=toolbar] button,
.normal-layout-container .OverallDiscrepency .card-body div[role=toolbar] button,
.normal-layout-container .coding .card-body div[role=toolbar] button,
.normal-layout-container .reports .card-body div[role=toolbar] button,
.normal-layout-container .list-member .card-body div[role=toolbar] button,
.normal-layout-container .hcc .card-body div[role=toolbar] button,
.normal-layout-container .aca-metrics .card-body div[role=toolbar] button {
  padding: 13px 10px;
}
.normal-layout-container .issue-details .card-body div[role=toolbar] button:hover,
.normal-layout-container .members .card-body div[role=toolbar] button:hover,
.normal-layout-container .hcc-list .card-body div[role=toolbar] button:hover,
.normal-layout-container .member-list .card-body div[role=toolbar] button:hover,
.normal-layout-container .OverallDiscrepency .card-body div[role=toolbar] button:hover,
.normal-layout-container .coding .card-body div[role=toolbar] button:hover,
.normal-layout-container .reports .card-body div[role=toolbar] button:hover,
.normal-layout-container .list-member .card-body div[role=toolbar] button:hover,
.normal-layout-container .hcc .card-body div[role=toolbar] button:hover,
.normal-layout-container .aca-metrics .card-body div[role=toolbar] button:hover {
  background-color: #fff !important;
  outline: 0;
}
.normal-layout-container .issue-details .card-body div[role=toolbar] .MuiSvgIcon-root,
.normal-layout-container .members .card-body div[role=toolbar] .MuiSvgIcon-root,
.normal-layout-container .hcc-list .card-body div[role=toolbar] .MuiSvgIcon-root,
.normal-layout-container .member-list .card-body div[role=toolbar] .MuiSvgIcon-root,
.normal-layout-container .OverallDiscrepency .card-body div[role=toolbar] .MuiSvgIcon-root,
.normal-layout-container .coding .card-body div[role=toolbar] .MuiSvgIcon-root,
.normal-layout-container .reports .card-body div[role=toolbar] .MuiSvgIcon-root,
.normal-layout-container .list-member .card-body div[role=toolbar] .MuiSvgIcon-root,
.normal-layout-container .hcc .card-body div[role=toolbar] .MuiSvgIcon-root,
.normal-layout-container .aca-metrics .card-body div[role=toolbar] .MuiSvgIcon-root {
  width: 18px;
}
.normal-layout-container .issue-details .card {
  height: 390px;
  overflow-y: scroll !important;
}
.normal-layout-container .risk-members .card {
  height: 390px;
}
.normal-layout-container .makeStyles-content-3 {
  height: 100vh !important;
  min-height: auto !important;
}
.normal-layout-container .makeStyles-wrapper-1 {
  height: auto !important;
}
.normal-layout-container a:hover {
  text-decoration: none !important;
}
.normal-layout-container .relative {
  position: relative;
}
.normal-layout-container .carousel .control-prev.control-arrow:before {
  border-right: 8px solid #3498ec !important;
}
.normal-layout-container .carousel .control-dots {
  display: none !important;
}
.normal-layout-container .gap-issue .card-body {
  height: 250px !important;
  overflow-y: scroll !important;
}
.normal-layout-container .level-summary .state-level {
  background: none !important;
  box-shadow: none !important;
}
.normal-layout-container .level-summary .state-level > .card-header:first-child {
  background-color: #fff !important;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15) !important;
  box-shadow: var(--devsite-page-box-shadow, 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15)) !important;
}
.normal-layout-container .issuer-table table tr td {
  margin: 10px !important;
  border-right: 1px solid #fff !important;
  border-left: 0px;
  border-bottom: 0px;
}
.normal-layout-container .issuer-table table tr td a {
  font-size: 15px;
  font-weight: bold;
}
.normal-layout-container .issuer-table table tr td:nth-child(5) div {
  padding: 3px 7px;
  /*font-size: 12px;*/
  /*font-weight: 400;*/
  line-height: 1;
  /* color: #fff; */
  /* background-color: #777; */
  border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
.normal-layout-container .issuer-table table,
.normal-layout-container .issuer-table table tfoot {
  border-top: 1px solid #ddd;
}
.normal-layout-container .issuer-table table tr th {
  border-bottom: 0px;
}
.normal-layout-container .header {
  position: fixed;
  right: 0px;
  left: 0px;
  height: 57px;
  z-index: 999;
  top: 0px;
}
.normal-layout-container .header1 {
  position: fixed;
  right: 0px;
  left: 0px;
  height: 57px;
  z-index: 999;
  box-shadow: 0px 0px 7px #313131;
  top: 0px;
  background-color: #526CEC !important;
}
.normal-layout-container .header2 {
  position: absolute;
  right: 0px;
  left: 0px;
  height: 57px;
  box-shadow: 0px 0px 7px #313131;
  top: 0px;
}
.normal-layout-container .header nav {
  display: none;
}
.normal-layout-container .gap-issue .bg-white {
  background-color: #fff !important;
  margin-bottom: 0px;
  padding: 22px;
}
.normal-layout-container .gap-issue {
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15) !important;
  box-shadow: var(--devsite-page-box-shadow, 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15)) !important;
  background-color: #fff !important;
  height: 370px;
}
.normal-layout-container .bg-white {
  background-color: #fff !important;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15) !important;
  box-shadow: var(--devsite-page-box-shadow, 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15)) !important;
}
.normal-layout-container .member-gap .card {
  height: 400px;
  overflow-y: scroll !important;
}
.normal-layout-container .gaps .card {
  height: 368px;
  overflow-y: scroll !important;
}
.normal-layout-container .container,
.normal-layout-container .container-lg,
.normal-layout-container .container-md,
.normal-layout-container .container-sm,
.normal-layout-container .container-xl,
.normal-layout-container .MuiContainer-maxWidthLg {
  max-width: 100% !important;
}
.normal-layout-container .MuiContainer-maxWidthLg {
  padding: 0px !important;
}
.normal-layout-container .chart-review .card {
  height: 370px;
  overflow-y: scroll !important;
}
.normal-layout-container .chart {
  margin-top: 50px !important;
}
.normal-layout-container .bar_chart {
  margin-top: 10px !important;
  padding-bottom: 10px !important;
  margin-bottom: 10px !important;
}
.normal-layout-container .bar_chart_transform text {
  /* transform: translateY(10px); */
}
.normal-layout-container .tab-content-title-div {
  background: white;
  padding: 3px;
  margin: 0px 0px;
}
.normal-layout-container .pg-bottom {
  margin-bottom: 50px;
}
.normal-layout-container .slick-prev:before,
.normal-layout-container .slick-next:before {
  color: #3498ec !important;
}
.normal-layout-container .relative.box {
  display: flex !important;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(255, 255, 255, 0.15) !important;
  box-shadow: var(--devsite-page-box-shadow, 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(255, 255, 255, 0.15)) !important;
  padding: 14px 5px;
  border-radius: 0px;
  cursor: pointer;
}
.normal-layout-container .relative.box .bg1.pl-2.text-left {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.normal-layout-container .slick-slide {
  display: flex;
  padding: 0px 5px;
}
.normal-layout-container .slick-slide svg {
  color: #0d98ec !important;
  width: 44px !important;
  height: 44px !important;
  opacity: 1 !important;
  position: relative !important;
  top: 7px !important;
}
.normal-layout-container .card-body .box:hover {
  background: linear-gradient(-12deg, #2a57d7 0, #9eeeff 100%) !important;
  color: #fff;
}
.normal-layout-container .card-body .box:hover svg {
  color: #fff !important;
}
.normal-layout-container .MUIDataTable-responsiveStacked-83 {
  overflow: hidden !important;
}
.normal-layout-container .MuiTablePagination-root {
  overflow: hidden !important;
}
.normal-layout-container .hcc-heatmap tspan {
  text-overflow: ellipsis;
}
.normal-layout-container .header-notification-badge .MuiBadge-badge {
  height: 20px;
  display: flex;
  padding: 0px 4px;
  z-index: 1;
  position: absolute;
  flex-wrap: wrap;
  font-size: 0.75rem;
  min-width: 15px;
  box-sizing: border-box;
  transition: -webkit-transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 100;
  line-height: 1;
  align-content: center;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
}
.normal-layout-container .header-notification-badge .MuiBadge-colorSecondary {
  color: #fff;
  background-color: #f50057;
  margin-top: 5px;
  margin-right: 2px;
}
.normal-layout-container .notice-dropdown .dropdown-menu {
  min-width: 320px;
  padding: 0;
}
.normal-layout-container .notice-dropdown .dropdown-menuheader {
  padding: 12px 20px;
  font-size: 13px;
  border-bottom: 1px solid #f2f4f9;
}
.normal-layout-container .notice-dropdown .drop-menucontent {
  max-height: 290px;
  position: relative;
  overflow-y: auto;
}
.normal-layout-container .notice-dropdown .drop-noticeRow:not(:last-child) {
  border-bottom: 1px solid #f3f3f3;
}
.normal-layout-container .notice-dropdown .drop-noticeRow {
  display: block;
  align-items: center;
  padding: 9px 16px;
  width: 100%;
}
.normal-layout-container .notice-dropdown .drop-noticeRow h4 {
  color: #18214d;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
  text-align: left;
  overflow: hidden;
  margin-bottom: 2px;
  width: 100%;
}
.normal-layout-container .notice-dropdown .drop-noticeRow .desc {
  color: #18214d;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  overflow: hidden;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 2px;
}
.normal-layout-container .notice-dropdown .dropdown-menu.show {
  opacity: 1;
  -webkit-transform: translateY(10px) !important;
          transform: translateY(10px) !important;
  visibility: visible;
}
.normal-layout-container .notice-dropdown .dropdown-menu {
  max-width: 280px;
  min-width: 200px;
  position: absolute;
  right: 0;
  left: auto;
  border-radius: 4px;
  padding: 12px 4px;
  border: none;
  z-index: 99;
  box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.3);
  transition: 0.3s;
  -webkit-transform: translateY(30px) !important;
          transform: translateY(30px) !important;
  display: block;
  opacity: 0;
  visibility: hidden;
}
.normal-layout-container .header-notification-badge {
  cursor: pointer;
}
.normal-layout-container svg text {
  font-family: "Source Sans Pro", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400 !important;
  line-height: 1.5em !important;
  font-style: normal !important;
  stroke: none !important;
  stroke-width: 0 !important;
}
.normal-layout-container html {
  position: fixed;
  height: 100%;
  overflow: hidden;
}
.normal-layout-container body {
  /* margin: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; */
}
.normal-layout-container #headerTop {
  margin-bottom: 100px;
}
.normal-layout-container .MuiSelect-selectMenu {
  min-height: 1.576em !important;
}
.normal-layout-container .MuiOutlinedInput-inputMarginDense {
  padding-top: 17.5px !important;
}
.normal-layout-container div.card-header h6.text-bold {
  font-weight: bold !important;
}
.normal-layout-container div.card-header h6.text-capital {
  text-transform: uppercase;
}
.normal-layout-container .MuiCard-root.disabled {
  background: rgb(220, 220, 220) !important;
  color: rgba(0, 0, 0, 0.87) !important;
  cursor: auto !important;
  opacity: 0.9 !important;
}
.normal-layout-container .MuiCard-root.disabled:hover {
  background: rgb(220, 220, 220) !important;
  color: rgba(0, 0, 0, 0.87) !important;
  cursor: auto !important;
  opacity: 0.9 !important;
}
.normal-layout-container .MuiCard-root.disabled:hover i,
.normal-layout-container .MuiCard-root.disabled:hover i,
.normal-layout-container .MuiCard-root.disabled i:hover {
  color: #5982E8 !important;
  cursor: auto !important;
  opacity: 0.9 !important;
}
.normal-layout-container .awv-table-action .custom-btn-design {
  cursor: pointer !important;
  color: #fff !important;
  border-radius: 5px !important;
}
.normal-layout-container .awv-form-upload-div {
  background-color: #fff;
  height: 270px;
  border: 3px dashed #9f9d9d;
}
.normal-layout-container .awv-file-upload-icon {
  cursor: pointer !important;
  background-color: #ac10f2;
  color: #fff;
  padding: 4px;
  border-radius: 5px;
}
.normal-layout-container div.custom-action-column thead.MuiTableHead-root th:last-child {
  width: 300px;
}
.normal-layout-container div.awv_layout div.awv-circuler-progress {
  min-width: 100px;
  width: 11.2%;
  margin-left: 2.7% !important;
  flex: 0 0 auto;
}
.normal-layout-container div.awv_layout main.MuiBox-root {
  /* width: 100% !important; */
}
.normal-layout-container div.awv_layout {
  background-color: #fff !important;
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: auto !important;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  /* font-family: "Open Sans !important"; */
}
.normal-layout-container div.awv_layout a,
.normal-layout-container div.awv_layout .MuiTableCell-root,
.normal-layout-container div.awv_model_layout a,
.normal-layout-container div.awv_model_layout .MuiDialog-container {
  font-weight: 400;
  font-size: 13px !important;
}
.normal-layout-container div.awv_layout h6.MuiTypography-root {
  font-weight: 600 !important;
}
.normal-layout-container div.awv_layout button:focus {
  outline: none !important;
}
.normal-layout-container div.awv_model_layout ::-webkit-file-upload-button,
.normal-layout-container div.awv_layout_right_sidebar ::-webkit-file-upload-button {
  color: #fff;
  padding: 1em;
  background: #1d9a9b;
  border: 1px solid #1d9a9b;
}
.normal-layout-container div.awv_model_layout .custom_file_upload input[type=file],
.normal-layout-container div.awv_layout_right_sidebar .custom_file_upload input[type=file] {
  margin: unset;
  padding: unset;
  height: 55px;
  border-radius: 5px;
  background: transparent;
}
.normal-layout-container div.awv_layout :focus-visible {
  outline: none !important;
}
.normal-layout-container div.awv_layout .MuiAvatar-colorDefault {
  background-color: #1a9698 !important;
}
.normal-layout-container div.awv_model_layout .MuiFormLabel-root.Mui-focused {
  color: #1a9698 !important;
}
.normal-layout-container div.awv_model_layout .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #1a9698 !important;
}
.normal-layout-container div.awv_layout div.breadcrumb {
  background-color: #F7F7F7 !important;
}
.normal-layout-container div.awv_layout div.MuiCollapse-wrapperInner {
  width: 93% !important;
}
.normal-layout-container div.awv_layout .mainMenuRootSidebar div {
  font-weight: 500 !important;
  font-size: 15px !important;
}
.normal-layout-container div.awv_layout button.MuiButtonBase-root.MuiIconButton-root svg#expandable-button {
  margin-left: 15px !important;
  color: #fff;
  background: #1D9A9B;
}
.normal-layout-container .awv_layout .sidebar_custom .MuiPaper-root {
  background-color: #393a41 !important;
  margin: 10px 0px 0px 10px;
  border-radius: 15px;
  /* position: absolute; */
}
.normal-layout-container .awv_layout .MuiListItem-button:hover {
  text-decoration: none;
  background-color: transparent !important;
  color: rgb(239, 240, 247) !important;
}
.normal-layout-container .awv_layout .MuiListItem-button {
  text-decoration: none;
  background-color: transparent !important;
  color: rgb(239, 240, 247) !important;
}
.normal-layout-container .awv_layout header.MuiPaper-root {
  background-color: #f7f7f7 !important;
}
.normal-layout-container .awv_layout .logo {
  position: absolute;
  left: 5px;
  top: 0px;
  width: 121px;
  height: 50px;
  padding: 4px;
  margin-top: 7px;
}
.normal-layout-container .awv_layout hr.sidebar-awv-custom {
  background-color: #55555B;
  color: #55555B;
}
.normal-layout-container div.awv_layout a:hover {
  color: #1a9698;
}
.normal-layout-container div.awv_layout a.custom-active {
  color: #1a9698;
}
.normal-layout-container div.awv_layout .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
  color: #202020 !important;
}
.normal-layout-container div.awv_layout div.expand-grid-custom {
  border-left: 5px solid rgb(26, 150, 152) !important;
}
.normal-layout-container div.awv_layout_right_sidebar div.expand-grid-custom {
  border-left: 5px solid rgb(26, 150, 152) !important;
}
.normal-layout-container div.awv_layout_right_sidebar div.awv-recored-right-sidebar-form {
  font-size: 12px !important;
}
.normal-layout-container div.awv_layout .expand-grid-warning-status,
.normal-layout-container div.awv_model_layout .expand-grid-warning-status,
.normal-layout-container div.awv_layout_right_sidebar .expand-grid-warning-status {
  background: #f1c40f;
  border-radius: 25px !important;
  color: #fff;
}
.normal-layout-container div.awv_layout .expand-grid-success-status,
.normal-layout-container div.awv_model_layout .expand-grid-success-status,
.normal-layout-container div.awv_layout_right_sidebar .expand-grid-success-status {
  background: #1a9698;
  border-radius: 25px !important;
  color: #fff;
}
.normal-layout-container div.awv_layout .expand-grid-primary-status,
.normal-layout-container div.awv_model_layout .expand-grid-primary-status,
.normal-layout-container div.awv_layout_right_sidebar .expand-grid-primary-status {
  background: #1a9698;
  border-radius: 25px !important;
  color: #fff;
}
.normal-layout-container div.awv_layout .expand-grid-danger-status,
.normal-layout-container div.awv_model_layout .expand-grid-danger-status,
.normal-layout-container div.awv_layout_right_sidebar .expand-grid-danger-status {
  background: #dc3545;
  border-radius: 25px !important;
  color: #fff;
}
.normal-layout-container div.awv_layout .btn-custom-warning,
.normal-layout-container div.awv_model_layout .btn-custom-warning,
.normal-layout-container div.awv_layout_right_sidebar .btn-custom-warning {
  background: #f1c40f;
  color: #fff;
}
.normal-layout-container div.awv_layout .btn-custom-primary,
.normal-layout-container div.awv_model_layout .btn-custom-primary,
.normal-layout-container div.awv_layout_right_sidebar .btn-custom-primary {
  background: #1a9698;
  color: #fff;
}
.normal-layout-container div.awv_layout .btn-custom-danger,
.normal-layout-container div.awv_model_layout .btn-custom-danger,
.normal-layout-container div.awv_layout_right_sidebar .btn-custom-danger {
  background: #dc3545;
  color: #fff;
}
.normal-layout-container div.awv_layout .btn-custom-success,
.normal-layout-container div.awv_model_layout .btn-custom-success,
.normal-layout-container div.awv_layout_right_sidebar .btn-custom-success {
  background: #28a745;
  color: #fff;
}
.normal-layout-container div.awv_layout .btn-custom-primary:disabled,
.normal-layout-container div.awv_model_layout .btn-custom-primary:disabled,
.normal-layout-container div.awv_layout_right_sidebar .btn-custom-primary:disabled,
.normal-layout-container div.awv_layout .btn-custom-warning:disabled,
.normal-layout-container div.awv_model_layout .btn-custom-warning:disabled,
.normal-layout-container div.awv_layout_right_sidebar .btn-custom-warning:disabled,
.normal-layout-container div.awv_layout .btn-custom-success:disabled,
.normal-layout-container div.awv_model_layout .btn-custom-success:disabled,
.normal-layout-container div.awv_layout_right_sidebar .btn-custom-success:disabled,
.normal-layout-container div.awv_layout .btn-custom-danger:disabled,
.normal-layout-container div.awv_model_layout .btn-custom-danger:disabled,
.normal-layout-container div.awv_layout_right_sidebar .btn-custom-danger:disabled {
  background: #e3e3e3;
  color: #7f7c7c;
}
.normal-layout-container div.awv_layout .txt-custom-warning,
.normal-layout-container div.awv_model_layout .txt-custom-warning,
.normal-layout-container div.awv_layout_right_sidebar .txt-custom-warning {
  color: #f1c40f;
}
.normal-layout-container div.awv_layout .txt-custom-primary,
.normal-layout-container div.awv_model_layout .txt-custom-primary,
.normal-layout-container div.awv_layout_right_sidebar .txt-custom-primary {
  color: #1a9698;
}
.normal-layout-container div.awv_layout .txt-custom-danger,
.normal-layout-container div.awv_model_layout .txt-custom-danger,
.normal-layout-container div.awv_layout_right_sidebar .txt-custom-danger {
  color: #dc3545;
}
.normal-layout-container div.awv_layout .txt-custom-success,
.normal-layout-container div.awv_model_layout .txt-custom-success,
.normal-layout-container div.awv_layout_right_sidebar .txt-custom-success {
  color: #28a745;
}
.normal-layout-container div.awv_layout button.MuiButtonBase-root.MuiTab-root:hover {
  color: rgb(255, 255, 255) !important;
  background: #1a9698 !important;
}
.normal-layout-container div.awv_layout div.MuiTabs-root .Mui-selected {
  color: #1a9698 !important;
}
.normal-layout-container div.awv_layout div.MuiTabs-root .mui-tab-label.Mui-selected {
  background: #fff !important;
  color: #1a9698 !important;
}
.normal-layout-container div.awv_layout div.MuiTabs-root .mui-tab-label.Mui-selected:hover {
  background: #1a9698 !important;
  color: #fff !important;
}
.normal-layout-container div.awv_layout div.MuiTabs-root span.MuiTabs-indicator {
  background: #1a9698 !important;
}
.normal-layout-container div.awv_layout div.circular-content {
  line-height: 1.2;
}
.normal-layout-container div.awv_layout div.custom-column-yearly-chart {
  margin-top: -12%;
  margin-right: 15%;
}
.normal-layout-container div#chartdivStack svg g[opacity="0.4"],
.normal-layout-container div#chartdivStack svg g[opacity="0.3"],
.normal-layout-container div#chartdivStack svg g[opacity="0.2"],
.normal-layout-container div#chartdivStack svg g[opacity="0.1"] {
  display: none;
}
@media only screen and (min-width: 1366px) {
  .normal-layout-container {
    /* For desktop: */
  }
  .normal-layout-container div.awv_layout div.custom-column-yearly-chart {
    margin-top: -7%;
    margin-right: 15%;
  }
}
.normal-layout-container div.awv_layout a.quick-links {
  font-size: 15px !important;
}
.normal-layout-container div.awv_layout ::-webkit-scrollbar {
  width: 10px;
}
.normal-layout-container div.awv_layout ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
.normal-layout-container div.awv_layout ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.normal-layout-container div.awv_layout ::-webkit-scrollbar-thumb:hover {
  background: #e3e3e3;
}
.normal-layout-container div.awv_layout .MuiFormControl-root.selectCustomDesign {
  font-size: 12px !important;
  z-index: 110 !important;
  max-width: 200px;
}
.normal-layout-container .custom-autocomplete ul.options {
  position: absolute;
  list-style: none;
  padding: 0;
  margin-top: 55px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  z-index: 1;
  max-height: 200px;
  overflow: auto;
}
.normal-layout-container .custom-autocomplete ul.options li {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
}
.normal-layout-container .custom-autocomplete ul.options li:hover {
  background-color: #f0f0f0;
}
.normal-layout-container div.awv_layout_right_sidebar div.history-custom-warning {
  border-left: 5px solid #f1c40f !important;
}
.normal-layout-container div.awv_layout_right_sidebar div.history-custom-primary {
  border-left: 5px solid #E9731D !important;
}
.normal-layout-container div.awv_layout_right_sidebar div.history-custom-primary {
  border-left: 5px solid rgb(26, 150, 152) !important;
}
.normal-layout-container div.awv_layout_right_sidebar div.history-custom-danger {
  border-left: 5px solid red !important;
}

/* Add this to your CSS file */
.spinner {
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top: 2px solid #fff;
  border-radius: 50%;
  width: 1em; /* Use em to make the size relative to the font size */
  height: 1em; /* Use em to make the size relative to the font size */
  -webkit-animation: spin 0.6s linear infinite;
          animation: spin 0.6s linear infinite;
  margin-left: 0.5em; /* Add margin to the left to create space */
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pcp_details_table table tr th {
    width: 105px;
    word-wrap: none;
}

.claim_detail_table table tr th {
    position: -webkit-sticky;
    position: sticky;
   width: 105px;
    word-wrap: normal;
    height: 10px;
}.Dynamic_detail_table table tr th {
    width: 200px;
    word-wrap: none;
    position: static;
 }
  .recharts-legend-item ,.legend-item-0
{
    display: inline-block !important;
    margin-right: 10px !important;
    /* border: 1px solid; */
    position: relative !important;
    bottom: 19rem !important;
}
.recharts-legend-item-text{
    color: #666 !important;
    font-size: 12px !important;
}
.slick-slide{
    min-width: 13rem;
}
.slick-track{
    display: flex;
    justify-content: center;
}
.autoFit {
  position:absolute;
  top:20px;
  right:0px;
  bottom:0px;
  left:20px;
}
.multi-range-slider {
    border: none !important;
    border-radius: none !important;
    padding: none !important;
    box-shadow: none !important;
}

.multi-range-slider .ruler {
    margin: -13px 0px -5px 0px !important;
}

.multi-range-slider .bar-inner,
.bar-right,
.bar-left {
    background-color: transparent !important;
    border: transparent !important;
    box-shadow: inset 0px 0px 5px transparent !important;
}

.multi-range-slider .thumb-left::before {
    border-radius: 0px !important;
    width: 12px !important;
    height: 60px !important;
    stroke: rgb(255, 255, 255) !important;
    stroke-width: 1 !important;
    background: rgb(228, 160, 13) !important;
    border: solid 1px rgb(255, 255, 255) !important;
    box-shadow: none !important;
}

.multi-range-slider .thumb-left * {
    font-size: 15px !important;
    text-align: center !important;
    background-color: transparent !important;
    border-radius: 0% !important;
    color: black !important;
    box-shadow: 0px 0px 5px transparent;
}

.multi-range-slider .thumb-right::before {
    border-radius: 0px !important;
    stroke: rgb(255, 255, 255) !important;
    stroke-width: 1 !important;
    background: transparent !important;
    border: solid 1px rgb(255, 255, 255) !important;
    box-shadow: none !important;
    margin-top: 47px;
    width: 0 !important;
    height: 0 !important;
    border-left: 10px solid transparent !important;
    border-right: 10px solid transparent !important;
    border-bottom: 15px solid rgb(17, 39, 89) !important;
}

.multi-range-slider .thumb-right * {
    font-size: 15px !important;
    text-align: center !important;
    background-color: transparent !important;
    border-radius: 0% !important;
    color: black !important;
    box-shadow: 0px 0px 5px transparent;
    top: 61px;
}

.multi-range-slider .ruler .ruler-rule:nth-child(1) {
    color: rgb(229, 240, 243);
    background: rgb(229, 240, 243);
    padding: 25px 0px;
}

.multi-range-slider .ruler .ruler-rule:nth-child(2) {
    color: rgb(207, 225, 231);
    background: rgb(207, 225, 231);
    padding: 25px 0px;
}

.multi-range-slider .ruler .ruler-rule:nth-child(3) {
    color: rgb(184, 210, 219);
    background: rgb(184, 210, 219);
    padding: 25px 0px;
}

.multi-range-slider .ruler .ruler-rule:nth-child(4) {
    color: rgb(163, 195, 207);
    background: rgb(163, 195, 207);
    padding: 25px 0px;
}

.multi-range-slider .ruler .ruler-rule:nth-child(5) {
    color: rgb(141, 180, 196);
    background: rgb(141, 180, 196);
    padding: 25px 0px;
}

.multi-range-slider .ruler .ruler-rule:nth-child(6) {
    color: rgb(119, 165, 185);
    background: rgb(119, 165, 185);
    padding: 25px 0px;
}

.multi-range-slider .ruler .ruler-rule:nth-child(7) {
    color: rgb(97, 151, 174);
    background: rgb(97, 151, 174);
    padding: 25px 0px;
}

.multi-range-slider .ruler .ruler-rule:nth-child(8) {
    color: rgb(74, 136, 164);
    background: rgb(74, 136, 164);
    padding: 25px 0px;
}

.multi-range-slider .ruler .ruler-rule:nth-child(9) {
    color: rgb(47, 122, 154);
    background: rgb(47, 122, 154);
    padding: 25px 0px;
}

.multi-range-slider .ruler .ruler-rule:last-child {
    color: #526CEC;
    background: #526CEC;
    padding: 25px 0px;
}


/* .multi-range-slider-black */
.multi-range-slider-black {
    border: none !important;
    border-radius: none !important;
    padding: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
}

.multi-range-slider-black .ruler {
    margin: -13px 0px -5px 0px !important;
}

.multi-range-slider-black .bar-inner,
.bar-right,
.bar-left {
    background-color: transparent !important;
    border: transparent !important;
    box-shadow: inset 0px 0px 5px transparent !important;
}

.multi-range-slider-black .thumb-left::before {
    border-radius: 0px !important;
    stroke: rgb(255, 255, 255) !important;
    stroke-width: 1 !important;
    background: transparent !important;
    border: solid 1px rgb(255, 255, 255) !important;
    box-shadow: none !important;
    margin-top: -20px;
    width: 0 !important;
    height: 0 !important;
    border-left: 10px solid transparent !important;
    border-right: 10px solid transparent !important;
    border-top: 15px solid rgb(17, 39, 89) !important;
    pointer-events: none !important;
}

.multi-range-slider-black .thumb-left * {
    font-size: 15px !important;
    text-align: center !important;
    background-color: transparent !important;
    border-radius: 0% !important;
    color: black !important;
    box-shadow: 0px 0px 5px transparent;
    pointer-events: none !important;
    font-weight: 700 !important;
}

.multi-range-slider-black .thumb-right::before {
    border-radius: 0px !important;
    stroke: rgb(255, 255, 255) !important;
    stroke-width: 1 !important;
    background: transparent !important;
    border: solid 1px rgb(255, 255, 255) !important;
    box-shadow: none !important;
    margin-top: 48px;
    width: 0 !important;
    height: 0 !important;
    border-left: 10px solid transparent !important;
    border-right: 10px solid transparent !important;
    border-bottom: 15px solid rgb(17, 39, 89) !important;
    pointer-events: none !important;
}

.multi-range-slider-black .thumb-right * {
    font-size: 15px !important;
    text-align: center !important;
    background-color: transparent !important;
    border-radius: 0% !important;
    color: black !important;
    box-shadow: 0px 0px 5px transparent;
    top: 61px;
    pointer-events: none !important;
    font-weight: 700 !important;
}

.range-single-slider .thumb-right::before {
    border-radius: 0px !important;
    stroke: rgb(255, 255, 255) !important;
    stroke-width: 1 !important;
    background: transparent !important;
    border: solid 1px rgb(255, 255, 255) !important;
    box-shadow: none !important;
    margin-top: 48px;
    width: 0 !important;
    height: 0 !important;
    border-left: 10px solid transparent !important;
    border-right: 10px solid transparent !important;
    border-bottom: 15px solid rgb(17, 39, 89) !important;
    pointer-events: none !important;
    display: none !important;
}

.range-single-slider .thumb-right * {
    font-size: 15px !important;
    text-align: center !important;
    background-color: transparent !important;
    border-radius: 0% !important;
    color: black !important;
    box-shadow: 0px 0px 5px transparent;
    top: 61px;
    pointer-events: none !important;
    display: none !important;
}

.multi-range-slider-black .ruler .ruler-rule:nth-child(1) {
    color: #E2F0F4;
    background: #E2F0F4;
    padding: 25px 0px;
}

.multi-range-slider-black .ruler .ruler-rule:nth-child(2) {
    color: #CAE1E7;
    background: #CAE1E7;
    padding: 25px 0px;
}

.multi-range-slider-black .ruler .ruler-rule:nth-child(3) {
    color: #B3D1DC;
    background: #B3D1DC;
    padding: 25px 0px;
}

.multi-range-slider-black .ruler .ruler-rule:nth-child(4) {
    color: #97C4D2;
    background: #97C4D2;
    padding: 25px 0px;
}

.multi-range-slider-black .ruler .ruler-rule:nth-child(5) {
    color: #7EB5C6;
    background: #7EB5C6;
    padding: 25px 0px;
}

.multi-range-slider-black .ruler .ruler-rule:nth-child(6) {
    color: #63A7BC;
    background: #63A7BC;
    padding: 25px 0px;
}

.multi-range-slider-black .ruler .ruler-rule:nth-child(7) {
    color: #429AAD;
    background: #429AAD;
    padding: 25px 0px;
}

.multi-range-slider-black .ruler .ruler-rule:nth-child(8) {
    color: #278AA4;
    background: #278AA4;
    padding: 25px 0px;
}

.multi-range-slider-black .ruler .ruler-rule:nth-child(9) {
    color: #007B9B;
    background: #007B9B;
    padding: 25px 0px;
}

.multi-range-slider-black .ruler .ruler-rule:last-child {
    color: #006F91;
    background: #006F91;
    padding: 25px 0px;
}

.multi-range-slider-black .label-min {
    color: black;
    font-weight: 600 !important;
}

.multi-range-slider-black .label-max {
    color: black;
    font-weight: 600 !important;
}

.multi-range-slider-black .ruler {
    border: 1px solid #EBEBEB;
}


/* multi-range-slider-swap */

.multi-range-slider-swap .thumb-left::before {
    border-radius: 0px !important;
    stroke: rgb(255, 255, 255) !important;
    stroke-width: 1 !important;
    background: transparent !important;
    border: solid 1px rgb(255, 255, 255) !important;
    box-shadow: none !important;
    margin-top: 48px;
    width: 0 !important;
    height: 0 !important;
    border-left: 10px solid transparent !important;
    border-right: 10px solid transparent !important;
    border-bottom: 15px solid rgb(17, 39, 89) !important;
    pointer-events: none !important;
}

.multi-range-slider-swap .thumb-left * {
    font-size: 15px !important;
    text-align: center !important;
    background-color: transparent !important;
    border-radius: 0% !important;
    color: black !important;
    box-shadow: 0px 0px 5px transparent;
    top: 61px;
    pointer-events: none !important;
    font-weight: 700 !important;
}

.multi-range-slider-swap .thumb-right::before {
    border-radius: 0px !important;
    stroke: rgb(255, 255, 255) !important;
    stroke-width: 1 !important;
    background: transparent !important;
    border: solid 1px rgb(255, 255, 255) !important;
    box-shadow: none !important;
    margin-top: -20px;
    width: 0 !important;
    height: 0 !important;
    border-left: 10px solid transparent !important;
    border-right: 10px solid transparent !important;
    border-top: 15px solid rgb(17, 39, 89) !important;
    pointer-events: none !important;
}

.multi-range-slider-swap .thumb-right * {
    font-size: 15px !important;
    text-align: center !important;
    background-color: transparent !important;
    border-radius: 0% !important;
    color: black !important;
    box-shadow: 0px 0px 5px transparent;
    pointer-events: none !important;
    font-weight: 700 !important;
    top: -45px !important;
}
@charset "utf-8";

.awe-layout-container .sidebar-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9;
  max-width: 260px;
  background-image: linear-gradient(180deg, #414149 0%, #242428 100%);
  height: 100vh;
  max-width: 260px;
  width: 100%;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.09);
}

.awe-layout-container .sidebar-header {
  padding: 20px;
  color: var(--white);
  border-bottom: 1px solid var(--light300);
}

.awe-layout-container .sidebar-wrapper > ul {
  max-height: calc(100vh - 87px);
  overflow-y: auto;
  padding: 10px;
  grid-gap: 10px;
  gap: 10px;
}

.awe-layout-container .sidebar-wrapper ul li a {
  padding: 7px 14px;
  font-weight: 400;
  color: var(--white);
  font-size: var(--fs14);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.awe-layout-container .sidebar-wrapper > ul > li > a{
  padding-left: 0px;
  padding-right: 0px;
}
.awe-layout-container .sidebar-wrapper ul li a svg{
  transition:  all 0.5s ease;
  -webkit-transition:  all 0.5s ease;
  -moz-transition:  all 0.5s ease;
  -ms-transition:  all 0.5s ease;
  -o-transition:  all 0.5s ease;
}
.awe-layout-container .sidebar-wrapper ul li a:not(.collapsed) .arrow_icon svg{
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}


.awe-layout-container .sidebar-wrapper ul ul li a:hover,
.awe-layout-container .sidebar-header ul a:hover,
.awe-layout-container .sidebar-wrapper ul ul li a.active {
  color: var(--white);
}

.awe-layout-container .sidebar-wrapper ul ul li a.active,
.awe-layout-container .sidebar-wrapper ul ul li a:hover {
  background-color: var(--primary400);
}

.awe-layout-container .sidebar-wrapper.is_mini {
  max-width: 70px;
}

.awe-layout-container .sidebar-wrapper.is_mini ul li a {
  padding: 13px;
}
.awe-layout-container .sidebar-wrapper ul li .submenu {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}
.awe-layout-container .sidebar-wrapper ul li .submenu.collapse:not(.show){
  display: none;
}
.awe-layout-container .sidebar-wrapper .sidebar-header a .icon,
.awe-layout-container .sidebar-wrapper,
.awe-layout-container .sidebar-wrapper ul li a,
.awe-layout-container .sidebar-wrapper ul li a .text {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

.awe-layout-container .sidebar-wrapper.is_mini .sidebar-header a .icon {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.awe-layout-container .sidebar-wrapper.is_mini ul li a .text,
.awe-layout-container .sidebar-wrapper.is_mini .sidebar-header a .text {
  display: none;
  opacity: 0px;
  /* @media screen and (max-width:1399px) {
     .sidebar-wrapper ul li a{
     padding: 20px 24px;
     }
     .sidebar-wrapper.is_mini ul li a {
     padding: 20px 22px;
     }
     } */
}

@media screen and (max-width: 1199px) {
  .awe-layout-container .sidebar-wrapper {
    top: 0px;
    height: 100%;
    left: -320px;
    z-index: 99;
  }

  body.sidebar_mini .sidebar-wrapper {
    display: block;
    left: 0px;
  }
}


.awe-layout-container .diagnosis_code_sidebar {
  position: fixed;
  bottom: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: #212529;
  visibility: hidden;
  background-color: #ffffff;
  background-clip: padding-box;
  top: 0;
  right: 0;
  width: 350px;
  border-left: 1px solid rgba(0, 0, 0, 0.175);
  outline: 0;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  -webkit-transition: transform 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out;
  -ms-transition: transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.awe-layout-container .diagnosis_code_sidebar.show{
  visibility: visible;
  transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
}
.awe-layout-container .diagnosis_code_sidebar + .overlay {
  z-index: 99;
}
@charset "utf-8";

:root {
    --primaryMain: #093535;
    --primary900: #0c4444;
    --primary800: #105a5b;
    --primary700: #147172;
    --primary600: #15787a;
    --primary500: #178789;
    --primary400: #1a9698;
    --primary300: #b8dedf;
    --primary200: #ddeff0;
    --primary100: #e8f5f5;
    --primary50: #F2FAFF;

    --darkMain: #000000;
    --dark1000: #391C1C;
    --dark900: #616161;
    --dark800: #313131;
    --dark700: #3A3C40;
    --dark650: #3A1D1D;
    --dark600: rgb(41, 41, 62, 0.6);
    --dark500: rgb(41, 41, 62, 0.5);
    --dark400: rgb(41, 41, 62, 0.4);
    --dark300: rgb(41, 41, 62, 0.3);
    --dark200: rgb(41, 41, 62, 0.2);
    --dark100: rgb(41, 41, 62, 0.1);

    --lightDark: #3A3939;
    --lightDark900: rgb(58, 57, 57, 0.9);
    --lightDark800: rgb(58, 57, 57, 0.8);
    --lightDark700: rgb(58, 57, 57, 0.7);
    --lightDark600: rgb(58, 57, 57, 0.6);
    --lightDark500: rgb(58, 57, 57, 0.5);
    --lightDark400: rgb(58, 57, 57, 0.4);
    --lightDark300: rgb(58, 57, 57, 0.3);
    --lightDark200: rgb(58, 57, 57, 0.2);
    --lightDark100: rgb(58, 57, 57, 0.1);

    --greyMain: #171C26;
    --grey900: #464F60;
    --grey800: #687182;
    --grey700: #868FA0;
    --grey600: #A1A9B8;
    --grey500: #6B6B6B;
    --grey400: #82868C;
    --grey300: #6F727A;
    --grey200: #F7F9FC;
    --grey150: #979797;
    --grey100: #585555;

    --tosca: #27A9B9;
    --tosca900: rgb(39, 169, 185, 0.9);
    --tosca800: rgb(39, 169, 185, 0.8);
    --tosca700: rgb(39, 169, 185, 0.7);
    --tosca600: rgb(39, 169, 185, 0.6);
    --tosca500: rgb(39, 169, 185, 0.5);
    --tosca400: rgb(39, 169, 185, 0.4);
    --tosca300: rgb(39, 169, 185, 0.3);
    --tosca200: rgb(39, 169, 185, 0.2);
    --tosca100: rgb(39, 169, 185, 0.1);

    --lightMain: #DADADA;
    --light900: #D9D9D9;
    --light800: #D4D4D4;
    --light700: #DEDEDE;
    --light650: #F5F5F5;
    --light600: #F4F4F4;
    --light500: #E9EDF5;
    --light400: #F7F8FB;
    --light300: #55555B;
    --light200: rgb(242, 242, 245, 0.2);
    --light100: rgb(242, 242, 245, 0.1);

    --border: #D4D4D4;
    --border900: rgb(214, 214, 214, 0.9);
    --border800: rgb(214, 214, 214, 0.8);
    --border700: rgb(214, 214, 214, 0.7);
    --border600: rgb(214, 214, 214, 0.6);
    --border500: rgb(214, 214, 214, 0.5);
    --border400: rgb(214, 214, 214, 0.4);
    --border300: rgb(214, 214, 214, 0.3);
    --border200: rgb(214, 214, 214, 0.2);
    --border100: rgb(214, 214, 214, 0.1);

    --blueMain: #1C274C;
    --blue900: rgb(28, 39, 76, 0.9);
    --blue800: rgb(28, 39, 76, 0.8);
    --blue700: rgb(28, 39, 76, 0.7);
    --blue600: rgb(28, 39, 76, 0.6);
    --blue500: rgb(28, 39, 76, 0.5);
    --blue400: rgb(28, 39, 76, 0.4);
    --blue300: rgb(28, 39, 76, 0.3);
    --blue200: rgb(28, 39, 76, 0.2);
    --blue100: rgb(28, 39, 76, 0.1);

    --dangerMain: #FF0000;
    --danger900: #FF5367;
    --danger800: rgb(255, 83, 103, 0.8);
    --danger700: rgb(255, 83, 103, 0.7);
    --danger600: rgb(255, 83, 103, 0.6);
    --danger500: rgb(255, 83, 103, 0.5);
    --danger400: rgb(255, 83, 103, 0.4);
    --danger300: rgb(255, 83, 103, 0.3);
    --danger200: rgb(255, 83, 103, 0.2);
    --danger100: rgb(255, 83, 103, 0.1);

    --redMain: #FF0000;
    --red400: #E23F68;
    --red300: #E75E80;
    --red200: #F76F7F;
    --red100: #FCC5CB;

    --warningMain: #E9731D;
    --warning900: #FFC701;
    --warning800: #F1C40F;
    --warning700: #F2C94C;
    --warning600: rgb(230, 164, 37, 0.6);
    --warning500: rgb(230, 164, 37, 0.5);
    --warning400: rgb(230, 164, 37, 0.4);
    --warning300: rgb(230, 164, 37, 0.3);
    --warning200: rgb(230, 164, 37, 0.2);
    --warning100: rgb(230, 164, 37, 0.1);

    --white: #FFFFFF;
    --white900: rgb(255, 255, 255, 0.9);
    --white800: rgb(255, 255, 255, 0.8);
    --white700: rgb(255, 255, 255, 0.7);
    --white600: rgb(255, 255, 255, 0.6);
    --white500: rgb(255, 255, 255, 0.5);
    --white400: rgb(255, 255, 255, 0.4);
    --white300: rgb(255, 255, 255, 0.3);
    --white200: rgb(255, 255, 255, 0.2);
    --white100: rgb(255, 255, 255, 0.1);

    --fonts-OpenSans: 'Open Sans', sans-serif;;

    --h1: 2.25rem;
    --h2: 2rem;
    --h3: 1.75rem;
    --h4: 1.5rem;
    --h5: 1.25rem;
    --h6: 0.9375rem;
    --p: 0.9375rem;

    --fs36: 2.25rem;
    --fs34: 2.125rem;
    --fs32: 2rem;
    --fs30: 1.875rem;
    --fs28: 1.75rem;
    --fs26: 1.625rem;
    --fs24: 1.5rem;
    --fs22: 1.375rem;
    --fs20: 1.25rem;
    --fs18: 1.125rem;
    --fs16: 1rem;
    --fs15: 0.9375rem;
    --fs14: 0.875rem;
    --fs13: 13px;
    --fs12: 0.75rem;
    --fs10: 0.625rem;
}

/* @media screen and (max-width:1399px) {
    :root {
        --h1: 2rem;
        --h2: 1.75rem;
        --h3: 1.5rem;
        --h4: 1.5rem;
        --h5: 1.125rem;
        --h6: 0.875rem;
        --p: 0.875rem;
    }
} */

@media screen and (max-width: 575px) {
    :root {
        --h1: 1.75rem;
        --h2: 1.5rem;
        --h3: 1.25rem;
        --h4: 1.125rem;
        --h5: 1rem;
    }
}
@charset "utf-8";

.awe-layout-container * {
    outline: none;
    margin: 0;
    box-sizing: border-box;
}

body.awe-layout-container {
    font-family: var(--fonts-OpenSans);
    font-size: var(--fs15);
    font-weight: 400;
    line-height: normal;
    color: var(--greyMain);
    background-color: #F4F4F4;
    overflow-x: hidden;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-size: 16px;
    width: 100%;
    height: 100%;
}

.awe-layout-container a {
    color: inherit;
    display: inline-block;
    transition: all 0.4s ease;
}

.awe-layout-container a:hover,
.awe-layout-container button:hover {
    color: var(--primaryMain);
}

.awe-layout-container a,
.awe-layout-container a:hover,
.awe-layout-container a:focus {
    text-decoration: none;
    transition: 0.4s all;
    outline: none;
}

.awe-layout-container button,
.awe-layout-container input,
.awe-layout-container optgroup,
.awe-layout-container select,
.awe-layout-container textarea {
    color: var(--darkMain);
    font-weight: normal;
    border: 0px;
    background-color: transparent;
    outline: none;
    padding: 0px;
}

.awe-layout-container input,
.awe-layout-container optgroup,
.awe-layout-container select,
.awe-layout-container textarea {
    width: 100%;
}

.awe-layout-container a[type=button],
.awe-layout-container a[type=reset],
.awe-layout-container a[type=submit],
.awe-layout-container button,
.awe-layout-container html [type=button],
.awe-layout-container [type=reset],
.awe-layout-container [type=submit] {
    -webkit-appearance: none;
    appearance: none;
}

.awe-layout-container input::-webkit-outer-spin-button,
.awe-layout-container input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    /* Firefox */
}

.awe-layout-container input[type=number] {
    -moz-appearance: textfield;
}

.awe-layout-container button,
.awe-layout-container [type=button] {
    background-color: transparent;
    border: none;
    box-shadow: none;
    outline: none;
}

.awe-layout-container button:focus,
.awe-layout-container input:focus,
.awe-layout-container select:focus,
.awe-layout-container textarea:focus,
.awe-layout-container .accordion-button:focus {
    outline: none;
    box-shadow: none;
}

.awe-layout-container .form-control {
    border: 1px solid var(--light700);
    /*background: var(--white);
     */
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    padding: 8px 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.awe-layout-container .form-check-input:focus,
.awe-layout-container .form-control:focus {
    box-shadow: none;
    outline: none;
}

.awe-layout-container .form-check-input {
    border: 1px solid var(--border);
    margin: 2px 0px 0px -1.5rem;
    cursor: pointer;
}

.awe-layout-container .form-check-input:checked {
    background-color: var(--primaryMain);
    border-color: var(--primaryMain);
}

.awe-layout-container .form-check-label {
    cursor: pointer;
}

.awe-layout-container [type="file"] {
    -webkit-appearance: none;
            appearance: none;
}

.awe-layout-container [type="file"]::-webkit-file-upload-button {
    display: none;
}

.awe-layout-container [type="file"]::file-selector-button {
    display: none;
}

.awe-layout-container ul,
.awe-layout-container menu,
.awe-layout-container dir {
    margin: 0;
    padding: 0;
    /* display: block; */
    list-style-type: none;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
    -webkit-padding-start: 0;
}

.awe-layout-container section {
    position: relative;
}

.awe-layout-container ::-webkit-input-placeholder {
    color: var(--greyMain);
}

.awe-layout-container :-moz-placeholder {
    color: var(--greyMain);
}

.awe-layout-container ::-moz-placeholder {
    color: var(--greyMain);
}

.awe-layout-container :-ms-input-placeholder {
    color: var(--greyMain);
}

.awe-layout-container a,
.awe-layout-container button {
    transition: 0.4s all;
    outline: none;
}

.awe-layout-container .o_hidden {
    overflow: hidden !important;
}

.awe-layout-container hr {
    border-top: 1px solid #E0E0E0;
}

.awe-layout-container p {
    color: var(--greyMain);
    font-size: var(--p);
    margin-bottom: 16px;
}

.awe-layout-container p:last-child {
    margin-bottom: 0;
}

.awe-layout-container img {
    max-width: 100%;
    /* Scrollbar */
}

.awe-layout-container .custom-scrollbar {
    overflow-y: auto;
}

.awe-layout-container .custom-scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 6px;
    background-color: var(--light700);
    border-radius: 8px;
}

.awe-layout-container .custom-scrollbar::-webkit-scrollbar-track {
    background-color: var(--light700);
    border-radius: 8px;
}

.awe-layout-container .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--primary400);
    border-radius: 8px;
    /* Hide scrollbar for Chrome, Safari and Opera */
}

.awe-layout-container .scrollbar_hide::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for IE, Edge and Firefox */
}

.awe-layout-container .scrollbar_hide {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    /*Preloader*/
}

.awe-layout-container .preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--darkMain);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.awe-layout-container .loader-part h5 {
    font-size: 14px;
    color: #fff;
    margin-bottom: 0;
    margin-top: 20px;
    font-weight: 400;
}

.awe-layout-container .loader-spinner {
    width: 75px;
    height: 75px;
    margin: 0;
    background: transparent;
    border-top: 4px solid #fff;
    border-right: 4px solid transparent;
    border-radius: 50%;
    -webkit-animation: 1s spin linear infinite;
    animation: 1s spin linear infinite;
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {

    /*-------default-class---------*/
    /* font size */
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.awe-layout-container .h1,
.awe-layout-container .h2,
.awe-layout-container .h3,
.awe-layout-container .h4,
.awe-layout-container .h5,
.awe-layout-container .h6,
.awe-layout-container h1,
.awe-layout-container h2,
.awe-layout-container h3,
.awe-layout-container h4,
.awe-layout-container h5,
.awe-layout-container h6 {
    color: var(--darkMain);
    font-weight: 700;
    margin: 0;
    line-height: 1.5;
}

.awe-layout-container h1,
.awe-layout-container .h1 {
    font-size: var(--h1);
}

.awe-layout-container h2,
.awe-layout-container .h2 {
    font-size: var(--h2);
}

.awe-layout-container h3,
.awe-layout-container .h3 {
    font-size: var(--h3);
}

.awe-layout-container h4,
.awe-layout-container .h4 {
    font-size: var(--h4);
}

.awe-layout-container h5,
.awe-layout-container .h5 {
    font-size: var(--h5);
}

.awe-layout-container h6,
.awe-layout-container .h6 {
    font-size: var(--h6);
}

.awe-layout-container .fs12 {
    font-size: var(--fs12) !important;
}

.awe-layout-container .fs13 {
    font-size: var(--fs13) !important;
}

.awe-layout-container .fs14 {
    font-size: var(--fs14) !important;
}

.awe-layout-container .fs15 {
    font-size: var(--fs15) !important;
}

.awe-layout-container .fs16 {
    font-size: var(--fs16) !important;
}

.awe-layout-container .fs18 {
    font-size: var(--fs18) !important;
}

.awe-layout-container .fs20 {
    font-size: var(--fs20) !important;
}

.awe-layout-container .fs22 {
    font-size: var(--fs22) !important;
}

.awe-layout-container .fs24 {
    font-size: var(--fs24) !important;
}

.awe-layout-container .fs26 {
    font-size: var(--fs26) !important;
}

.awe-layout-container .fs28 {
    font-size: var(--fs28) !important;
}

.awe-layout-container .fs30 {
    font-size: var(--fs30) !important;
}

.awe-layout-container .fs32 {
    font-size: var(--fs32) !important;
}

.awe-layout-container .fs34 {
    font-size: var(--fs34) !important;
}

.awe-layout-container .fs36 {
    font-size: var(--fs36) !important;
    /* line height */
}

.awe-layout-container .lh_1 {
    line-height: 1;
}

.awe-layout-container .lh_1_1 {
    line-height: 1.1;
}

.awe-layout-container .lh_1_2 {
    line-height: 1.2;
}

.awe-layout-container .lh_1_3 {
    line-height: 1.3;
}

.awe-layout-container .lh_1_4 {
    line-height: 1.4;
}

.awe-layout-container .lh_1_5 {
    line-height: 1.5;
}

.awe-layout-container .lh_1_6 {
    line-height: 1.6;
}

.awe-layout-container .lh_1_7 {
    line-height: 1.7;
}

.awe-layout-container .lh_1_8 {
    line-height: 1.8;
}

.awe-layout-container .lh_2 {
    line-height: 2;
    /* font weight */
}

.awe-layout-container .fw400 {
    font-weight: 400 !important;
}

.awe-layout-container .fw500 {
    font-weight: 500 !important;
}

.awe-layout-container .fw600 {
    font-weight: 600 !important;
}

.awe-layout-container .fw700 {
    font-weight: 700 !important;
}

.awe-layout-container .fw800 {
    font-weight: 800 !important;
}

.awe-layout-container select {
    -webkit-appearance: none;
    appearance: none;
    background: url(/static/media/arrow_down.af715ee6.svg) calc(100% - 10px)/11px no-repeat;
}

.awe-layout-container .link_text {
    font-weight: 500;
    font-size: 18px;
    color: var(--primaryMain);
}

.awe-layout-container .link_text:hover {
    color: var(--primary400);
}

.awe-layout-container .link_white {
    color: var(--white);
}

.awe-layout-container .link_white:hover {
    color: var(--primary400);
}

.awe-layout-container .link_primary {
    color: var(--primary400);
}

.awe-layout-container .link_primary:hover {
    color: var(--primary500);
}

.awe-layout-container .sr-only {
    position: absolute;
    width: 0px;
    height: 0px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    /*-----Spaching-------*/
}

.awe-layout-container .ptb96 {
    padding-top: 6rem;
    padding-bottom: 6rem;
}

.awe-layout-container .ptb80 {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.awe-layout-container .ptb64 {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.awe-layout-container .ptb48 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.awe-layout-container .ptb40 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.awe-layout-container .ptb32 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.awe-layout-container .ptb24 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.awe-layout-container .ptb16 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.awe-layout-container .pt96 {
    padding-top: 6rem;
}

.awe-layout-container .pt80 {
    padding-top: 5rem;
}

.awe-layout-container .pt64 {
    padding-top: 4rem;
}

.awe-layout-container .pt48 {
    padding-top: 3rem;
}

.awe-layout-container .pt32 {
    padding-top: 2rem;
}

.awe-layout-container .pt24 {
    padding-top: 1.5rem;
}

.awe-layout-container .pt16 {
    padding-top: 1rem;
}

.awe-layout-container .pb96 {
    padding-bottom: 6rem;
}

.awe-layout-container .pb80 {
    padding-bottom: 5rem;
}

.awe-layout-container .pb64 {
    padding-bottom: 4rem;
}

.awe-layout-container .pb48 {
    padding-bottom: 3rem;
}

.awe-layout-container .pb32 {
    padding-bottom: 2rem;
}

.awe-layout-container .pb24 {
    padding-bottom: 1.5rem;
}

.awe-layout-container .pb16 {
    padding-bottom: 1rem;
}

.awe-layout-container .mt96 {
    margin-top: 6rem;
}

.awe-layout-container .mt80 {
    margin-top: 5rem;
}

.awe-layout-container .mt64 {
    margin-top: 4rem;
}

.awe-layout-container .mt48 {
    margin-top: 3rem;
}

.awe-layout-container .mt32 {
    margin-top: 2rem;
}

.awe-layout-container .mt24 {
    margin-top: 1.5rem;
}

.awe-layout-container .mt20 {
    margin-top: 1.25rem;
}

.awe-layout-container .mt16 {
    margin-top: 1rem;
}

.awe-layout-container .mt12 {
    margin-top: 0.75rem;
}

.awe-layout-container .mt10 {
    margin-top: 0.625rem;
}

.awe-layout-container .mb96 {
    margin-bottom: 6rem;
}

.awe-layout-container .mb80 {
    margin-bottom: 5rem;
}

.awe-layout-container .mb64 {
    margin-bottom: 4rem;
}

.awe-layout-container .mb48 {
    margin-bottom: 3rem;
}

.awe-layout-container .mb32 {
    margin-bottom: 2rem;
}

.awe-layout-container .mb24 {
    margin-bottom: 1.5rem;
}

.awe-layout-container .mb20 {
    margin-bottom: 1.25rem;
}

.awe-layout-container .mb16 {
    margin-bottom: 1rem;
}

.awe-layout-container .mb12 {
    margin-bottom: 0.75rem;
}

.awe-layout-container .mb10 {
    margin-bottom: 0.625rem;
    /* flex class */
}

.awe-layout-container .flex_item_cc,
.awe-layout-container .flex_item_cb,
.awe-layout-container .flex_item_ca,
.awe-layout-container .flex_item_cs,
.awe-layout-container .flex_item_ce,
.awe-layout-container .flex_item_ss,
.awe-layout-container .flex_item_sb,
.awe-layout-container .flex_item_sc,
.awe-layout-container .flex_item_se {
    display: flex;
    grid-gap: 16px;
    gap: 16px;
}

.awe-layout-container .flex_item_cc {
    align-items: center;
    justify-content: center;
}

.awe-layout-container .flex_item_cb {
    align-items: center;
    justify-content: space-between;
}

.awe-layout-container .flex_item_ca {
    align-items: center;
    justify-content: space-around;
}

.awe-layout-container .flex_item_cs {
    align-items: center;
    justify-content: flex-start;
}

.awe-layout-container .flex_item_ce {
    align-items: center;
    justify-content: flex-end;
}

.awe-layout-container .flex_item_ss {
    align-items: flex-start;
    justify-content: flex-start;
}

.awe-layout-container .flex_item_sb {
    align-items: flex-start;
    justify-content: space-between;
}

.awe-layout-container .flex_item_sc {
    align-items: flex-start;
    justify-content: center;
}

.awe-layout-container .flex_item_se {
    align-items: flex-start;
    justify-content: flex-end;
}

.awe-layout-container .user_img {
    width: 52px;
    height: 52px;
    overflow: hidden;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

.awe-layout-container .user_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.awe-layout-container .user_img.w42,
.awe-layout-container .w42 {
    width: 42px;
    height: 42px;
}

.awe-layout-container .user_img.w32,
.awe-layout-container .w32 {
    width: 32px;
    height: 32px;
}

.awe-layout-container .user_img.w24,
.awe-layout-container .w24 {
    width: 24px;
    height: 24px;
}

.awe-layout-container .user_img.w20,
.awe-layout-container .w20 {
    width: 20px;
    height: 20px;
}

.awe-layout-container .user_img.w16,
.awe-layout-container .w16 {
    width: 16px;
    height: 16px;
    /* width */
}

.awe-layout-container .user_img.w12,
.awe-layout-container .w12 {
    width: 12px;
    height: 12px;
    /* width */
}

.awe-layout-container .w40 {
    width: 40px;
}

.awe-layout-container .mw40 {
    max-width: 40px;
}

.awe-layout-container .minw40 {
    min-width: 40px;
}

.awe-layout-container .w50 {
    width: 50px;
}

.awe-layout-container .w50 {
    width: 50px;
}

.awe-layout-container .mw50 {
    max-width: 50px;
}

.awe-layout-container .minw50 {
    min-width: 50px;
}

.awe-layout-container .w50 {
    width: 50px;
}

.awe-layout-container .mw60 {
    max-width: 60px;
}

.awe-layout-container .minw60 {
    min-width: 60px;
}

.awe-layout-container .w80 {
    width: 80px;
}

.awe-layout-container .mw80 {
    max-width: 80px;
}

.awe-layout-container .minw80 {
    min-width: 80px;
}

.awe-layout-container .minw85 {
    min-width: 85px;
}

.awe-layout-container .w100 {
    width: 100px;
}

.awe-layout-container .mw100 {
    max-width: 100px;
}

.awe-layout-container .minw100 {
    min-width: 100px;
}

.awe-layout-container .w120 {
    width: 100px;
}

.awe-layout-container .mw120 {
    max-width: 100px;
}

.awe-layout-container .minw120 {
    min-width: 100px;
}

.awe-layout-container .w150 {
    width: 150px;
}

.awe-layout-container .mw150 {
    max-width: 150px;
}

.awe-layout-container .minw150 {
    min-width: 150px;
}

.awe-layout-container .w160 {
    width: 160px;
}

.awe-layout-container .mw160 {
    max-width: 160px;
}

.awe-layout-container .minw160 {
    min-width: 160px;
}

.awe-layout-container .w170 {
    width: 170px;
}

.awe-layout-container .mw170 {
    max-width: 170px;
}

.awe-layout-container .minw170 {
    min-width: 170px;
}

.awe-layout-container .w180 {
    width: 180px;
}

.awe-layout-container .mw180 {
    max-width: 180px;
}

.awe-layout-container .minw180 {
    min-width: 180px;
}

.awe-layout-container .w200 {
    width: 200px;
}

.awe-layout-container .mw200 {
    max-width: 200px;
}

.awe-layout-container .minw200 {
    min-width: 200px;
}

.awe-layout-container .w250 {
    width: 250px;
}

.awe-layout-container .mw250 {
    max-width: 250px;
}

.awe-layout-container .minw250 {
    min-width: 250px;
}

.awe-layout-container .w300 {
    width: 300px;
}

.awe-layout-container .mw300 {
    max-width: 300px;
}

.awe-layout-container .minw300 {
    min-width: 300px;
}

@media screen and (max-width: 575px) {

    .awe-layout-container .btn-theme.minw250,
    .awe-layout-container .btn-theme.minw200 {
        min-width: 100px;
    }
}

.awe-layout-container .gap4 {
    grid-gap: 4px !important;
    gap: 4px !important;
}

.awe-layout-container .gap6 {
    grid-gap: 6px !important;
    gap: 6px !important;
}

.awe-layout-container .gap8 {
    grid-gap: 8px !important;
    gap: 8px !important;
}

.awe-layout-container .gap10 {
    grid-gap: 10px !important;
    gap: 10px !important;
}

.awe-layout-container .gap12 {
    grid-gap: 12px !important;
    gap: 12px !important;
}

.awe-layout-container .gap14 {
    grid-gap: 14px !important;
    gap: 14px !important;
    /* dropdowns */
}

.awe-layout-container .dropdown-menu {
    -webkit-animation: 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 animdropdown;
    animation: 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 animdropdown;
}

@-webkit-keyframes animdropdown {
    from {
        -webkit-transform: translate3d(0, 6px, 0);
        transform: translate3d(0, 6px, 0);
        opacity: 0;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes animdropdown {
    from {
        -webkit-transform: translate3d(0, 6px, 0);
        transform: translate3d(0, 6px, 0);
        opacity: 0;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.awe-layout-container .btn-theme {
    border: 1px solid var(--primary400);
    background-color: var(--primary400);
    text-transform: uppercase;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
    padding: 7.5px 12px;
    font-size: 13px;
    font-weight: 400;
    color: var(--white);
    letter-spacing: 0.455px;
    line-height: 18px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.awe-layout-container .btn-theme.btn-theme-outline {
    border-color: var(--primary400);
    color: var(--primary400);
    background-color: transparent;
}

.awe-layout-container .btn-theme.btn-theme-outline:hover {
    border: 1px solid var(--primary400);
    background-color: var(--primary400);
    color: var(--white);
}

.awe-layout-container .btn-icon {
    padding: 8.5px;
    line-height: 1;
    border: 1px solid var(--primary400);
    background-color: var(--primary400);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
    color: var(--white);
    line-height: 1;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.awe-layout-container .btn-theme:hover,
.awe-layout-container .btn-icon:hover {
    background-color: var(--primary500);
    border-color: var(--primary500);
    color: var(--white);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
}

.awe-layout-container .btn-theme:focus {
    background-color: var(--primary600);
    border-color: var(--primary600);
    color: var(--white);
    box-shadow: 0px 0px 0px 2px rgba(0, 73, 218, 0.15), 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
}

.awe-layout-container .btn-theme:disabled {
    background-color: var(--primary300);
    border-color: var(--primary300);
    color: var(--white);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
}

.awe-layout-container .btn-theme.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 8px;
    gap: 8px;
}

.awe-layout-container .btn-theme.icon span {
    width: 24px;
    height: 24px;
}

.awe-layout-container .btn-theme.btn-sm {
    padding: 8px 24px;
    font-size: var(--fs14);
}

.awe-layout-container .btn-icon.btn-sm {
    padding: 8px;
}

.awe-layout-container .btn-theme.btn-sm.icon span {
    width: 16px;
    height: 16px;
}

.awe-layout-container .btn-white {
    border-color: var(--white);
    background-color: var(--white);
    color: var(--grey700);
    box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.16);
}

.awe-layout-container .btn-white:hover {
    background-color: var(--white);
    border-color: var(--white);
    color: var(--grey700);
    box-shadow: 0px 0px 0px 1px rgba(0, 80, 240, 0.4);
}

.awe-layout-container .btn-white:focus {
    background-color: var(--white);
    border-color: var(--white);
    color: var(--grey700);
    box-shadow: 0px 0px 0px 2px rgba(0, 73, 218, 0.15), 0px 0px 0px 1px rgba(70, 79, 96, 0.32);
}

.awe-layout-container .btn-white:disabled {
    background-color: var(--grey200);
    border-color: var(--grey200);
    color: var(--grey700);
    box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.2);
}

.awe-layout-container .btn-grey {
    border-color: var(--lightMain);
    background-color: var(--lightMain);
    color: var(--greyMain);
}

.awe-layout-container .btn-grey:hover {
    background-color: var(--dark100);
    border-color: var(--dark100);
    color: var(--darkMain);
}

.awe-layout-container .btn-danger {
    border-color: var(--red300);
    background-color: var(--red300);
    color: var(--white);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
}

.awe-layout-container .btn-danger:hover {
    background-color: var(--red400);
    border-color: var(--red400);
    color: var(--white);
}

.awe-layout-container .btn-danger:focus {
    background-color: var(--red300);
    border-color: var(--red300);
    color: var(--white);
    box-shadow: 0px 0px 0px 2px rgba(219, 15, 66, 0.15), 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
}

.awe-layout-container .btn-danger:disabled {
    background-color: var(--red200);
    border-color: var(--red200);
    color: var(--white);
    box-shadow: 0px 0px 0px 1px #F76F7F, 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 991px) {
    .awe-layout-container .btn-theme {
        padding: 8px 16px;
        font-size: var(--fs14);
    }

    .awe-layout-container .btn-icon {
        padding: 10px;
    }
}

.awe-layout-container .badge {
    padding: 2px 8px;
    font-size: 13px;
    line-height: normal;
    font-weight: 600;
    min-width: 66px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    grid-gap: 6px;
    gap: 6px;
    background-color: var(--primary400);
    color: var(--white);
    line-height: 17px;
    text-transform: uppercase;
    border-radius: 20px;
}

/* .awe-layout-container .badge::before {
    content: "";
    width: 6px;
    height: 6px;
    background-color: var(--white);
    display: block;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
} */

.awe-layout-container .badge-active {
    background-color: var(--primary400);
    color: var(--white);
}

.awe-layout-container .badge-dark {
    background-color: var(--dark100);
    color: var(--darkMain);
}

.awe-layout-container .badge-warning {
    background-color: var(--warning900);
    color: var(--white);
}

.awe-layout-container .badge-orange {
    background-color: var(--warningMain);
    color: var(--white);
}

.awe-layout-container .badge-danger {
    background-color: var(--danger900);
    color: var(--white);
}

.awe-layout-container .badge-light {
    background-color: var(--lightMain);
    color: var(--darkMain);
}

.awe-layout-container .status_badge.badge-light {
    background-color: var(--lightMain);
    color: var(--darkMain);
}

.awe-layout-container .border,
.awe-layout-container .border-top,
.awe-layout-container .border-bottom,
.awe-layout-container .border-end,
.awe-layout-container .border-start {
    border-color: var(--border);
}

@media screen and (max-width: 1399px) {

    /* pagination */
    .awe-layout-container .badge {
        font-size: var(--fs14);
        padding: 6px 14px;
    }
}

.awe-layout-container .pagination li:first-child a {
    border-radius: 12px 0px 0px 12px;
    -webkit-border-radius: 12px 0px 0px 12px;
    -moz-border-radius: 12px 0px 0px 12px;
    -ms-border-radius: 12px 0px 0px 12px;
    -o-border-radius: 12px 0px 0px 12px;
}

.awe-layout-container .pagination li:last-child a {
    border-radius: 0px 12px 12px 0;
    -webkit-border-radius: 0px 12px 12px 0;
    -moz-border-radius: 0px 12px 12px 0;
    -ms-border-radius: 0px 12px 12px 0;
    -o-border-radius: 0px 12px 12px 0;
    border-right: 1px solid var(--border);
}

.awe-layout-container .pagination li a {
    border: 1px solid var(--border);
    background-color: var(--white);
    border-right: 0px;
    padding: 12px;
    font-size: var(--fs16);
    font-weight: 700;
    line-height: normal;
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.awe-layout-container .pagination li.active a,
.awe-layout-container .pagination li:hover a {
    background-color: var(--tosca100);
    color: var(--tosca);
}

@media screen and (max-width: 767px) {

    /* text color */
    .awe-layout-container .pagination li a {
        width: 38px;
        height: 38px;
        padding: 6px;
    }

    .awe-layout-container .pagination li:first-child a {
        border-radius: 8px 0px 0px 8px;
        -webkit-border-radius: 8px 0px 0px 8px;
        -moz-border-radius: 8px 0px 0px 8px;
        -ms-border-radius: 8px 0px 0px 8px;
        -o-border-radius: 8px 0px 0px 8px;
    }

    .awe-layout-container .pagination li:last-child a {
        border-radius: 0px 8px 8px 0;
        -webkit-border-radius: 0px 8px 8px 0;
        -moz-border-radius: 0px 8px 8px 0;
        -ms-border-radius: 0px 8px 8px 0;
        -o-border-radius: 0px 8px 8px 0;
        border-right: 1px solid var(--border);
    }
}

.awe-layout-container .text-primary {
    color: var(--primary400) !important;
}

.awe-layout-container .text-grey-600 {
    color: var(--grey600) !important;
}

.awe-layout-container .text-grey-900 {
    color: var(--grey900) !important;
}

.awe-layout-container .text-grey-100 {
    color: var(--grey100) !important;
}

.awe-layout-container .text-tosca {
    color: var(--primary400) !important;
}

.awe-layout-container .text-danger {
    color: var(--dangerMain) !important;
}

.awe-layout-container .text-dark {
    color: var(--darkMain) !important;
}

.awe-layout-container .text_c3A3C40 {
    color: var(--dark700) !important;
}

.awe-layout-container .text-grey {
    color: var(--greyMain) !important;
}

.awe-layout-container .text-grey-800 {
    color: var(--grey800) !important;
}

.awe-layout-container .text_c979797 {
    color: var(--grey150) !important;
}

.awe-layout-container .text-light-dark {
    color: var(--lightDark) !important;
}

.awe-layout-container .text-light-dark-800 {
    color: var(--lightDark800) !important;
}

.awe-layout-container .text-warning-900 {
    color: var(--warning900) !important;
}

.awe-layout-container .text-warning-800 {
    color: var(--warning800) !important;
}

.awe-layout-container .text-warning-700 {
    color: var(--warning700) !important;
}

.awe-layout-container .dropdown-menu {
    top: 100% !important;
    padding: 0px;
    border-radius: 16px;
    border: 1px solid var(--border);
    background-color: var(--white);
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    min-width: 200px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
}

.awe-layout-container .dropdown-menu li a {
    padding: 16px 20px;
    border-bottom: 1px solid var(--border);
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.awe-layout-container .dropdown-menu li:last-child a {
    border-bottom: 0px;
    /* modal */
}

.awe-layout-container .modal .modal-content {
    border-radius: 24px;
    background: #FFF;
    background: var(--white, #FFF);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.09);
    /* custom_switch */
}

.awe-layout-container .custom_switch .switch-label {
    display: flex;
    align-items: center;
    max-width: 155px;
    min-width: 155px;
    cursor: pointer;
}

.awe-layout-container .custom_switch .switch-label span {
    width: 50%;
    padding: 14px 16px;
    font-size: var(--fs16);
    line-height: 18px;
    border: 1px solid var(--border);
    text-align: center;
    display: block;
    flex-shrink: 0;
}

.awe-layout-container .custom_switch .switch-label .left {
    border-radius: 8px 0px 0px 8px;
    -webkit-border-radius: 8px 0px 0px 8px;
    -moz-border-radius: 8px 0px 0px 8px;
    -ms-border-radius: 8px 0px 0px 8px;
    -o-border-radius: 8px 0px 0px 8px;
}

.awe-layout-container .custom_switch .switch-label .right {
    border-radius: 0px 8px 8px 0px;
    border-left: 0px;
}

.awe-layout-container .custom_switch input:checked+.switch-label .left {
    background-color: var(--tosca);
    color: var(--white);
    border-color: var(--tosca);
}

.awe-layout-container .custom_switch input:not(:checked)+.switch-label .right {
    background-color: var(--dangerMain);
    color: var(--white);
    border-color: var(--dangerMain);
}

.awe-layout-container .switch-wrapper,
.awe-layout-container .switch-label {
    position: relative;
    cursor: pointer;
}

.awe-layout-container .switch-label .switch-box {
    content: "";
    width: 32px;
    height: 10px;
    position: relative;
    display: block;
    background-color: #D2D2D2;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.awe-layout-container .switch-label .switch-box:before {
    content: "";
    width: 16px;
    height: 16px;
    display: block;
    position: absolute;
    top: -3px;
    left: 0px;
    background-color: #7F7F8D;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
}

.awe-layout-container .switch-wrapper .switch-input:checked+.switch-label .on_text,
.awe-layout-container .switch-wrapper .switch-input:not(:checked)+.switch-label .off_text {
    display: block;
}

.awe-layout-container .switch-wrapper .switch-input:not(:checked)+.switch-label .on_text,
.awe-layout-container .switch-wrapper .switch-input:checked+.switch-label .off_text {
    display: none;
}

.awe-layout-container .switch-wrapper .switch-input:checked+.switch-label .switch-box {
    background-color: var(--tosca300);
}

.awe-layout-container .switch-wrapper .switch-input:checked+.switch-label .switch-box::before {
    left: calc(100% - 16px);
    background-color: var(--tosca);
}

.awe-layout-container .list_style_number {
    counter-reset: count;
}

.awe-layout-container .list_style_number li {
    counter-increment: count;
}

.awe-layout-container .list_style_number .count::before {
    content: counter(count);
}

.awe-layout-container .list_style_number .count.dot::before {
    content: counter(count) ".";
}

.awe-layout-container .list_style_dots li {
    display: flex;
    align-items: flex-start;
    grid-gap: 8px;
    gap: 8px;
}

.awe-layout-container .list_style_dots li::before {
    content: "";
    width: 6px;
    height: 6px;
    display: block;
    margin-top: 7px;
    background-color: var(--greyMain);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.awe-layout-container .r12 {
    border-radius: 12px !important;
    -webkit-border-radius: 12px !important;
    -moz-border-radius: 12px !important;
    -ms-border-radius: 12px !important;
    -o-border-radius: 12px !important;
}

.awe-layout-container .r10 {
    border-radius: 10px !important;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    -ms-border-radius: 10px !important;
    -o-border-radius: 10px !important;
}

.awe-layout-container .r8 {
    border-radius: 8px !important;
    -webkit-border-radius: 8px !important;
    -moz-border-radius: 8px !important;
    -ms-border-radius: 8px !important;
    -o-border-radius: 8px !important;
}

.awe-layout-container .r6 {
    border-radius: 6px !important;
    -webkit-border-radius: 6px !important;
    -moz-border-radius: 6px !important;
    -ms-border-radius: 6px !important;
    -o-border-radius: 6px !important;
}

.awe-layout-container .bg-light {
    background-color: var(--lightMain) !important;
}

.awe-layout-container .col_border_left div:nth-child(1n+0) .coder_word_card {
    border-left: 2px solid var(--primary400);
    padding: 10px;
}

.awe-layout-container .status_badge {
    background-color: var(--primary400);
    color: var(--white);
    min-width: 48px;
    height: 22px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: var(--fs13);
    line-height: 18px;
    font-weight: 600;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

.awe-layout-container .status_badge.badge_primary {
    background-color: var(--primary400);
    color: var(--white);
}

.awe-layout-container .status_badge.badge_warning {
    background-color: var(--warning900);
    color: var(--white);
}

.awe-layout-container .status_badge.badge_danger {
    background-color: var(--danger900);
    color: var(--white);
}

.awe-layout-container .status_badge.badge_orange {
    background-color: var(--warningMain);
    color: var(--white);
}

.awe-layout-container .status_badge.badge_yellow {
    background-color: var(--yellow);
    color: var(--white);
}
.awe-layout-container .status_badge.badge_orange {
    background-color: var(--orange);
    color: var(--white);
}

.awe-layout-container .status_badge.badge_green {
    background-color: var(--green);
    color: var(--white);
}
.awe-layout-container .status_badge.badge_red {
    background-color: var(--red);
    color: var(--white);
}

.awe-layout-container .table-short-text {
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.awe-layout-container .mui_custom_table p.MuiTablePagination-caption {
    margin-bottom: 0px;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.45px;
    color: var(--grey800);
}

.awe-layout-container .mui_custom_table .custom_table_responsive+.MuiTable-root .MuiTableCell-root {
    padding: 0px;
}

.awe-layout-container .mui_custom_table .custom_table_responsive+.MuiTable-root .MuiSelect-root {
    padding: 0px;
}

.awe-layout-container .mui_custom_table .custom_table_responsive+.MuiTable-root .MuiTablePagination-selectRoot {
    margin-right: 0px;
    padding-right: 22px;
}

.text_line1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.awe-layout-container .filter_dropdown .dropdown-menu {
    box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.25);
    right: 0;
    left: unset !important;
    background: #FFFFFF;
    border: 0px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.awe-layout-container .custom_select.MuiInputBase-root {
    width: 100%;
    display: block;
}

.awe-layout-container .custom_select .MuiSelect-select {
    border: 1px solid var(--light700);
    /*background: var(--white);
     */
    font-family: var(--fonts-OpenSans);
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    padding: 8px 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    width: auto !important;
}

.awe-layout-container .custom_select .custom_select__value-container {
    padding: 0px;
}

.MuiMenu-list .MuiMenuItem-root {
    font-family: var(--fonts-OpenSans);
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
}

.awe-layout-container .custom_select .custom_select__control {
    border: 1px solid var(--light700);
    /*background: var(--white);
     */
    font-family: var(--fonts-OpenSans);
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    padding: 8px 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    width: auto !important;
    padding: 0px;
    padding-left: 8px;
    padding-right: 8px;
    margin: 0px;
}

.awe-layout-container .custom_select .custom_select__indicator {
    padding-left: 0px;
    padding-right: 0px;
}

.awe-layout-container .custom_select .custom_select__menu-list .custom_select__option {
    font-family: var(--fonts-OpenSans);
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
}

.awe-layout-container .custom_select .custom_select__indicator-separator {
    display: none;
}

.awe-layout-container .form-control[type="date"]::-webkit-calendar-picker-indicator {
    background: url(/static/media/calendar_icon.6f83d39f.svg) no-repeat;
}

/* .awe-layout-container p.MuiTablePagination-caption {
    margin-bottom: 4px;
}

.awe-layout-container .MuiTableSortLabel-root .MuiTableSortLabel-icon {
    opacity: 1;
}

.awe-layout-container .custom_table {
    width: 100%;
}

.awe-layout-container .custom_table table {
    width: 100%;
    border-collapse: collapse;
}

.awe-layout-container .custom_table th,
.awe-layout-container .custom_table td {
    border: 1px solid #E9EDF5;
    padding: 10px;
    text-align: center;
}

.awe-layout-container .custom_table th {
    background: #F7F8FB;
    text-transform: uppercase;
    font-weight: 700;
}

.awe-layout-container .custom_table td {
    background: white;
}

.awe-layout-container .custom_table .Action,
.awe-layout-container .custom_table .Status,
.awe-layout-container .custom_table .DxCode,
.awe-layout-container .custom_table .PageNo {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 40px;
}

.awe-layout-container .custom_table .Tag {
    width: 48px;
    height: 22px;
    padding: 8px;
    background: #1A9698;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
}

.awe-layout-container .custom_table .PageNo,
.awe-layout-container .custom_table .RowsPerPage {
    display: flex;
    align-items: center;
    gap: 20px;
}

.awe-layout-container .custom_table .Button {
    padding: 4px;
    background: #F7F9FC;
    box-shadow: 0 0 0 1px rgba(70, 78.67, 96, 0.20);
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
} */

.awe-layout-container .mui_custom_table .table {
    font-size: 14px;
    color: var(--grey100);
    font-weight: 400;
    line-height: 20px;
}

.awe-layout-container .mui_custom_table .table thead {
    background-color: var(--light500);
}

.awe-layout-container .mui_custom_table .table thead th {
    border-bottom: 0px;
    font-size: 13px;
    color: var(--dark900);
    font-weight: 700;
    line-height: 20px;
    padding: 7px 16px;
    text-transform: uppercase;
}

.awe-layout-container .mui_custom_table .table thead th.sort_th .table_sort_icon {
    color: var(--grey600);
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
}

.awe-layout-container .mui_custom_table .table thead th.sort_th:hover .table_sort_icon {
    color: var(--dark900);
}

.awe-layout-container .mui_custom_table .table tbody td {
    padding: 7px 16px;
    vertical-align: middle;
}

.awe-layout-container .border-top-2 {
    border-top: 2px solid var(--border);
}

.border-warning-900 {
    border-color: var(--warning900) !important;
}

.border-danger-900 {
    border-color: var(--danger900) !important;
}

.border-dark-650 {
    border-color: var(--dark650) !important;
}

.custom_checkbox .MuiSvgIcon-root {
    color: var(--border);
    width: 24px;
    height: 24px;
}

.custom_checkbox .Mui-checked .MuiSvgIcon-root {
    color: var(--primary400);
}

@-webkit-keyframes ping {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 0.5;
    }

    100% {
        -webkit-transform: scale(2);
                transform: scale(2);
        opacity: 0;
    }
}

@keyframes ping {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 0.5;
    }

    100% {
        -webkit-transform: scale(2);
                transform: scale(2);
        opacity: 0;
    }
}

.dotsAnimation {
    position: relative;
    width: 10px;
    height: 10px;
    display: block;
    background-color: var(--dangerMain);
    border-radius: 10px;
}

.dotsAnimation::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--dangerMain);
}

.dotsAnimation::before {
    -webkit-animation: ping 1.7s ease infinite;
            animation: ping 1.7s ease infinite;
    opacity: 0.25;
}


.custom_row {
    display: flex;
    grid-gap: 16px;
    gap: 16px;
}

.custom_row>* {
    flex: 1 0 auto;
    width: calc(50% - 8px);
}

.options li.selected {
    background-color: #f0f0f0 !important;
    /* Add your preferred highlight color */
}


.awe-layout-container div.custom-autocomplete input.MuiAutocomplete-input {
    border: 1px solid var(--light700);
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    padding: 8px 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.awe-layout-container div.custom-autocomplete div.MuiOutlinedInput-root {
    border: 0px solid var(--light700);
    padding: 0px;
}

.awe-layout-container button.cw-icon-design {
    background-color: #1a9698;
    color: #fff !important;
    border-radius: 50px;
    padding: 3px 5px;
    font-size: 10px;
    font-weight: bold;
}

.query-text p {
    margin-bottom:2px !important;
}

.ql-editor blockquote, .ql-editor h1, .ql-editor h2, .ql-editor h3, .ql-editor h4, .ql-editor h5, .ql-editor h6, .ql-editor ol, .ql-editor p, .ql-editor pre, .ql-editor ul {
    margin: 0 !important;
    padding: 0 !important;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.awe-layout-container header {
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.09);
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 99;
    background-color: var(--white);
    border-bottom: 1px solid var(--border);
}

.awe-layout-container header .navbar {
    padding: 10px 30px;
}
.awe-layout-container header .navbar .dropdown .dropdown-toggle::after{
    display: none;
}
.awe-layout-container header .navbar .dropdown-relative {
    position: relative;
}
.awe-layout-container header .navbar .dropdown.user_menu .dropdown-menu{
    left: unset;
    right: 0px;
    width: 230px;
}
.awe-layout-container header .navbar .dropdown-menu {
    width: 360px;
    border: 0;
    padding: 0.5rem;
    border-radius: 10px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.awe-layout-container header .navbar .dropdown-menu::after {
    content: '';
    width: 13px;
    height: 13px;
    background: #fff;
    position: absolute;
    top: -6px;
    right: 16px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
}

.awe-layout-container header .navbar .dropdown-menu::after {
    content: '';
    width: 13px;
    height: 13px;
    background: #ffff;
    position: absolute;
    top: -6px;
    right: 16px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
}

.awe-layout-container .notifications-list .icon {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    background-color: var(--lightMain);
    -webkit-border-radius: 48px;
    -moz-border-radius: 48px;
    -ms-border-radius: 48px;
    -o-border-radius: 48px;
}

.awe-layout-container .overlay {
    background-color: var(--dark500);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
}

.awe-layout-container .overlay.isOpen {
    opacity: 1;
    visibility: visible;
}

@media screen and (max-width: 1199px) {
    .awe-layout-container header .navbar {
        padding: 14px 16px;
    }

    .awe-layout-container header .navbar .user_img {
        width: 36px;
        height: 36px;
    }
}

.awe-layout-container .noarrow.dropdown-toggle::after {
    display: none;
}

.awe-layout-container .auth-wrapper .main-wrapper {
    min-height: unset;
    max-height: unset;
    max-width: 100%;
    height: unset;
    margin-left: unset;
}

.awe-layout-container .header-wrapper {
    max-width: calc(100vw - 260px);
    margin-left: 260px;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
}

body.awe-layout-container.sidebar_mini .header-wrapper {
    margin-left: 70px;
    max-width: calc(100vw - 70px);
}

.awe-layout-container .main-wrapper {
    min-height: calc(100vh - 72px);
    max-height: calc(100vh - 72px);
    max-width: calc(100vw - 260px);
    height: 100vh;
    margin-left: 260px;
    padding: 30px;
    padding-top: 86px;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
}

body.awe-layout-container.sidebar_mini .main-wrapper {
    margin-left: 70px;
    min-height: calc(100vh - 80px);
    max-height: calc(100vh - 80px);
    max-width: calc(100vw - 70px);
}

.awe-layout-container .navbar .dropdown.user_menu .user_text p {
    color: var(--grey150);
}

.awe-layout-container .navbar .dropdown.user_menu .dropdown-menu li a {
    border-bottom: 0px;
    padding: 12px 16px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.awe-layout-container .navbar .dropdown.user_menu .dropdown-menu li:hover a {
    background-color: var(--primary100);
    color: var(--primaryMain);
}

.awe-layout-container .navbar .dropdown.notify_menu .dropdown-menu li a {
    padding: 0px;
}

@media screen and (max-width: 1199px) {
    body.awe-layout-container.sidebar_mini .main-wrapper {
        margin-left: 0;
        min-height: unset;
        max-height: unset;
        max-width: unset;
    }

    .awe-layout-container .header-wrapper {
        max-width: 100%;
        margin-left: 0px;
    }

    body.awe-layout-container.sidebar_mini .header-wrapper {
        max-width: 100%;
        margin-left: 0px;
    }

    .awe-layout-container .main-wrapper {
        max-width: 100%;
        margin-left: 0px;
        padding: 24px;
        padding-top: 86px;
    }
}

@media screen and (max-width: 575px) {
    .awe-layout-container .main-wrapper {
        padding: 16px;
        padding-top: 86px;
    }
}

.awe-layout-container .breadcrumb{
    padding: 0px;
    background-color: transparent;
}
.awe-layout-container .breadcrumb_dropdown_menu>* {
    cursor: pointer;
}

.awe-layout-container .breadcrumb_dropdown_menu .dropdown-menu {
    min-width: 305px;
}

.awe-layout-container .breadcrumb_dropdown_menu .dropdown-menu ul {
    overflow-y: auto;
    max-height: 370px;
}

.awe-layout-container footer{
    padding: 16px 30px;
    max-width: calc(100vw - 260px);
    margin-left: 260px;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
}

body.awe-layout-container.sidebar_mini footer {
    margin-left: 70px;
    max-width: calc(100vw - 70px);
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 99;
}


@charset "utf-8";

.awe-layout-container .card {
    border: 0px;
    background-color: var(--white);
    height: 100%;
    word-break: break-word;
    border-radius: 10px;
}

.awe-layout-container .card-body {
    padding: 20px;
}

.awe-layout-container .card-footer,
.awe-layout-container .card-bottom {
    padding: 16px 20px 20px;
    border-top: 1px solid var(--border);
    background-color: transparent;
}

.awe-layout-container .card-bottom {
    padding: 16px 20px;
    border-top: 1px solid var(--border);
}

.awe-layout-container .card .card_header .card-title {
    margin-bottom: 0px;
    color: var(--lightDark800);
}

@media screen and (max-width: 1399px) {
    .awe-layout-container .card {
        border-radius: 16px;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        -ms-border-radius: 16px;
        -o-border-radius: 16px;
    }

    .awe-layout-container .card-body {
        padding: 16px;
    }

    .awe-layout-container .card-footer {
        padding: 8px 16px 16px;
    }

    .awe-layout-container .card-bottom {
        padding: 8px 16px;
    }
}

.awe-layout-container .icon {
    flex-shrink: 0;
    /* grid and list layout */
}

.awe-layout-container .grid .list-layout,
.awe-layout-container .list .grid-layout {
    display: none;
}

.awe-layout-container .grid .grid-layout,
.awe-layout-container .list .list-layout {
    display: block;
}

.awe-layout-container .progress {
    --bs-progress-height: 8px;
    background-color: var(--border300);
}

.awe-layout-container .progress-tosca {
    background-color: var(--tosca);
    border-radius: 10px;
}

.awe-layout-container .td_bg_transparent tr td,
.awe-layout-container .td_bg_transparent tr th {
    background-color: transparent;
}

.awe-layout-container .custom-table-wrap .form-check-input {
    width: 20px;
    height: 20px;
}

.awe-layout-container .custom-table-wrap .table.table_rounded {
    border-collapse: separate;
    border: 0px;
    border-spacing: 0px;
}

.awe-layout-container .custom-table-wrap .table {
    border-collapse: collapse;
    border-spacing: 0px;
}

.awe-layout-container .custom-table-wrap .table.border-none thead tr th,
.awe-layout-container .custom-table-wrap .table.border-none tbody tr td {
    border: 0px;
}

.awe-layout-container .custom-table-wrap .table.td_border td,
.awe-layout-container .custom-table-wrap .table.td_border th {
    border-bottom: 1px solid var(--border);
    border-left: 1px solid var(--border);
}

.awe-layout-container .custom-table-wrap .table.td_border.in_border_0 td,
.awe-layout-container .custom-table-wrap .table.td_border.in_border_0 th {
    border-left: 0px;
}

.awe-layout-container .custom-table-wrap .table.td_border.in_border_0 td:first-child,
.awe-layout-container .custom-table-wrap .table.td_border.in_border_0 th:first-child {
    border-left: 1px solid var(--border);
}

.awe-layout-container .custom-table-wrap .table.td_border.in_border_0 td:last-child,
.awe-layout-container .custom-table-wrap .table.td_border.in_border_0 th:last-child {
    border-right: 1px solid var(--border);
}

.awe-layout-container .custom-table-wrap .table.td_border thead th {
    border-top: 1px solid var(--border);
}

.awe-layout-container .custom-table-wrap .table.td_border td:last-child,
.awe-layout-container .custom-table-wrap .table.td_border th:last-child {
    border-right: 1px solid var(--border);
}

.awe-layout-container .custom-table-wrap .table.td_border_bottom td,
.awe-layout-container .custom-table-wrap .table.td_border_bottom th,
.awe-layout-container .custom-table-wrap .table.tr_border_bottom tr {
    border-bottom: 1px solid var(--border) !important;
}

.awe-layout-container .custom-table-wrap .table thead tr th {
    background-color: var(--lightMain);
    padding: 16px 14px;
}

.awe-layout-container .custom-table-wrap .table.table_rounded tr th:first-child {
    border-top-left-radius: 12px;
}

.awe-layout-container .custom-table-wrap .table.table_rounded tr th:last-child {
    border-top-right-radius: 12px;
}

.awe-layout-container .custom-table-wrap .table.table_rounded tr:last-child td:first-child {
    border-bottom-left-radius: 12px;
}

.awe-layout-container .custom-table-wrap .table.table_rounded tr:last-child td:last-child {
    border-bottom-right-radius: 12px;
}

.awe-layout-container .custom-table-wrap .table.table_rounded_0 tr th:first-child,
.awe-layout-container .custom-table-wrap .table.table_rounded_0 tr th:last-child,
.awe-layout-container .custom-table-wrap .table.table_rounded_0 tr:last-child td:first-child,
.awe-layout-container .custom-table-wrap .table.table_rounded_0 tr:last-child td:last-child {
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
}

.awe-layout-container .custom-table-wrap .table tbody tr td {
    padding: 16px 14px;
    vertical-align: middle;
}

.awe-layout-container .custom-table-wrap .table.td_border_bottom tr:last-child td,
.awe-layout-container .custom-table-wrap .table.tr_border_bottom tbody tr:last-child {
    border-bottom: 0px;
}

.awe-layout-container .custom-table-wrap .table:not(.not_striped) tbody tr:nth-child(even) td {
    background-color: var(--lightMain);
}

.awe-layout-container .list-group-items li>* {
    padding: 20px 24px;
    border-bottom: 1px solid var(--border);
    display: block;
}

.awe-layout-container .list-group-items li:last-child a {
    border-bottom: 0px;
}

.awe-layout-container .custom-table-wrap table.space-0 tr td,
.awe-layout-container .custom-table-wrap table.space-0 tr th {
    padding: 0px;
}

.awe-layout-container .custom-table-wrap table.space-0 tr td p,
.awe-layout-container .custom-table-wrap table.space-0 tr th p {
    padding: 16px 14px;
    line-height: 1.5;
    /* .auth-wrapper */
}

.awe-layout-container .auth-wrapper .card .card-body {
    padding: 32px;
}

.awe-layout-container .auth-wrapper .main-wrapper .row {
    min-height: calc(100vh - 152px);
    max-height: calc(100vh - 152px);
    height: calc(100vh - 152px);
}

@media screen and (max-width: 991px) {

    /* tabs_wrapper */
    .awe-layout-container .auth-wrapper .main-wrapper .row {
        min-height: unset;
        max-height: unset;
        height: unset;
    }

    .awe-layout-container .auth-wrapper .card .card-body {
        padding: 24px;
    }
}

.awe-layout-container .tabs_wrapper {
    background-color: var(--white);
}

.awe-layout-container .tabs_wrapper ul {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--lightMain);
}

.awe-layout-container .tabs_wrapper ul li button {
    font-size: var(--fs16);
    font-weight: 600;
    color: var(--grey300);
    padding: 10px 16px;
    border: 0px !important;
    border-bottom: 2px solid var(--grey150) !important;
    margin-bottom: 0px;
}

.awe-layout-container .tabs_wrapper ul li button.active,
.awe-layout-container .tabs_wrapper ul li button:hover {
    border: 0px;
    border-bottom-color: var(--primary400) !important;
    color: var(--primary400) !important;
}

@media screen and (max-width: 1399px) {
    .awe-layout-container .tabs_wrapper ul li button {
        padding: 14px 24px;
        font-size: var(--fs14);
    }
}

.awe-layout-container .minh_80 {
    height: 80px;
}

.awe-layout-container .offcanvas {
    --bs-offcanvas-width: 350px;
}

.awe-layout-container .offcanvas .offcanvas-header,
.awe-layout-container .offcanvas .offcanvas-body {
    padding: 16px 20px;
}

.awe-layout-container .diagnosis_code_sidebar .offcanvas-body {
    min-height: calc(100vh - 56px);
    max-height: 100%;
}

.awe-layout-container .history_card_list .history_card_item {
    padding: 8px 16px;
    background-color: #FFFFFF;
    box-shadow: 1px 1px 9px -3px rgba(0, 0, 0, 0.25);
    border-left: 5px solid;
}

.awe-layout-container .history_card_list .history_card_item:not(:last-child) {
    margin-bottom: 10px;
}

.awe-layout-container .history_card_list .history_card_item.status-danger {
    border-left-color: var(--danger900);
}

.awe-layout-container .history_card_list .history_card_item.status-warning {
    border-left-color: var(--warningMain);
}

.awe-layout-container .history_card_list .history_card_item.status-orange {
    border-left-color: var(--warningMain);
}

.awe-layout-container .history_card_list .history_card_item.status-primary {
    border-left-color: var(--primary400);
}
.awe-layout-container .history_card_list .history_card_item.status-light {
    border-left-color: var(--lightMain);
}

.mui_custom_table table .collapse_btn.collapsed svg {
    transform: rotate(00deg);
    -webkit-transform: rotate(00deg);
    -moz-transform: rotate(00deg);
    -ms-transform: rotate(00deg);
    -o-transform: rotate(00deg);
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
}

.mui_custom_table table .collapse_btn:not(.collapsed) svg {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
}

.mui_custom_table .custom_table_responsive {
    overflow-x: auto;
    max-width: 100%;
}

.mui_custom_table .custom_table th {
    z-index: 0;
}

.mui_custom_table .custom_table .th-align-middle th {
    vertical-align: middle;
}

.mui_custom_table .custom_table th .MuiButton-root {
    margin-left: 0px;
    margin-right: 0px;
    font-size: 13px;
    font-weight: 700;
    color: #616161;
    font-family: inherit;
    text-transform: uppercase;
}

.custom_table_responsive+table {
    min-width: unset;
}

.awe-layout-container .custom-autocomplete {
    position: relative;
}

.awe-layout-container .custom-autocomplete ul:empty {
    display: none;
}

.awe-layout-container .custom-autocomplete ul.options {
    position: absolute;
    list-style: none;
    padding: 0;
    margin-top: 0px;
    width: 100%;
    background-color: var(--white);
    border: 1px solid var(--border);
    border-top: none;
    z-index: 1;
    max-height: 200px;
    overflow: auto;
    font-size: 13px;
    font-weight: 500;
}

.awe-layout-container .custom-autocomplete ul.options li {
    padding: 8px 10px;
    cursor: pointer;
    background-color: var(--white);
    border-bottom: 1px solid var(--border);
}

.awe-layout-container .custom-autocomplete ul.options li:hover {
    background-color: #f0f0f0;
}

.awe-layout-container .form-input-error {
    color: var(--dangerMain) !important;
    font-size: 11px;
}

.awe-layout-container .MuiTooltip-tooltip {
    font-size: 15px;
    font-weight: 400;
    color: var(--white);
    padding: 6px 14px;
    background: var(--dark800);
    min-width: 90px;
    text-align: center;
    font-family: inherit;
}

.awe-layout-container .css-kudwh-MuiTooltip-arrow {
    color: var(--dark800);
}

.awe-layout-container .filter_dropdown_menu .MuiPaper-root {
    box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.25);
    background: #FFFFFF;
    border: 0px;
    padding: 16px 20px;
    min-width: 290px;
    max-width: 290px;
    width: 100%;
    border-radius: 10px;
}


.awe-layout-container .pagination_select .MuiSelect-select {
    border: 0px;
    padding: 0px;
    height: auto;
    min-height: auto;
    padding-right: 24px !important;
    background-color: transparent;
    font-family: var(--fonts-OpenSans);
}

.awe-layout-container .pagination_select .MuiOutlinedInput-notchedOutline {
    border: 0px;
}

.awe-layout-container .pagination_select .MuiSelect-select .MuiSvgIcon-root {
    right: 0px;
}

.summary_status_wrap .summary_status_widget.widget1 ul li {
    padding: 11px 8px;
}

.awe-layout-container .MuiLinearProgress-root {
    background-color: var(--light900);
}

.awe-layout-container .MuiLinearProgress-root .MuiLinearProgress-bar {
    background-color: var(--primary400);
}

.awe-layout-container .MuiLinearProgress-root.liner_danger .MuiLinearProgress-bar{
    background-color: var(--danger900);
}
.awe-layout-container .MuiLinearProgress-root.liner_black .MuiLinearProgress-bar {
    background-color: var(--dark1000);
}
.mui_custom_table table .MuiLinearProgress-root,
.mui_custom_table table .MuiLinearProgress-root .MuiLinearProgress-bar {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

@media screen and (max-width: 413px) {

    .annual_wellness_filter .right_part .flex_item_cs,
    .annual_wellness_filter .right_part .custom_select {
        flex-grow: 1;
        max-width: 100%;
    }
}

.align-middle {
    vertical-align: middle !important;
}

.awe-layout-container .summary_status_wrap .col_border_left  .summary_status_widget{
    border: 1px solid var(--border);
    border-top: 0px;
}
.awe-layout-container .quick_links_menu ul{
    grid-gap: 6px;
    gap: 6px;
    padding: 15px;
    background-color: var(--light650);
}
.awe-layout-container .quick_links_menu ul li{
    display: flex;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
    font-size: var(--fs13);
    font-weight: 400;
}
.awe-layout-container .quick_links_menu ul li a{
    display: block;
}
.awe-layout-container .quick_links_menu ul li:not(:last-child):after{
    content: "|";
    display: block;
    line-height: 1.2;
}

.awe-layout-container div.Toastify__toast {
    margin-bottom:15px;
}
.awe-layout-container div.Toastify__toast-icon {
    margin-right: 10px;
}
/*
iframe {
    display: none !important;
}
*/
.download-button {
    background-color: lightgreen;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }
  
  .download-icon {
    margin-right: 8px;
    font-size: 20px;
  }
  
  .btn {
    background-color: DodgerBlue;
    border: none;
    color: white;
    padding: 12px 30px;
    cursor: pointer;
    font-size: 20px;
  }

  
.ui-item h2 {
  font-size: 60px;
  line-height: 80px;
  font-weight: 300;
  margin: 2px 0px;
  padding: 0px;
}

.ui-item {
  height: 170px;
  margin: 10px auto;
  padding: 20px;
  cursor: pointer;
}

.ui-item a {
  color: #fff;
}

.text-center {
  text-align: center;
}
/* 
.bg-green {
    background-color: #51d466 ;
}

.bg-purple {
    background-color: #cb79e6 !important;
}

.bg-lblue {
    background-color: #32c8de !important;
}

.bg-yellow {
    background-color: #fcd419 !important;
} */

.home-heading {
  font-size: 26px;
  line-height: 37px;
  margin: 19px;
  font-weight: bold;
}

