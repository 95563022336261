@charset "utf-8";

:root {
    --primaryMain: #093535;
    --primary900: #0c4444;
    --primary800: #105a5b;
    --primary700: #147172;
    --primary600: #15787a;
    --primary500: #178789;
    --primary400: #1a9698;
    --primary300: #b8dedf;
    --primary200: #ddeff0;
    --primary100: #e8f5f5;
    --primary50: #F2FAFF;

    --darkMain: #000000;
    --dark1000: #391C1C;
    --dark900: #616161;
    --dark800: #313131;
    --dark700: #3A3C40;
    --dark650: #3A1D1D;
    --dark600: rgb(41, 41, 62, 0.6);
    --dark500: rgb(41, 41, 62, 0.5);
    --dark400: rgb(41, 41, 62, 0.4);
    --dark300: rgb(41, 41, 62, 0.3);
    --dark200: rgb(41, 41, 62, 0.2);
    --dark100: rgb(41, 41, 62, 0.1);

    --lightDark: #3A3939;
    --lightDark900: rgb(58, 57, 57, 0.9);
    --lightDark800: rgb(58, 57, 57, 0.8);
    --lightDark700: rgb(58, 57, 57, 0.7);
    --lightDark600: rgb(58, 57, 57, 0.6);
    --lightDark500: rgb(58, 57, 57, 0.5);
    --lightDark400: rgb(58, 57, 57, 0.4);
    --lightDark300: rgb(58, 57, 57, 0.3);
    --lightDark200: rgb(58, 57, 57, 0.2);
    --lightDark100: rgb(58, 57, 57, 0.1);

    --greyMain: #171C26;
    --grey900: #464F60;
    --grey800: #687182;
    --grey700: #868FA0;
    --grey600: #A1A9B8;
    --grey500: #6B6B6B;
    --grey400: #82868C;
    --grey300: #6F727A;
    --grey200: #F7F9FC;
    --grey150: #979797;
    --grey100: #585555;

    --tosca: #27A9B9;
    --tosca900: rgb(39, 169, 185, 0.9);
    --tosca800: rgb(39, 169, 185, 0.8);
    --tosca700: rgb(39, 169, 185, 0.7);
    --tosca600: rgb(39, 169, 185, 0.6);
    --tosca500: rgb(39, 169, 185, 0.5);
    --tosca400: rgb(39, 169, 185, 0.4);
    --tosca300: rgb(39, 169, 185, 0.3);
    --tosca200: rgb(39, 169, 185, 0.2);
    --tosca100: rgb(39, 169, 185, 0.1);

    --lightMain: #DADADA;
    --light900: #D9D9D9;
    --light800: #D4D4D4;
    --light700: #DEDEDE;
    --light650: #F5F5F5;
    --light600: #F4F4F4;
    --light500: #E9EDF5;
    --light400: #F7F8FB;
    --light300: #55555B;
    --light200: rgb(242, 242, 245, 0.2);
    --light100: rgb(242, 242, 245, 0.1);

    --border: #D4D4D4;
    --border900: rgb(214, 214, 214, 0.9);
    --border800: rgb(214, 214, 214, 0.8);
    --border700: rgb(214, 214, 214, 0.7);
    --border600: rgb(214, 214, 214, 0.6);
    --border500: rgb(214, 214, 214, 0.5);
    --border400: rgb(214, 214, 214, 0.4);
    --border300: rgb(214, 214, 214, 0.3);
    --border200: rgb(214, 214, 214, 0.2);
    --border100: rgb(214, 214, 214, 0.1);

    --blueMain: #1C274C;
    --blue900: rgb(28, 39, 76, 0.9);
    --blue800: rgb(28, 39, 76, 0.8);
    --blue700: rgb(28, 39, 76, 0.7);
    --blue600: rgb(28, 39, 76, 0.6);
    --blue500: rgb(28, 39, 76, 0.5);
    --blue400: rgb(28, 39, 76, 0.4);
    --blue300: rgb(28, 39, 76, 0.3);
    --blue200: rgb(28, 39, 76, 0.2);
    --blue100: rgb(28, 39, 76, 0.1);

    --dangerMain: #FF0000;
    --danger900: #FF5367;
    --danger800: rgb(255, 83, 103, 0.8);
    --danger700: rgb(255, 83, 103, 0.7);
    --danger600: rgb(255, 83, 103, 0.6);
    --danger500: rgb(255, 83, 103, 0.5);
    --danger400: rgb(255, 83, 103, 0.4);
    --danger300: rgb(255, 83, 103, 0.3);
    --danger200: rgb(255, 83, 103, 0.2);
    --danger100: rgb(255, 83, 103, 0.1);

    --redMain: #FF0000;
    --red400: #E23F68;
    --red300: #E75E80;
    --red200: #F76F7F;
    --red100: #FCC5CB;

    --warningMain: #E9731D;
    --warning900: #FFC701;
    --warning800: #F1C40F;
    --warning700: #F2C94C;
    --warning600: rgb(230, 164, 37, 0.6);
    --warning500: rgb(230, 164, 37, 0.5);
    --warning400: rgb(230, 164, 37, 0.4);
    --warning300: rgb(230, 164, 37, 0.3);
    --warning200: rgb(230, 164, 37, 0.2);
    --warning100: rgb(230, 164, 37, 0.1);

    --white: #FFFFFF;
    --white900: rgb(255, 255, 255, 0.9);
    --white800: rgb(255, 255, 255, 0.8);
    --white700: rgb(255, 255, 255, 0.7);
    --white600: rgb(255, 255, 255, 0.6);
    --white500: rgb(255, 255, 255, 0.5);
    --white400: rgb(255, 255, 255, 0.4);
    --white300: rgb(255, 255, 255, 0.3);
    --white200: rgb(255, 255, 255, 0.2);
    --white100: rgb(255, 255, 255, 0.1);

    --fonts-OpenSans: 'Open Sans', sans-serif;;

    --h1: 2.25rem;
    --h2: 2rem;
    --h3: 1.75rem;
    --h4: 1.5rem;
    --h5: 1.25rem;
    --h6: 0.9375rem;
    --p: 0.9375rem;

    --fs36: 2.25rem;
    --fs34: 2.125rem;
    --fs32: 2rem;
    --fs30: 1.875rem;
    --fs28: 1.75rem;
    --fs26: 1.625rem;
    --fs24: 1.5rem;
    --fs22: 1.375rem;
    --fs20: 1.25rem;
    --fs18: 1.125rem;
    --fs16: 1rem;
    --fs15: 0.9375rem;
    --fs14: 0.875rem;
    --fs13: 13px;
    --fs12: 0.75rem;
    --fs10: 0.625rem;
}

/* @media screen and (max-width:1399px) {
    :root {
        --h1: 2rem;
        --h2: 1.75rem;
        --h3: 1.5rem;
        --h4: 1.5rem;
        --h5: 1.125rem;
        --h6: 0.875rem;
        --p: 0.875rem;
    }
} */

@media screen and (max-width: 575px) {
    :root {
        --h1: 1.75rem;
        --h2: 1.5rem;
        --h3: 1.25rem;
        --h4: 1.125rem;
        --h5: 1rem;
    }
}