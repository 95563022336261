@charset "utf-8";

.awe-layout-container .sidebar-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9;
  max-width: 260px;
  background-image: linear-gradient(180deg, #414149 0%, #242428 100%);
  height: 100vh;
  max-width: 260px;
  width: 100%;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.09);
}

.awe-layout-container .sidebar-header {
  padding: 20px;
  color: var(--white);
  border-bottom: 1px solid var(--light300);
}

.awe-layout-container .sidebar-wrapper > ul {
  max-height: calc(100vh - 87px);
  overflow-y: auto;
  padding: 10px;
  gap: 10px;
}

.awe-layout-container .sidebar-wrapper ul li a {
  padding: 7px 14px;
  font-weight: 400;
  color: var(--white);
  font-size: var(--fs14);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.awe-layout-container .sidebar-wrapper > ul > li > a{
  padding-left: 0px;
  padding-right: 0px;
}
.awe-layout-container .sidebar-wrapper ul li a svg{
  transition:  all 0.5s ease;
  -webkit-transition:  all 0.5s ease;
  -moz-transition:  all 0.5s ease;
  -ms-transition:  all 0.5s ease;
  -o-transition:  all 0.5s ease;
}
.awe-layout-container .sidebar-wrapper ul li a:not(.collapsed) .arrow_icon svg{
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}


.awe-layout-container .sidebar-wrapper ul ul li a:hover,
.awe-layout-container .sidebar-header ul a:hover,
.awe-layout-container .sidebar-wrapper ul ul li a.active {
  color: var(--white);
}

.awe-layout-container .sidebar-wrapper ul ul li a.active,
.awe-layout-container .sidebar-wrapper ul ul li a:hover {
  background-color: var(--primary400);
}

.awe-layout-container .sidebar-wrapper.is_mini {
  max-width: 70px;
}

.awe-layout-container .sidebar-wrapper.is_mini ul li a {
  padding: 13px;
}
.awe-layout-container .sidebar-wrapper ul li .submenu {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.awe-layout-container .sidebar-wrapper ul li .submenu.collapse:not(.show){
  display: none;
}
.awe-layout-container .sidebar-wrapper .sidebar-header a .icon,
.awe-layout-container .sidebar-wrapper,
.awe-layout-container .sidebar-wrapper ul li a,
.awe-layout-container .sidebar-wrapper ul li a .text {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

.awe-layout-container .sidebar-wrapper.is_mini .sidebar-header a .icon {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.awe-layout-container .sidebar-wrapper.is_mini ul li a .text,
.awe-layout-container .sidebar-wrapper.is_mini .sidebar-header a .text {
  display: none;
  opacity: 0px;
  /* @media screen and (max-width:1399px) {
     .sidebar-wrapper ul li a{
     padding: 20px 24px;
     }
     .sidebar-wrapper.is_mini ul li a {
     padding: 20px 22px;
     }
     } */
}

@media screen and (max-width: 1199px) {
  .awe-layout-container .sidebar-wrapper {
    top: 0px;
    height: 100%;
    left: -320px;
    z-index: 99;
  }

  body.sidebar_mini .sidebar-wrapper {
    display: block;
    left: 0px;
  }
}


.awe-layout-container .diagnosis_code_sidebar {
  position: fixed;
  bottom: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: #212529;
  visibility: hidden;
  background-color: #ffffff;
  background-clip: padding-box;
  top: 0;
  right: 0;
  width: 350px;
  border-left: 1px solid rgba(0, 0, 0, 0.175);
  outline: 0;
  transition: transform 0.3s ease-in-out;
  -webkit-transition: transform 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out;
  -ms-transition: transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
}

.awe-layout-container .diagnosis_code_sidebar.show{
  visibility: visible;
  transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
}
.awe-layout-container .diagnosis_code_sidebar + .overlay {
  z-index: 99;
}