.awe-layout-container header {
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.09);
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 99;
    background-color: var(--white);
    border-bottom: 1px solid var(--border);
}

.awe-layout-container header .navbar {
    padding: 10px 30px;
}
.awe-layout-container header .navbar .dropdown .dropdown-toggle::after{
    display: none;
}
.awe-layout-container header .navbar .dropdown-relative {
    position: relative;
}
.awe-layout-container header .navbar .dropdown.user_menu .dropdown-menu{
    left: unset;
    right: 0px;
    width: 230px;
}
.awe-layout-container header .navbar .dropdown-menu {
    width: 360px;
    border: 0;
    padding: 0.5rem;
    border-radius: 10px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.awe-layout-container header .navbar .dropdown-menu::after {
    content: '';
    width: 13px;
    height: 13px;
    background: #fff;
    position: absolute;
    top: -6px;
    right: 16px;
    transform: rotate(45deg);
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
}

.awe-layout-container header .navbar .dropdown-menu::after {
    content: '';
    width: 13px;
    height: 13px;
    background: #ffff;
    position: absolute;
    top: -6px;
    right: 16px;
    transform: rotate(45deg);
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
}

.awe-layout-container .notifications-list .icon {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    background-color: var(--lightMain);
    -webkit-border-radius: 48px;
    -moz-border-radius: 48px;
    -ms-border-radius: 48px;
    -o-border-radius: 48px;
}

.awe-layout-container .overlay {
    background-color: var(--dark500);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
}

.awe-layout-container .overlay.isOpen {
    opacity: 1;
    visibility: visible;
}

@media screen and (max-width: 1199px) {
    .awe-layout-container header .navbar {
        padding: 14px 16px;
    }

    .awe-layout-container header .navbar .user_img {
        width: 36px;
        height: 36px;
    }
}

.awe-layout-container .noarrow.dropdown-toggle::after {
    display: none;
}

.awe-layout-container .auth-wrapper .main-wrapper {
    min-height: unset;
    max-height: unset;
    max-width: 100%;
    height: unset;
    margin-left: unset;
}

.awe-layout-container .header-wrapper {
    max-width: calc(100vw - 260px);
    margin-left: 260px;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
}

body.awe-layout-container.sidebar_mini .header-wrapper {
    margin-left: 70px;
    max-width: calc(100vw - 70px);
}

.awe-layout-container .main-wrapper {
    min-height: calc(100vh - 72px);
    max-height: calc(100vh - 72px);
    max-width: calc(100vw - 260px);
    height: 100vh;
    margin-left: 260px;
    padding: 30px;
    padding-top: 86px;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
}

body.awe-layout-container.sidebar_mini .main-wrapper {
    margin-left: 70px;
    min-height: calc(100vh - 80px);
    max-height: calc(100vh - 80px);
    max-width: calc(100vw - 70px);
}

.awe-layout-container .navbar .dropdown.user_menu .user_text p {
    color: var(--grey150);
}

.awe-layout-container .navbar .dropdown.user_menu .dropdown-menu li a {
    border-bottom: 0px;
    padding: 12px 16px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.awe-layout-container .navbar .dropdown.user_menu .dropdown-menu li:hover a {
    background-color: var(--primary100);
    color: var(--primaryMain);
}

.awe-layout-container .navbar .dropdown.notify_menu .dropdown-menu li a {
    padding: 0px;
}

@media screen and (max-width: 1199px) {
    body.awe-layout-container.sidebar_mini .main-wrapper {
        margin-left: 0;
        min-height: unset;
        max-height: unset;
        max-width: unset;
    }

    .awe-layout-container .header-wrapper {
        max-width: 100%;
        margin-left: 0px;
    }

    body.awe-layout-container.sidebar_mini .header-wrapper {
        max-width: 100%;
        margin-left: 0px;
    }

    .awe-layout-container .main-wrapper {
        max-width: 100%;
        margin-left: 0px;
        padding: 24px;
        padding-top: 86px;
    }
}

@media screen and (max-width: 575px) {
    .awe-layout-container .main-wrapper {
        padding: 16px;
        padding-top: 86px;
    }
}

.awe-layout-container .breadcrumb{
    padding: 0px;
    background-color: transparent;
}
.awe-layout-container .breadcrumb_dropdown_menu>* {
    cursor: pointer;
}

.awe-layout-container .breadcrumb_dropdown_menu .dropdown-menu {
    min-width: 305px;
}

.awe-layout-container .breadcrumb_dropdown_menu .dropdown-menu ul {
    overflow-y: auto;
    max-height: 370px;
}

.awe-layout-container footer{
    padding: 16px 30px;
    max-width: calc(100vw - 260px);
    margin-left: 260px;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
}

body.awe-layout-container.sidebar_mini footer {
    margin-left: 70px;
    max-width: calc(100vw - 70px);
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 99;
}

