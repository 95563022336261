@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap');
@import "sidebar.css";
@import "variables.css";
@import "normal.css";
@import "header.css";

.awe-layout-container .card {
    border: 0px;
    background-color: var(--white);
    height: 100%;
    word-break: break-word;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px;
}

.awe-layout-container .card-body {
    padding: 20px;
}

.awe-layout-container .card-footer,
.awe-layout-container .card-bottom {
    padding: 16px 20px 20px;
    border-top: 1px solid var(--border);
    background-color: transparent;
}

.awe-layout-container .card-bottom {
    padding: 16px 20px;
    border-top: 1px solid var(--border);
}

.awe-layout-container .card .card_header .card-title {
    margin-bottom: 0px;
    color: var(--lightDark800);
}

@media screen and (max-width: 1399px) {
    .awe-layout-container .card {
        border-radius: 16px;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        -ms-border-radius: 16px;
        -o-border-radius: 16px;
    }

    .awe-layout-container .card-body {
        padding: 16px;
    }

    .awe-layout-container .card-footer {
        padding: 8px 16px 16px;
    }

    .awe-layout-container .card-bottom {
        padding: 8px 16px;
    }
}

.awe-layout-container .icon {
    flex-shrink: 0;
    /* grid and list layout */
}

.awe-layout-container .grid .list-layout,
.awe-layout-container .list .grid-layout {
    display: none;
}

.awe-layout-container .grid .grid-layout,
.awe-layout-container .list .list-layout {
    display: block;
}

.awe-layout-container .progress {
    --bs-progress-height: 8px;
    background-color: var(--border300);
}

.awe-layout-container .progress-tosca {
    background-color: var(--tosca);
    border-radius: 10px;
}

.awe-layout-container .td_bg_transparent tr td,
.awe-layout-container .td_bg_transparent tr th {
    background-color: transparent;
}

.awe-layout-container .custom-table-wrap .form-check-input {
    width: 20px;
    height: 20px;
}

.awe-layout-container .custom-table-wrap .table.table_rounded {
    border-collapse: separate;
    border: 0px;
    border-spacing: 0px;
}

.awe-layout-container .custom-table-wrap .table {
    border-collapse: collapse;
    border-spacing: 0px;
}

.awe-layout-container .custom-table-wrap .table.border-none thead tr th,
.awe-layout-container .custom-table-wrap .table.border-none tbody tr td {
    border: 0px;
}

.awe-layout-container .custom-table-wrap .table.td_border td,
.awe-layout-container .custom-table-wrap .table.td_border th {
    border-bottom: 1px solid var(--border);
    border-left: 1px solid var(--border);
}

.awe-layout-container .custom-table-wrap .table.td_border.in_border_0 td,
.awe-layout-container .custom-table-wrap .table.td_border.in_border_0 th {
    border-left: 0px;
}

.awe-layout-container .custom-table-wrap .table.td_border.in_border_0 td:first-child,
.awe-layout-container .custom-table-wrap .table.td_border.in_border_0 th:first-child {
    border-left: 1px solid var(--border);
}

.awe-layout-container .custom-table-wrap .table.td_border.in_border_0 td:last-child,
.awe-layout-container .custom-table-wrap .table.td_border.in_border_0 th:last-child {
    border-right: 1px solid var(--border);
}

.awe-layout-container .custom-table-wrap .table.td_border thead th {
    border-top: 1px solid var(--border);
}

.awe-layout-container .custom-table-wrap .table.td_border td:last-child,
.awe-layout-container .custom-table-wrap .table.td_border th:last-child {
    border-right: 1px solid var(--border);
}

.awe-layout-container .custom-table-wrap .table.td_border_bottom td,
.awe-layout-container .custom-table-wrap .table.td_border_bottom th,
.awe-layout-container .custom-table-wrap .table.tr_border_bottom tr {
    border-bottom: 1px solid var(--border) !important;
}

.awe-layout-container .custom-table-wrap .table thead tr th {
    background-color: var(--lightMain);
    padding: 16px 14px;
}

.awe-layout-container .custom-table-wrap .table.table_rounded tr th:first-child {
    border-top-left-radius: 12px;
}

.awe-layout-container .custom-table-wrap .table.table_rounded tr th:last-child {
    border-top-right-radius: 12px;
}

.awe-layout-container .custom-table-wrap .table.table_rounded tr:last-child td:first-child {
    border-bottom-left-radius: 12px;
}

.awe-layout-container .custom-table-wrap .table.table_rounded tr:last-child td:last-child {
    border-bottom-right-radius: 12px;
}

.awe-layout-container .custom-table-wrap .table.table_rounded_0 tr th:first-child,
.awe-layout-container .custom-table-wrap .table.table_rounded_0 tr th:last-child,
.awe-layout-container .custom-table-wrap .table.table_rounded_0 tr:last-child td:first-child,
.awe-layout-container .custom-table-wrap .table.table_rounded_0 tr:last-child td:last-child {
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
}

.awe-layout-container .custom-table-wrap .table tbody tr td {
    padding: 16px 14px;
    vertical-align: middle;
}

.awe-layout-container .custom-table-wrap .table.td_border_bottom tr:last-child td,
.awe-layout-container .custom-table-wrap .table.tr_border_bottom tbody tr:last-child {
    border-bottom: 0px;
}

.awe-layout-container .custom-table-wrap .table:not(.not_striped) tbody tr:nth-child(even) td {
    background-color: var(--lightMain);
}

.awe-layout-container .list-group-items li>* {
    padding: 20px 24px;
    border-bottom: 1px solid var(--border);
    display: block;
}

.awe-layout-container .list-group-items li:last-child a {
    border-bottom: 0px;
}

.awe-layout-container .custom-table-wrap table.space-0 tr td,
.awe-layout-container .custom-table-wrap table.space-0 tr th {
    padding: 0px;
}

.awe-layout-container .custom-table-wrap table.space-0 tr td p,
.awe-layout-container .custom-table-wrap table.space-0 tr th p {
    padding: 16px 14px;
    line-height: 1.5;
    /* .auth-wrapper */
}

.awe-layout-container .auth-wrapper .card .card-body {
    padding: 32px;
}

.awe-layout-container .auth-wrapper .main-wrapper .row {
    min-height: calc(100vh - 152px);
    max-height: calc(100vh - 152px);
    height: calc(100vh - 152px);
}

@media screen and (max-width: 991px) {

    /* tabs_wrapper */
    .awe-layout-container .auth-wrapper .main-wrapper .row {
        min-height: unset;
        max-height: unset;
        height: unset;
    }

    .awe-layout-container .auth-wrapper .card .card-body {
        padding: 24px;
    }
}

.awe-layout-container .tabs_wrapper {
    background-color: var(--white);
}

.awe-layout-container .tabs_wrapper ul {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--lightMain);
}

.awe-layout-container .tabs_wrapper ul li button {
    font-size: var(--fs16);
    font-weight: 600;
    color: var(--grey300);
    padding: 10px 16px;
    border: 0px !important;
    border-bottom: 2px solid var(--grey150) !important;
    margin-bottom: 0px;
}

.awe-layout-container .tabs_wrapper ul li button.active,
.awe-layout-container .tabs_wrapper ul li button:hover {
    border: 0px;
    border-bottom-color: var(--primary400) !important;
    color: var(--primary400) !important;
}

@media screen and (max-width: 1399px) {
    .awe-layout-container .tabs_wrapper ul li button {
        padding: 14px 24px;
        font-size: var(--fs14);
    }
}

.awe-layout-container .minh_80 {
    height: 80px;
}

.awe-layout-container .offcanvas {
    --bs-offcanvas-width: 350px;
}

.awe-layout-container .offcanvas .offcanvas-header,
.awe-layout-container .offcanvas .offcanvas-body {
    padding: 16px 20px;
}

.awe-layout-container .diagnosis_code_sidebar .offcanvas-body {
    min-height: calc(100vh - 56px);
    max-height: 100%;
}

.awe-layout-container .history_card_list .history_card_item {
    padding: 8px 16px;
    background-color: #FFFFFF;
    box-shadow: 1px 1px 9px -3px rgba(0, 0, 0, 0.25);
    border-left: 5px solid;
}

.awe-layout-container .history_card_list .history_card_item:not(:last-child) {
    margin-bottom: 10px;
}

.awe-layout-container .history_card_list .history_card_item.status-danger {
    border-left-color: var(--danger900);
}

.awe-layout-container .history_card_list .history_card_item.status-warning {
    border-left-color: var(--warningMain);
}

.awe-layout-container .history_card_list .history_card_item.status-orange {
    border-left-color: var(--warningMain);
}

.awe-layout-container .history_card_list .history_card_item.status-primary {
    border-left-color: var(--primary400);
}
.awe-layout-container .history_card_list .history_card_item.status-light {
    border-left-color: var(--lightMain);
}

.mui_custom_table table .collapse_btn.collapsed svg {
    transform: rotate(00deg);
    -webkit-transform: rotate(00deg);
    -moz-transform: rotate(00deg);
    -ms-transform: rotate(00deg);
    -o-transform: rotate(00deg);
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
}

.mui_custom_table table .collapse_btn:not(.collapsed) svg {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
}

.mui_custom_table .custom_table_responsive {
    overflow-x: auto;
    max-width: 100%;
}

.mui_custom_table .custom_table th {
    z-index: 0;
}

.mui_custom_table .custom_table .th-align-middle th {
    vertical-align: middle;
}

.mui_custom_table .custom_table th .MuiButton-root {
    margin-left: 0px;
    margin-right: 0px;
    font-size: 13px;
    font-weight: 700;
    color: #616161;
    font-family: inherit;
    text-transform: uppercase;
}

.custom_table_responsive+table {
    min-width: unset;
}

.awe-layout-container .custom-autocomplete {
    position: relative;
}

.awe-layout-container .custom-autocomplete ul:empty {
    display: none;
}

.awe-layout-container .custom-autocomplete ul.options {
    position: absolute;
    list-style: none;
    padding: 0;
    margin-top: 0px;
    width: 100%;
    background-color: var(--white);
    border: 1px solid var(--border);
    border-top: none;
    z-index: 1;
    max-height: 200px;
    overflow: auto;
    font-size: 13px;
    font-weight: 500;
}

.awe-layout-container .custom-autocomplete ul.options li {
    padding: 8px 10px;
    cursor: pointer;
    background-color: var(--white);
    border-bottom: 1px solid var(--border);
}

.awe-layout-container .custom-autocomplete ul.options li:hover {
    background-color: #f0f0f0;
}

.awe-layout-container .form-input-error {
    color: var(--dangerMain) !important;
    font-size: 11px;
}

.awe-layout-container .MuiTooltip-tooltip {
    font-size: 15px;
    font-weight: 400;
    color: var(--white);
    padding: 6px 14px;
    background: var(--dark800);
    min-width: 90px;
    text-align: center;
    font-family: inherit;
}

.awe-layout-container .css-kudwh-MuiTooltip-arrow {
    color: var(--dark800);
}

.awe-layout-container .filter_dropdown_menu .MuiPaper-root {
    box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.25);
    background: #FFFFFF;
    border: 0px;
    padding: 16px 20px;
    min-width: 290px;
    max-width: 290px;
    width: 100%;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px;
}


.awe-layout-container .pagination_select .MuiSelect-select {
    border: 0px;
    padding: 0px;
    height: auto;
    min-height: auto;
    padding-right: 24px !important;
    background-color: transparent;
    font-family: var(--fonts-OpenSans);
}

.awe-layout-container .pagination_select .MuiOutlinedInput-notchedOutline {
    border: 0px;
}

.awe-layout-container .pagination_select .MuiSelect-select .MuiSvgIcon-root {
    right: 0px;
}

.summary_status_wrap .summary_status_widget.widget1 ul li {
    padding: 11px 8px;
}

.awe-layout-container .MuiLinearProgress-root {
    background-color: var(--light900);
}

.awe-layout-container .MuiLinearProgress-root .MuiLinearProgress-bar {
    background-color: var(--primary400);
}

.awe-layout-container .MuiLinearProgress-root.liner_danger .MuiLinearProgress-bar{
    background-color: var(--danger900);
}
.awe-layout-container .MuiLinearProgress-root.liner_black .MuiLinearProgress-bar {
    background-color: var(--dark1000);
}
.mui_custom_table table .MuiLinearProgress-root,
.mui_custom_table table .MuiLinearProgress-root .MuiLinearProgress-bar {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

@media screen and (max-width: 413px) {

    .annual_wellness_filter .right_part .flex_item_cs,
    .annual_wellness_filter .right_part .custom_select {
        flex-grow: 1;
        max-width: 100%;
    }
}

.align-middle {
    vertical-align: middle !important;
}

.awe-layout-container .summary_status_wrap .col_border_left  .summary_status_widget{
    border: 1px solid var(--border);
    border-top: 0px;
}
.awe-layout-container .quick_links_menu ul{
    gap: 6px;
    padding: 15px;
    background-color: var(--light650);
}
.awe-layout-container .quick_links_menu ul li{
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: var(--fs13);
    font-weight: 400;
}
.awe-layout-container .quick_links_menu ul li a{
    display: block;
}
.awe-layout-container .quick_links_menu ul li:not(:last-child):after{
    content: "|";
    display: block;
    line-height: 1.2;
}

.awe-layout-container div.Toastify__toast {
    margin-bottom:15px;
}
.awe-layout-container div.Toastify__toast-icon {
    margin-right: 10px;
}
/*
iframe {
    display: none !important;
}
*/
.download-button {
    background-color: lightgreen;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }
  
  .download-icon {
    margin-right: 8px;
    font-size: 20px;
  }
  
  .btn {
    background-color: DodgerBlue;
    border: none;
    color: white;
    padding: 12px 30px;
    cursor: pointer;
    font-size: 20px;
  }

  