.ui-item h2 {
  font-size: 60px;
  line-height: 80px;
  font-weight: 300;
  margin: 2px 0px;
  padding: 0px;
}

.ui-item {
  height: 170px;
  margin: 10px auto;
  padding: 20px;
  cursor: pointer;
}

.ui-item a {
  color: #fff;
}

.text-center {
  text-align: center;
}
/* 
.bg-green {
    background-color: #51d466 ;
}

.bg-purple {
    background-color: #cb79e6 !important;
}

.bg-lblue {
    background-color: #32c8de !important;
}

.bg-yellow {
    background-color: #fcd419 !important;
} */

.home-heading {
  font-size: 26px;
  line-height: 37px;
  margin: 19px;
  font-weight: bold;
}
