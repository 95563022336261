.multi-range-slider {
    border: none !important;
    border-radius: none !important;
    padding: none !important;
    box-shadow: none !important;
}

.multi-range-slider .ruler {
    margin: -13px 0px -5px 0px !important;
}

.multi-range-slider .bar-inner,
.bar-right,
.bar-left {
    background-color: transparent !important;
    border: transparent !important;
    box-shadow: inset 0px 0px 5px transparent !important;
}

.multi-range-slider .thumb-left::before {
    border-radius: 0px !important;
    width: 12px !important;
    height: 60px !important;
    stroke: rgb(255, 255, 255) !important;
    stroke-width: 1 !important;
    background: rgb(228, 160, 13) !important;
    border: solid 1px rgb(255, 255, 255) !important;
    box-shadow: none !important;
}

.multi-range-slider .thumb-left * {
    font-size: 15px !important;
    text-align: center !important;
    background-color: transparent !important;
    border-radius: 0% !important;
    color: black !important;
    box-shadow: 0px 0px 5px transparent;
}

.multi-range-slider .thumb-right::before {
    border-radius: 0px !important;
    stroke: rgb(255, 255, 255) !important;
    stroke-width: 1 !important;
    background: transparent !important;
    border: solid 1px rgb(255, 255, 255) !important;
    box-shadow: none !important;
    margin-top: 47px;
    width: 0 !important;
    height: 0 !important;
    border-left: 10px solid transparent !important;
    border-right: 10px solid transparent !important;
    border-bottom: 15px solid rgb(17, 39, 89) !important;
}

.multi-range-slider .thumb-right * {
    font-size: 15px !important;
    text-align: center !important;
    background-color: transparent !important;
    border-radius: 0% !important;
    color: black !important;
    box-shadow: 0px 0px 5px transparent;
    top: 61px;
}

.multi-range-slider .ruler .ruler-rule:nth-child(1) {
    color: rgb(229, 240, 243);
    background: rgb(229, 240, 243);
    padding: 25px 0px;
}

.multi-range-slider .ruler .ruler-rule:nth-child(2) {
    color: rgb(207, 225, 231);
    background: rgb(207, 225, 231);
    padding: 25px 0px;
}

.multi-range-slider .ruler .ruler-rule:nth-child(3) {
    color: rgb(184, 210, 219);
    background: rgb(184, 210, 219);
    padding: 25px 0px;
}

.multi-range-slider .ruler .ruler-rule:nth-child(4) {
    color: rgb(163, 195, 207);
    background: rgb(163, 195, 207);
    padding: 25px 0px;
}

.multi-range-slider .ruler .ruler-rule:nth-child(5) {
    color: rgb(141, 180, 196);
    background: rgb(141, 180, 196);
    padding: 25px 0px;
}

.multi-range-slider .ruler .ruler-rule:nth-child(6) {
    color: rgb(119, 165, 185);
    background: rgb(119, 165, 185);
    padding: 25px 0px;
}

.multi-range-slider .ruler .ruler-rule:nth-child(7) {
    color: rgb(97, 151, 174);
    background: rgb(97, 151, 174);
    padding: 25px 0px;
}

.multi-range-slider .ruler .ruler-rule:nth-child(8) {
    color: rgb(74, 136, 164);
    background: rgb(74, 136, 164);
    padding: 25px 0px;
}

.multi-range-slider .ruler .ruler-rule:nth-child(9) {
    color: rgb(47, 122, 154);
    background: rgb(47, 122, 154);
    padding: 25px 0px;
}

.multi-range-slider .ruler .ruler-rule:last-child {
    color: #526CEC;
    background: #526CEC;
    padding: 25px 0px;
}


/* .multi-range-slider-black */
.multi-range-slider-black {
    border: none !important;
    border-radius: none !important;
    padding: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
}

.multi-range-slider-black .ruler {
    margin: -13px 0px -5px 0px !important;
}

.multi-range-slider-black .bar-inner,
.bar-right,
.bar-left {
    background-color: transparent !important;
    border: transparent !important;
    box-shadow: inset 0px 0px 5px transparent !important;
}

.multi-range-slider-black .thumb-left::before {
    border-radius: 0px !important;
    stroke: rgb(255, 255, 255) !important;
    stroke-width: 1 !important;
    background: transparent !important;
    border: solid 1px rgb(255, 255, 255) !important;
    box-shadow: none !important;
    margin-top: -20px;
    width: 0 !important;
    height: 0 !important;
    border-left: 10px solid transparent !important;
    border-right: 10px solid transparent !important;
    border-top: 15px solid rgb(17, 39, 89) !important;
    pointer-events: none !important;
}

.multi-range-slider-black .thumb-left * {
    font-size: 15px !important;
    text-align: center !important;
    background-color: transparent !important;
    border-radius: 0% !important;
    color: black !important;
    box-shadow: 0px 0px 5px transparent;
    pointer-events: none !important;
    font-weight: 700 !important;
}

.multi-range-slider-black .thumb-right::before {
    border-radius: 0px !important;
    stroke: rgb(255, 255, 255) !important;
    stroke-width: 1 !important;
    background: transparent !important;
    border: solid 1px rgb(255, 255, 255) !important;
    box-shadow: none !important;
    margin-top: 48px;
    width: 0 !important;
    height: 0 !important;
    border-left: 10px solid transparent !important;
    border-right: 10px solid transparent !important;
    border-bottom: 15px solid rgb(17, 39, 89) !important;
    pointer-events: none !important;
}

.multi-range-slider-black .thumb-right * {
    font-size: 15px !important;
    text-align: center !important;
    background-color: transparent !important;
    border-radius: 0% !important;
    color: black !important;
    box-shadow: 0px 0px 5px transparent;
    top: 61px;
    pointer-events: none !important;
    font-weight: 700 !important;
}

.range-single-slider .thumb-right::before {
    border-radius: 0px !important;
    stroke: rgb(255, 255, 255) !important;
    stroke-width: 1 !important;
    background: transparent !important;
    border: solid 1px rgb(255, 255, 255) !important;
    box-shadow: none !important;
    margin-top: 48px;
    width: 0 !important;
    height: 0 !important;
    border-left: 10px solid transparent !important;
    border-right: 10px solid transparent !important;
    border-bottom: 15px solid rgb(17, 39, 89) !important;
    pointer-events: none !important;
    display: none !important;
}

.range-single-slider .thumb-right * {
    font-size: 15px !important;
    text-align: center !important;
    background-color: transparent !important;
    border-radius: 0% !important;
    color: black !important;
    box-shadow: 0px 0px 5px transparent;
    top: 61px;
    pointer-events: none !important;
    display: none !important;
}

.multi-range-slider-black .ruler .ruler-rule:nth-child(1) {
    color: #E2F0F4;
    background: #E2F0F4;
    padding: 25px 0px;
}

.multi-range-slider-black .ruler .ruler-rule:nth-child(2) {
    color: #CAE1E7;
    background: #CAE1E7;
    padding: 25px 0px;
}

.multi-range-slider-black .ruler .ruler-rule:nth-child(3) {
    color: #B3D1DC;
    background: #B3D1DC;
    padding: 25px 0px;
}

.multi-range-slider-black .ruler .ruler-rule:nth-child(4) {
    color: #97C4D2;
    background: #97C4D2;
    padding: 25px 0px;
}

.multi-range-slider-black .ruler .ruler-rule:nth-child(5) {
    color: #7EB5C6;
    background: #7EB5C6;
    padding: 25px 0px;
}

.multi-range-slider-black .ruler .ruler-rule:nth-child(6) {
    color: #63A7BC;
    background: #63A7BC;
    padding: 25px 0px;
}

.multi-range-slider-black .ruler .ruler-rule:nth-child(7) {
    color: #429AAD;
    background: #429AAD;
    padding: 25px 0px;
}

.multi-range-slider-black .ruler .ruler-rule:nth-child(8) {
    color: #278AA4;
    background: #278AA4;
    padding: 25px 0px;
}

.multi-range-slider-black .ruler .ruler-rule:nth-child(9) {
    color: #007B9B;
    background: #007B9B;
    padding: 25px 0px;
}

.multi-range-slider-black .ruler .ruler-rule:last-child {
    color: #006F91;
    background: #006F91;
    padding: 25px 0px;
}

.multi-range-slider-black .label-min {
    color: black;
    font-weight: 600 !important;
}

.multi-range-slider-black .label-max {
    color: black;
    font-weight: 600 !important;
}

.multi-range-slider-black .ruler {
    border: 1px solid #EBEBEB;
}


/* multi-range-slider-swap */

.multi-range-slider-swap .thumb-left::before {
    border-radius: 0px !important;
    stroke: rgb(255, 255, 255) !important;
    stroke-width: 1 !important;
    background: transparent !important;
    border: solid 1px rgb(255, 255, 255) !important;
    box-shadow: none !important;
    margin-top: 48px;
    width: 0 !important;
    height: 0 !important;
    border-left: 10px solid transparent !important;
    border-right: 10px solid transparent !important;
    border-bottom: 15px solid rgb(17, 39, 89) !important;
    pointer-events: none !important;
}

.multi-range-slider-swap .thumb-left * {
    font-size: 15px !important;
    text-align: center !important;
    background-color: transparent !important;
    border-radius: 0% !important;
    color: black !important;
    box-shadow: 0px 0px 5px transparent;
    top: 61px;
    pointer-events: none !important;
    font-weight: 700 !important;
}

.multi-range-slider-swap .thumb-right::before {
    border-radius: 0px !important;
    stroke: rgb(255, 255, 255) !important;
    stroke-width: 1 !important;
    background: transparent !important;
    border: solid 1px rgb(255, 255, 255) !important;
    box-shadow: none !important;
    margin-top: -20px;
    width: 0 !important;
    height: 0 !important;
    border-left: 10px solid transparent !important;
    border-right: 10px solid transparent !important;
    border-top: 15px solid rgb(17, 39, 89) !important;
    pointer-events: none !important;
}

.multi-range-slider-swap .thumb-right * {
    font-size: 15px !important;
    text-align: center !important;
    background-color: transparent !important;
    border-radius: 0% !important;
    color: black !important;
    box-shadow: 0px 0px 5px transparent;
    pointer-events: none !important;
    font-weight: 700 !important;
    top: -45px !important;
}